import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_state0_elPreloader from './images/WaitScreen_state0_elPreloader_626520.png';
import GlobalcheckConnection from './GlobalcheckConnection';

export default class WaitScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    
    const google = window.google = window.google ? window.google : {} 
    const geofire = require('geofire-common');
    
    navigator.geolocation.getCurrentPosition(function (position) {
    
            var pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
    
            const latitude  = parseFloat(position.coords.latitude.toFixed(6));
            const longitude = parseFloat(position.coords.longitude.toFixed(6));
            const hash = geofire.geohashForLocation([latitude, longitude]);
    
            var pos2 = new google.maps.LatLng(latitude, longitude);
              
            localStorage.setItem("ds_SlotLat", latitude);
            localStorage.setItem("ds_SlotLon", longitude);
            localStorage.setItem("ds_SlotHash", hash);
    
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({'latLng': pos}, function(results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                          var address = (results[0].formatted_address);
                          console.log('address',address)
                          localStorage.setItem("ds_mygeo", address)
                           if (results[0]) {
                        //console.log(results[0]);
                        // choose from console whatever you need.
                        var city = results[0].address_components[3].short_name;
                        var neig = results[0].address_components[2].long_name;
                        var state = results[0].address_components[4].short_name;
                        console.log( "city name is: ",  city)
                        console.log( "neig name is: ",  neig)
                        console.log( "state name is: ",  state)
                        localStorage.setItem("ds_SlotCity", city)
                        localStorage.setItem("ds_Neighborhood", neig)
                        localStorage.setItem("ds_SlotState", state)
                        }
                    } 
             })
     })
  
    
        
    setTimeout(() => {
      this.context.appActions.goToScreen('geoaddress', { transitionId: 'slideIn_bottom' })
    }, 5000);
    
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  
  renderState0() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state0_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    const style_state0_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_state0_elPreloader = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotLoadingAnim'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    return (
      <div className="AppScreen WaitScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state0_elBackground" style={style_state0_elBackground_outer}>
            <div className="appBg" style={style_state0_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state0_elText">
            <div className="baseFont" style={style_state0_elText}>
              <div>{this.context.locStrings.wait_text_290842}</div>
            </div>
          </div>
          
          <div className="state0_elPreloader">
            <div style={style_state0_elPreloader} />
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  
  onClick_state1_elHotspot = async () => {
    window.location.reload()
  
  }
  
  
  renderState1() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    
    const style_state1_elNavigatoronLine = {
      background: 'rgba(255, 125, 120, 1.000)',
     };
    const style_state1_elRetry = {
      color: '#feffff',
      textAlign: 'center',
      textShadow: '#feffff 1.0px 1.0px 2.0px',
     };
    
    const style_state1_elHotspot = {
      display: 'block',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen WaitScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state1_elNavigatoronLine" style={style_state1_elNavigatoronLine} />
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="hasNestedComps state1_elGlobalcheckConnection">
            <div>
              <GlobalcheckConnection ref={(el)=> this._state1_elGlobalcheckConnection = el} />
            </div>
          </div>
          
          <div className="state1_elRetry">
            <div className="actionFont" style={style_state1_elRetry}>
              <div>{this.context.locStrings.wait_retry_813538}</div>
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="actionFont containerMinHeight state1_elHotspot" style={style_state1_elHotspot} onClick={this.onClick_state1_elHotspot}  />
          </div>
        </div>
      </div>
    )
  }
  
  
  render() {
    let transformStateValue = (input) => {
      if(navigator.onLine === true){
      console.log('online')
      return 0;
      }else{
       console.log('offline')
      return 1;
      }
    }
    switch (parseInt((transformStateValue(this.context.ds_internetConnection) !== undefined) ? transformStateValue(this.context.ds_internetConnection) : 0, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
