import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class Price3 extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  
  
  onClick_elButton = async () => {
    // Go to screen 'requestpartnership'
    this.context.appActions.goToScreen('requestpartnership', this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
      borderRadius: '10.0px',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
      borderRadius: '10.0px',
     };
    const style_elPlanName = {
      fontSize: 35.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elText = {
      fontSize: 22.0,
      color: '#7f7eff',
      textAlign: 'center',
     };
    const style_elPrice = {
      fontSize: 30.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elFeatures = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elButton = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Price3">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="elPlanName">
            <div className="font-ralewayBold" style={style_elPlanName}>
              <div>{this.context.locStrings.component3_planname_827972}</div>
            </div>
          </div>
          
          <div className="elText">
            <div className="font-ralewayRegular" style={style_elText}>
              <div>{this.context.locStrings.component3_text_914961}</div>
            </div>
          </div>
          
          <div className="elPrice">
            <div className="font-ralewayRegular" style={style_elPrice}>
              <div>{this.context.locStrings.price3_price_246100807}</div>
            </div>
          </div>
          
          <div className="elFeatures">
            <div className="baseFont" style={style_elFeatures}>
              <div><div dangerouslySetInnerHTML={{__html: this.context.locStrings.component3_features_462895.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>
          
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              {this.context.locStrings.component3_button_745407}
            </Button>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
