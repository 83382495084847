import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class MenuWeb extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  onClick_elMenu0 = async () => {
    // Go to screen 'Feed'
    this.context.appActions.goToScreen('feed', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  
  onClick_elMenu1 = async () => {
    // Go to screen 'listProjects'
    this.context.appActions.goToScreen('listProjects', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  
  
  
  onClick_elMenu4 = async () => {
    // Go to screen 'collaborative_map'
    this.context.appActions.goToScreen('collaborative_map', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  
  onClick_elMenu4Copy = async () => {
  
  }
  
  
  
  onClick_elMenu4Copy2 = async () => {
  
  }
  
  
  
  onClick_elMenu4Copy3 = async () => {
  
  }
  
  
  
  
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
      borderRadius: '20.0px',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
      borderRadius: '20.0px',
      filter: 'drop-shadow(2.0px 0.0px 2px #eaeaea)',
      overflow: 'visible',
     };
    const style_elMenu0 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elMenu1 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elMenu2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elMenu3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elMenu4 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elMenu4Copy = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elMenu4Copy2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elMenu4Copy3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elMenu5 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elMenu5Copy = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elMenu5Copy2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    return (
      <div className="MenuWeb">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="elMenu0">
            <div className="baseFont" style={style_elMenu0} onClick={this.onClick_elMenu0} >
              <div>{this.context.locStrings.menuweb_menu1copy_839859248}</div>
            </div>
          </div>
          
          <div className="elMenu1">
            <div className="baseFont" style={style_elMenu1} onClick={this.onClick_elMenu1} >
              <div>{this.context.locStrings.drawerweb_menu1}</div>
            </div>
          </div>
          
          <div className="elMenu2">
            <div className="baseFont" style={style_elMenu2}>
              <div>{this.context.locStrings.drawerweb_menu2}</div>
            </div>
          </div>
          
          <div className="elMenu3">
            <div className="baseFont" style={style_elMenu3}>
              <div>{this.context.locStrings.drawerweb_menu3}</div>
            </div>
          </div>
          
          <div className="elMenu4">
            <div className="baseFont" style={style_elMenu4} onClick={this.onClick_elMenu4} >
              <div>{this.context.locStrings.drawerweb_menu4}</div>
            </div>
          </div>
          
          <div className="elMenu4Copy">
            <div className="baseFont" style={style_elMenu4Copy} onClick={this.onClick_elMenu4Copy} >
              <div>{this.context.locStrings.drawerweb_menu5}</div>
            </div>
          </div>
          
          <div className="elMenu4Copy2">
            <div className="baseFont" style={style_elMenu4Copy2} onClick={this.onClick_elMenu4Copy2} >
              <div>{this.context.locStrings.drawerweb_menu6}</div>
            </div>
          </div>
          
          <div className="elMenu4Copy3">
            <div className="baseFont" style={style_elMenu4Copy3} onClick={this.onClick_elMenu4Copy3} >
              <div>{this.context.locStrings.drawerweb_menu7}</div>
            </div>
          </div>
          
          <div className="elMenu5">
            <div className="baseFont" style={style_elMenu5}>
              <div>{this.context.locStrings.drawerweb_menu8}</div>
            </div>
          </div>
          
          <div className="elMenu5Copy">
            <div className="baseFont" style={style_elMenu5Copy}>
              <div>{this.context.locStrings.drawerweb_menu9}</div>
            </div>
          </div>
          
          <div className="elMenu5Copy2">
            <div className="baseFont" style={style_elMenu5Copy2}>
              <div>{this.context.locStrings.drawerweb_menu10}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
