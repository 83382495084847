import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class HerophotoText extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  
  
  textAreaChanged_elTextarea = (event) => {
    this.setState({textarea: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_catchphrase", event.target.value);
  }
  
  getValue_elTextarea = () => {
    let transformPropValue_textarea = (input) => {
      var maxLength = 140
      return input.substr(0, maxLength);
    }
    
    return transformPropValue_textarea((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_catchphrase'] : ''));
  }
  
  
  getValue_elTextareaCount = () => {
  let transformPropValue_textareaCount = (input) => {
    input = this.context.appActions.dataSlots['ds_catchphrase'].length
    var charactersLeft = 140 - input;
    return charactersLeft;
  }
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_textareaCount((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_catchphrase'] : '')));
  }
  
  render() {
    const style_elAddphoto = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elAddphototext = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elCreate = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elCreatetext = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elTextarea = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      pointerEvents: 'auto',
     };
    
    const value_textareaCount = this.getValue_elTextareaCount();
    
    const style_elTextareaCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    
    return (
      <div className="HerophotoText">
        <div className="layoutFlow">
          <div className="elAddphoto">
            <div className="font-ralewayBold" style={style_elAddphoto}>
              <div>{this.context.locStrings.herophototext_text2_813377374}</div>
            </div>
          </div>
          
          <div className="elAddphototext">
            <div className="baseFont" style={style_elAddphototext}>
              <div>{this.context.locStrings.herophototext_text_1717670066}</div>
            </div>
          </div>
          
          <div className="elCreate">
            <div className="font-ralewayBold" style={style_elCreate}>
              <div>{this.context.locStrings.herophototext_textcopy_1334065328}</div>
            </div>
          </div>
          
          <div className="elCreatetext">
            <div className="baseFont" style={style_elCreatetext}>
              <div>{this.context.locStrings.herophototext_textcopy2_1627730192}</div>
            </div>
          </div>
          
          <div className="elTextarea">
            <textarea className="baseFont" style={style_elTextarea}  placeholder={this.context.locStrings.herophototext_textarea_595932082} onChange={this.textAreaChanged_elTextarea} value={this.getValue_elTextarea()}  />
          </div>
          
          <div className="elTextareaCount">
            <div className="baseFont" style={style_elTextareaCount}>
              <div>{value_textareaCount}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
