// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_users extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Firebase (Cloud Firestore)'.
    
    item = {};
    this.items.push(item);
    item['geolocation'] = "x";
    item['userEmail'] = "x";
    item['document_key'] = "4pEXW69CPxSxQ8HkP0wy6YzWDgZ2";
    item['userStatus'] = "admin";
    item['fullname'] = "x";
    item['organizationName'] = "x";
    item['stripeId'] = "a";
    item['document_path'] = "";
    item['lng'] = "x";
    item['city'] = "x";
    item['bio'] = "";
    item['stripeLink'] = "s";
    item['name'] = "Tim";
    item['group'] = "x";
    item['spaceName'] = "x";
    item['state'] = "x";
    item['country_name'] = "x";
    item['gender'] = "masc";
    item['uid'] = "4pEXW69CPxSxQ8HkP0wy6YzWDgZ2";
    item['neighborhood'] = "x";
    item['roleAccount'] = "ss";
    item['timestamp'] = {"nanoseconds":0,"seconds":1655262000};
    item['isSubscribed'] = "false";
    item['role'] = "z";
    item['organizationId'] = "x";
    item['photoURL'] = "x";
    item['lat'] = "s";
    item['age'] = "60";
    item['groupId'] = "s";
    item['phoneNumber'] = "1234567";
    item['address'] = "";
    item['spaceId'] = "";
    item.key = key++;
  }

  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  
  addItem(item, options) {
    console.log("add to firebase: ", item);
      
    let isCollectionGroup = options.servicePath.startsWith("group:");
    if (isCollectionGroup) {
      console.log("unable to add to collection group: ", options.servicePath.substring(6));
      return;
    }
  
    const db = this.firebase.firestore();
    const collection = db.collection(options.servicePath);
  
    let addItemPromise;
    let parsedItem = (({ key, document_key, document_path, ...item }) => (item))( item );
    if (item.document_key && item.document_key.length > 0) {
      addItemPromise = collection.doc(item.document_key).set(parsedItem);
    } else {
      addItemPromise = collection.add(parsedItem);
    }
    addItemPromise
      .then((docRef) => {
        var addedKey="";
        if (docRef) {
          console.log("Firebase document added with id: ", docRef.id);
          addedKey=docRef.id;
          item.document_key = docRef.id;
          item.document_path = docRef.path;
        } else {
          console.log("Firebase document added with id: ", item.document_key);
          addedKey=item.document_key;
        }   
        if (this.onLastAddedDocumentKeyChanged != null) {
          this.onLastAddedDocumentKeyChanged(addedKey);
        }
        //super.addItem(item, options);
      
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        this._fetchComplete(error, options);
      });
      
    return addItemPromise;
  }
  
  removeItem(item, options) {
    //super.removeItem(item, options);
    
    console.log("delete from firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
  
    docRef.delete()
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error deleting document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  
  replaceItemByRowIndex(idx, item, options) {
    //super.replaceItemByRowIndex(idx, item, options);
    
    console.log("update in firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
      
    docRef.update((({ key, document_ref, document_key, document_path, ...item }) => (item))( item ))
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        this._fetchComplete(error, options);
      });
  }
  

}
