import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elNeighsomerville from './images/RequestpartnershipScreen_elNeighsomerville_1035528.jpg';
import RequestpartnershiptForm from './RequestpartnershiptForm';
import Footer from './Footer';
import OpenlanguageSelector from './OpenlanguageSelector';
import Menumobilelogout from './Menumobilelogout';
import Menulogout from './Menulogout';

export default class RequestpartnershipScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elNeighsomerville = {
      backgroundImage: 'url('+img_elNeighsomerville+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elPartnershipcontactForm = {
      borderRadius: '10.0px',
     };
    const style_elPartnershipcontactForm_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
      borderRadius: '10.0px',
     };
    
    const style_elBarfooter = {
      background: 'rgba(90, 99, 98, 1.000)',
     };
    const elOpenlanguageSelector = ((val) => { return val === "true" || val == true || val == 1 })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotLangSelector'] : '')) ? (
      <div className="hasNestedComps elOpenlanguageSelector">
        <OpenlanguageSelector ref={(el)=> this._elOpenlanguageSelector = el} />
      </div>
     ) : null;
    
    let contentElement_elSwapper;  // This element's content can vary based on screen size
    contentElement_elSwapper = (<Menumobilelogout />);
    if (this.context.deviceInfo.screenFormatId === 'narrow-phone') {
      contentElement_elSwapper = (<Menumobilelogout />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'wide-phone') {
      contentElement_elSwapper = (<Menumobilelogout />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'narrow-tablet') {
      contentElement_elSwapper = (<Menulogout />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'wide-tablet') {
      contentElement_elSwapper = (<Menulogout />);
    }
    
    
    return (
      <div className="AppScreen RequestpartnershipScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elNeighsomerville" style={style_elNeighsomerville} />
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="hasNestedComps elPartnershipcontactForm" style={style_elPartnershipcontactForm_outer}>
            <div style={style_elPartnershipcontactForm}>
              <RequestpartnershiptForm ref={(el)=> this._elPartnershipcontactForm = el} />
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elBarfooter" style={style_elBarfooter} />
            <div className="hasNestedComps elFooter">
              <Footer ref={(el)=> this._elFooter = el} />
            </div>
            { elOpenlanguageSelector }
            <div className="hasNestedComps elSwapper">
              {contentElement_elSwapper}
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
