import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elNoun_cloudConnectionLost_9278842 from './images/GlobalcheckConnection_elNoun_cloudConnectionLost_9278842.png';

export default class GlobalcheckConnection extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  render() {
    
    const style_elNoun_cloudConnectionLost_9278842 = {
      backgroundImage: 'url('+img_elNoun_cloudConnectionLost_9278842+')',
      backgroundSize: '100% 100%',
      opacity: 0.50,
     };
    const style_elTextlostconnection = {
      color: '#feffff',
      textAlign: 'center',
     };
    
    return (
      <div className="GlobalcheckConnection">
        <div className="layoutFlow">
          <div className="elNoun_cloudConnectionLost_9278842">
            <div style={style_elNoun_cloudConnectionLost_9278842} />
          </div>
          
          <div className="elTextlostconnection">
            <div className="headlineFont" style={style_elTextlostconnection}>
              <div>{this.context.locStrings.globalcheckconnection_textlostconnection_642124}</div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
