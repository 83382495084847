import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class Menucreate extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elButtonNewProject = async () => {
    let newVal = "false";
    this.context.appActions.updateDataSlot('ds_menuCreate', newVal);
  
    newVal = "false";
    this.context.appActions.updateDataSlot('ds_bgmap', newVal);
  
    // Go to screen 'newProject'
    this.context.appActions.goToScreen('newProject', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elButtonPoll = async () => {
  
  }
  
  
  onClick_elButtonCocreation = async () => {
  
  }
  
  
  onClick_elButtonReport = async () => {
  
  }
  
  
  onClick_elButtonodeia = async () => {
    let newVal = "false";
    this.context.appActions.updateDataSlot('ds_menuCreate', newVal);
  
    newVal = "false";
    this.context.appActions.updateDataSlot('ds_bgmap', newVal);
  
    // Go to screen 'createonmap'
    this.context.appActions.goToScreen('createonmap', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    
    const style_elButtonNewProject = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButtonPoll = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButtonCocreation = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButtonReport = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButtonodeia = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Menucreate">
        <div className="layoutFlow">
          <div className="elButtonNewProject">
            <Button className="actionFont" style={style_elButtonNewProject}  color="accent" onClick={this.onClick_elButtonNewProject} >
              {this.context.locStrings.comp26_buttonchallengecopy_719006}
            </Button>
          </div>
          
          <div className="elButtonPoll">
            <Button className="actionFont" style={style_elButtonPoll}  color="accent" onClick={this.onClick_elButtonPoll} >
              {this.context.locStrings.comp26_buttonpoll_753855}
            </Button>
          </div>
          
          <div className="elButtonCocreation">
            <Button className="actionFont" style={style_elButtonCocreation}  color="accent" onClick={this.onClick_elButtonCocreation} >
              {this.context.locStrings.comp26_buttonchallenge_561915}
            </Button>
          </div>
          
          <div className="elButtonReport">
            <Button className="actionFont" style={style_elButtonReport}  color="accent" onClick={this.onClick_elButtonReport} >
              {this.context.locStrings.comp26_buttonreport_789942}
            </Button>
          </div>
          
          <div className="elButtonodeia">
            <Button className="actionFont" style={style_elButtonodeia}  color="accent" onClick={this.onClick_elButtonodeia} >
              {this.context.locStrings.comp26_buttonodeia_636939}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
}
