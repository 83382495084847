import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

// UI framework component imports
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';

export default class Comp2 extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  
  getValue_state0_elTypeorganpicker = () => {
    return this.state.typeorganpicker !== undefined ? this.state.typeorganpicker : '';
  }
  
  pickerValueChanged_state0_elTypeorganpicker = (event) => {
    this.setState({typeorganpicker: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_type", event.target.value);
  }
  
  renderState0() {
    let selection_typeorganpicker = this.getValue_state0_elTypeorganpicker();
    // Source datasheet and selected data column for picker element 'Typeorganpicker'
    const dataSource_typeorganpicker = this.context.appActions.getDataSheet('contactType');
    const valueColumnName_typeorganpicker = 'en';
    const labelColumnName_typeorganpicker = 'en';
    
    const style_state0_elTypeorganpicker = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Comp2">
        <div className="layoutFlow">
          <div className="state0_elTypeorganpicker">
            <Select className="baseFont" style={style_state0_elTypeorganpicker}  onChange={this.pickerValueChanged_state0_elTypeorganpicker} value={selection_typeorganpicker} >
              {dataSource_typeorganpicker.items.every(item => {
                return item[valueColumnName_typeorganpicker] !== selection_typeorganpicker;
              }) ? <Option value=''/> : null}
              {dataSource_typeorganpicker.items.map(item => {
                const colValue = item[valueColumnName_typeorganpicker];
                const labelColValue = item[labelColumnName_typeorganpicker];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  
  getValue_state1_elTypeorganpicker = () => {
    return this.state.typeorganpicker !== undefined ? this.state.typeorganpicker : '';
  }
  
  pickerValueChanged_state1_elTypeorganpicker = (event) => {
    this.setState({typeorganpicker: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_type", event.target.value);
  }
  
  renderState1() {
    let selection_typeorganpicker = this.getValue_state1_elTypeorganpicker();
    // Source datasheet and selected data column for picker element 'Typeorganpicker'
    const dataSource_typeorganpicker = this.context.appActions.getDataSheet('contactType');
    const valueColumnName_typeorganpicker = 'ptbr';
    const labelColumnName_typeorganpicker = 'ptbr';
    
    const style_state1_elTypeorganpicker = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Comp2">
        <div className="layoutFlow">
          <div className="state1_elTypeorganpicker">
            <Select className="baseFont" style={style_state1_elTypeorganpicker}  onChange={this.pickerValueChanged_state1_elTypeorganpicker} value={selection_typeorganpicker} >
              {dataSource_typeorganpicker.items.every(item => {
                return item[valueColumnName_typeorganpicker] !== selection_typeorganpicker;
              }) ? <Option value=''/> : null}
              {dataSource_typeorganpicker.items.map(item => {
                const colValue = item[valueColumnName_typeorganpicker];
                const labelColValue = item[labelColumnName_typeorganpicker];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
        </div>
        
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
