import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import Logout from './Logout';
import Selector from './Selector';
import img_state0_elCoUrbaniza461992 from './images/Menu_state0_elCoUrbaniza461992_461992.png';
import img_state1_elCoUrbaniza461992 from './images/Menu_state0_elCoUrbaniza461992_461992.png';

export default class Menu extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  
  onClick_state0_elContato556013 = async () => {
    // Go to screen 'requestpartnership'
    this.context.appActions.goToScreen('requestpartnership', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state0_elCoUrbaniza461992 = async () => {
    // Go to screen 'Feed'
    this.context.appActions.goToScreen('feed', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  renderState0() {
    const style_state0_elBackground893156 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground893156_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
     };
    const style_state0_elContato556013 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elRect990514 = {
      background: 'rgba(119, 119, 119, 1.000)',
     };
    const style_state0_elCoUrbaniza461992 = {
      backgroundImage: 'url('+img_state0_elCoUrbaniza461992+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Menu">
        <div className="background">
          <div className="containerMinHeight state0_elBackground893156" style={style_state0_elBackground893156_outer}>
            <div style={style_state0_elBackground893156} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="flowRow flowRow_Menu_state0_elContato556013_556013">
          <div className="state0_elContato556013">
            <div className="baseFont" style={style_state0_elContato556013} onClick={this.onClick_state0_elContato556013} >
              <div>{this.context.locStrings.menu_contato_556013}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state0_elLogout557043">
            <div>
              <Logout ref={(el)=> this._state0_elLogout557043 = el} />
            </div>
          </div>
          
          </div>
          <div className="hasNestedComps state0_elSelector286199">
            <div>
              <Selector ref={(el)=> this._state0_elSelector286199 = el} />
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <div className="state0_elRect990514" style={style_state0_elRect990514} />
          <div className="state0_elCoUrbaniza461992" style={style_state0_elCoUrbaniza461992} onClick={this.onClick_state0_elCoUrbaniza461992}  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  
  onClick_state1_elContato556013 = async () => {
    // Go to screen 'requestpartnership'
    this.context.appActions.goToScreen('requestpartnership', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state1_elCoUrbaniza461992 = async () => {
    // Go to screen 'Feed'
    this.context.appActions.goToScreen('feed', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  renderState1() {
    const style_state1_elBackground893156 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBackground893156_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
     };
    const style_state1_elContato556013 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elRect990514 = {
      background: 'rgba(119, 119, 119, 1.000)',
     };
    const style_state1_elCoUrbaniza461992 = {
      backgroundImage: 'url('+img_state1_elCoUrbaniza461992+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Menu">
        <div className="background">
          <div className="containerMinHeight state1_elBackground893156" style={style_state1_elBackground893156_outer}>
            <div style={style_state1_elBackground893156} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="flowRow flowRow_Menu_state1_elContato556013_556013">
          <div className="state1_elContato556013">
            <div className="baseFont" style={style_state1_elContato556013} onClick={this.onClick_state1_elContato556013} >
              <div>{this.context.locStrings.menu_contato_556013}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state1_elLogout557043">
            <div>
              <Logout ref={(el)=> this._state1_elLogout557043 = el} />
            </div>
          </div>
          
          </div>
          <div className="hasNestedComps state1_elSelector286199">
            <div>
              <Selector ref={(el)=> this._state1_elSelector286199 = el} />
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <div className="state1_elRect990514" style={style_state1_elRect990514} />
          <div className="state1_elCoUrbaniza461992" style={style_state1_elCoUrbaniza461992} onClick={this.onClick_state1_elCoUrbaniza461992}  />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
