import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
// eslint-disable-next-line
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
// eslint-disable-next-line
import * as util from 'util';
import './App.css';
import ScreenContext from './ScreenContext.js';
import HomeScreen from './HomeScreen.js';
import N404Screen from './N404Screen.js';
import FailedtologinScreen from './FailedtologinScreen.js';
import SubscribeScreen from './SubscribeScreen.js';
import AccountSetupScreen from './AccountSetupScreen.js';
import ErrorScreen from './ErrorScreen.js';
import FeedScreen from './FeedScreen.js';
import WaitScreen from './WaitScreen.js';
import WelcomeScreen from './WelcomeScreen.js';
import GeoaddressScreen from './GeoaddressScreen.js';
import EditonmapScreen from './EditonmapScreen.js';
import PostLoginLandingPageScreen from './PostLoginLandingPageScreen.js';
import PrivacyPolicyScreen from './PrivacyPolicyScreen.js';
import TermsandConditionsScreen from './TermsandConditionsScreen.js';
import LastquestionScreen from './LastquestionScreen.js';
import LoadingfeedScreen from './LoadingfeedScreen.js';
import Collaborative_mapScreen from './Collaborative_mapScreen.js';
import RequestpartnershipScreen from './RequestpartnershipScreen.js';
import NewProjectScreen from './NewProjectScreen.js';
import NewPollScreen from './NewPollScreen.js';
import NewQuestionScreen from './NewQuestionScreen.js';
import CreateonmapScreen from './CreateonmapScreen.js';
import ListProjectsScreen from './ListProjectsScreen.js';
import ProjectScreen from './ProjectScreen.js';
import DataSheet_localizationSheet from './DataSheet_localizationSheet.js';
import DataSheet_users from './DataSheet_users.js';
import DataSheet_agegroup from './DataSheet_agegroup.js';
import DataSheet_gender from './DataSheet_gender.js';
import DataSheet_inviteMailList from './DataSheet_inviteMailList.js';
import DataSheet_plans from './DataSheet_plans.js';
import DataSheet_projects from './DataSheet_projects.js';
import DataSheet_contactType from './DataSheet_contactType.js';
import DataSheet_partnershipMessages from './DataSheet_partnershipMessages.js';
import DataSheet_projectTypes from './DataSheet_projectTypes.js';
import DataSheet_urbanPhases from './DataSheet_urbanPhases.js';
import firebase from 'firebase';
import firestore from 'firebase/firestore';


class App extends Component {
  constructor(props) {
    super(props);

    this.dataSheets = {};
    this.dataSheets['localizationSheet'] = new DataSheet_localizationSheet('localizationSheet', this.dataSheetDidUpdate);
    this.dataSheets['users'] = new DataSheet_users('users', this.dataSheetDidUpdate);
    this.dataSheets['agegroup'] = new DataSheet_agegroup('agegroup', this.dataSheetDidUpdate);
    this.dataSheets['gender'] = new DataSheet_gender('gender', this.dataSheetDidUpdate);
    this.dataSheets['inviteMailList'] = new DataSheet_inviteMailList('inviteMailList', this.dataSheetDidUpdate);
    this.dataSheets['plans'] = new DataSheet_plans('plans', this.dataSheetDidUpdate);
    this.dataSheets['projects'] = new DataSheet_projects('projects', this.dataSheetDidUpdate);
    this.dataSheets['contactType'] = new DataSheet_contactType('contactType', this.dataSheetDidUpdate);
    this.dataSheets['partnershipMessages'] = new DataSheet_partnershipMessages('partnershipMessages', this.dataSheetDidUpdate);
    this.dataSheets['projectTypes'] = new DataSheet_projectTypes('projectTypes', this.dataSheetDidUpdate);
    this.dataSheets['urbanPhases'] = new DataSheet_urbanPhases('urbanPhases', this.dataSheetDidUpdate);
    this.dataSheetLoaded = {};

    this.dataSlots = {};
    this.dataSlots['ds_activeLang'] = localStorage.getItem('ds_activeLang') || "en";
    this.dataSlots['ds_userType'] = localStorage.getItem('ds_userType') || "0";
    this.dataSlots['ds_SlotUserName'] = localStorage.getItem('ds_SlotUserName') || "";
    this.dataSlots['ds_SlotUserEmail'] = localStorage.getItem('ds_SlotUserEmail') || "";
    this.dataSlots['ds_SlotPhoneNumber'] = localStorage.getItem('ds_SlotPhoneNumber') || "";
    this.dataSlots['ds_SlotUserPhoto'] = localStorage.getItem('ds_SlotUserPhoto') || "";
    this.dataSlots['ds_SlotUserId'] = localStorage.getItem('ds_SlotUserId') || "";
    this.dataSlots['ds_userUpdatedFullName'] = localStorage.getItem('ds_userUpdatedFullName') || "";
    this.dataSlots['ds_userName'] = localStorage.getItem('ds_userName') || "me";
    this.dataSlots['ds_userIsSubscribed'] = localStorage.getItem('ds_userIsSubscribed') || "0";
    this.dataSlots['ds_SlotCountry'] = localStorage.getItem('ds_SlotCountry') || "";
    this.dataSlots['ds_SlotAge'] = localStorage.getItem('ds_SlotAge') || "";
    this.dataSlots['ds_mygeo'] = localStorage.getItem('ds_mygeo') || "";
    this.dataSlots['ds_SlotHash'] = localStorage.getItem('ds_SlotHash') || "";
    this.dataSlots['ds_SlotLat'] = localStorage.getItem('ds_SlotLat') || "";
    this.dataSlots['ds_SlotLon'] = localStorage.getItem('ds_SlotLon') || "";
    this.dataSlots['ds_SlotCity'] = localStorage.getItem('ds_SlotCity') || "";
    this.dataSlots['ds_SlotGender'] = localStorage.getItem('ds_SlotGender') || "";
    this.dataSlots['ds_Neighborhood'] = localStorage.getItem('ds_Neighborhood') || "";
    this.dataSlots['ds_SlotFullName'] = localStorage.getItem('ds_SlotFullName') || "";
    this.dataSlots['ds_SlotLoadingAnim'] = localStorage.getItem('ds_SlotLoadingAnim') || "https://firebasestorage.googleapis.com/v0/b/courbaniza.appspot.com/o/images%2Fpreloader.gif?alt=media&token=1702d4e0-db58-41a0-b19b-90e729a74af9";
    this.dataSlots['ds_locationName'] = localStorage.getItem('ds_locationName') || "";
    this.dataSlots['ds_locationId'] = localStorage.getItem('ds_locationId') || "";
    this.dataSlots['ds_organizationName'] = localStorage.getItem('ds_organizationName') || "";
    this.dataSlots['ds_organizationId'] = localStorage.getItem('ds_organizationId') || "";
    this.dataSlots['ds_userStatus'] = localStorage.getItem('ds_userStatus') || "0";
    this.dataSlots['ds_screen_state'] = localStorage.getItem('ds_screen_state') || "";
    this.dataSlots['ds_internetConnection'] = localStorage.getItem('ds_internetConnection') || "";
    this.dataSlots['ds_readConditions'] = localStorage.getItem('ds_readConditions') || "true";
    this.dataSlots['ds_SlotLangSelector'] = localStorage.getItem('ds_SlotLangSelector') || "";
    this.dataSlots['ds_SlotBgWide'] = localStorage.getItem('ds_SlotBgWide') || "false";
    this.dataSlots['ds_SlotWideMenu'] = localStorage.getItem('ds_SlotWideMenu') || "false";
    this.dataSlots['ds_SlotLangSelectorMobile'] = "";
    this.dataSlots['ds_SlotDrawer'] = "";
    this.dataSlots['ds_careAbout'] = localStorage.getItem('ds_careAbout') || "";
    this.dataSlots['ds_profession'] = localStorage.getItem('ds_profession') || "";
    this.dataSlots['ds_type'] = localStorage.getItem('ds_type') || "";
    this.dataSlots['ds_SlotprojectId'] = localStorage.getItem('ds_SlotprojectId') || "";
    this.dataSlots['ds_SlotIprojectIssue'] = localStorage.getItem('ds_SlotIprojectIssue') || "";
    this.dataSlots['ds_SlotprojectTitle'] = localStorage.getItem('ds_SlotprojectTitle') || "";
    this.dataSlots['ds_SlotEmailAuthor'] = localStorage.getItem('ds_SlotEmailAuthor') || "";
    this.dataSlots['ds_SlotprojectName'] = localStorage.getItem('ds_SlotprojectName') || "";
    this.dataSlots['ds_addressComplement'] = localStorage.getItem('ds_addressComplement') || "";
    this.dataSlots['ds_homebackground'] = localStorage.getItem('ds_homebackground') || "https://firebasestorage.googleapis.com/v0/b/courbaniza.appspot.com/o/images%2Fmappins.jpg?alt=media&token=d2ffc2f5-2ee7-4211-966a-0ef89983b171";
    this.dataSlots['ds_projectSummary'] = localStorage.getItem('ds_projectSummary') || "";
    this.dataSlots['ds_projectQuestion'] = localStorage.getItem('ds_projectQuestion') || "";
    this.dataSlots['ds_projectType'] = localStorage.getItem('ds_projectType') || "";
    this.dataSlots['ds_postType'] = localStorage.getItem('ds_postType') || "";
    this.dataSlots['ds_bgmap'] = localStorage.getItem('ds_bgmap') || "false";
    this.dataSlots['ds_projectStatus'] = localStorage.getItem('ds_projectStatus') || "false";
    this.dataSlots['ds_state'] = localStorage.getItem('ds_state') || "";
    this.dataSlots['ds_changeState'] = localStorage.getItem('ds_changeState') || "";
    this.dataSlots['ds_mapLabels'] = localStorage.getItem('ds_mapLabels') || "true";
    this.dataSlots['ds_menuNewIdea'] = localStorage.getItem('ds_menuNewIdea') || "false";
    this.dataSlots['ds_menuNewPoll'] = localStorage.getItem('ds_menuNewPoll') || "false";
    this.dataSlots['ds_menuNewProject'] = localStorage.getItem('ds_menuNewProject') || "false";
    this.dataSlots['ds_menuNewChallenge'] = localStorage.getItem('ds_menuNewChallenge') || "false";
    this.dataSlots['ds_menunewReport'] = localStorage.getItem('ds_menunewReport') || "false";
    this.dataSlots['ds_menuCreate'] = localStorage.getItem('ds_menuCreate') || "false";
    this.dataSlots['ds_mapCreate'] = localStorage.getItem('ds_mapCreate') || "false";
    this.dataSlots['ds_textcolor'] = "";
    this.dataSlots['ds_postPhoto'] = localStorage.getItem('ds_postPhoto') || "";
    this.dataSlots['ds_projectDescript'] = localStorage.getItem('ds_projectDescript') || "";
    this.dataSlots['ds_coverPhoto'] = localStorage.getItem('ds_coverPhoto') || "https://firebasestorage.googleapis.com/v0/b/courbaniza.appspot.com/o/images%2Fnoimagecontn-min.png?alt=media&token=1483cb0a-7d82-4590-8ff8-8445330c95b3";
    this.dataSlots['ds_heroPhoto'] = localStorage.getItem('ds_heroPhoto') || "https://firebasestorage.googleapis.com/v0/b/courbaniza.appspot.com/o/images%2Fnoimagecontn-min.png?alt=media&token=1483cb0a-7d82-4590-8ff8-8445330c95b3";
    this.dataSlots['ds_catchphrase'] = localStorage.getItem('ds_catchphrase') || "";
    this.dataSlots['ds_coverstate'] = "";
    this.dataSlots['ds_herostate'] = "";
    this.dataSlots['ds_editOnmap'] = "false";
    this.dataSlots['ds_gmapstatic'] = localStorage.getItem('ds_gmapstatic') || "<img src=\"https://maps.googleapis.com/maps/api/staticmap?center=40.714728,-73.998672&zoom=12\n&size=400x400&key=AIzaSyBJvMQUeg5bpGqay4ohIGPA-Zt5GSJ-yaU\">";
    this.dataSlots['ds_preloadergif'] = localStorage.getItem('ds_preloadergif') || "false";
    this.dataSlots['ds_ProjectDescripTotalChar'] = localStorage.getItem('ds_ProjectDescripTotalChar') || "0";
    this.dataSlots['ds_fase'] = localStorage.getItem('ds_fase') || "";
    this.dataSlots['ds_faseDescription'] = localStorage.getItem('ds_faseDescription') || "";
    this.dataSlots['ds_projectName'] = localStorage.getItem('ds_projectName') || "";
    this.dataSlots['ds_projectBrief'] = localStorage.getItem('ds_projectBrief') || "";
    this.dataSlots['ds_pickerDefaultValue'] = localStorage.getItem('ds_pickerDefaultValue') || "Select value";
    this.dataSlots['ds_url'] = localStorage.getItem('ds_url') || "...";
    this.dataSlots['ds_loginmodal'] = "";

    this.updateLocalizationFromDataSheet(this.dataSheets['localizationSheet']);


    this.serviceOptions_users = {
      dataSlots: this.dataSlots,
      servicePath: "users",
      query: "where(\"uid\", \"==\",\"$slot('ds_SlotUserId')\")",
    };
    this.dataSheets['users'].appActions = this;
    this.dataSheets['users'].firebase = firebase;
    
    this.serviceOptions_inviteMailList = {
      dataSlots: this.dataSlots,
      servicePath: "InviteMailList",
      query: "orderBy(\"timestamp\",\"desc\")",
    };
    this.dataSheets['inviteMailList'].appActions = this;
    this.dataSheets['inviteMailList'].firebase = firebase;
    
    this.serviceOptions_plans = {
      dataSlots: this.dataSlots,
      servicePath: "Plans",
      query: "",
    };
    this.dataSheets['plans'].appActions = this;
    this.dataSheets['plans'].firebase = firebase;
    
    this.serviceOptions_projects = {
      dataSlots: this.dataSlots,
      servicePath: "group:projects",
      query: "",
    };
    this.dataSheets['projects'].appActions = this;
    this.dataSheets['projects'].firebase = firebase;
    
    this.serviceOptions_partnershipMessages = {
      dataSlots: this.dataSlots,
      servicePath: "partnershipMessages",
      query: "",
    };
    this.dataSheets['partnershipMessages'].appActions = this;
    this.dataSheets['partnershipMessages'].firebase = firebase;
    
    // Initialize web service plugin 'FirebaseConnection'
    firebase.initializeApp({apiKey: "AIzaSyCyf0NT4zkAsrbJUW8F11p3IIWk_-4GPQA",
        authDomain: "courbaniza.firebaseapp.com",
        projectId: "courbaniza",
        storageBucket: "courbaniza.appspot.com",
        messagingSenderId: "769059162979",
        appId: "1:769059162979:web:35436f10c4389e9298a156",
        measurementId: "G-044K3MRGB5"});
    firebase.firestore().settings({});
    

    this.state = {
      screenTransitionForward: true,
    }

    this.events = new EventTarget();
  }

  windowDidResize = () => {
    let w = window.innerWidth;
    let formatId;
    if (w < 576) formatId = 'narrow-phone';
    else if (w < 768) formatId = 'wide-phone';
    else if (w < 1024) formatId = 'narrow-tablet';
    else formatId = 'wide-tablet';
    if (formatId !== this.state.screenFormatId) {
      this.setState({screenFormatId: formatId});
    }
  }

  componentDidMount() {
    this.windowDidResize();
    window.addEventListener('resize', this.windowDidResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowDidResize);
  }

  isLoading() {
    return this.state.loading;
  }

  goToScreen = (screenId, baseScreenId, props) => {
    // Implemented with React Router's 'history' object.
    // 'baseScreenId' is set when inside a container such as a tab bar.
    let path = '/'+screenId;
    if (baseScreenId && baseScreenId.length > 0) {
      path = '/'+baseScreenId+path;
      props = {};
    }
    this.props.history.push(path, {...props});
    window.scrollTo(0, 0);
  }

  goBack = () => {
    // Implemented with React Router's 'history' object.
    this.props.history.goBack();
  }

  getDataSheet = (sheetId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    return this.dataSheets[sheetId];
  }

  addToDataSheet = (sheetId, newRow, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    let sheet = this.dataSheets[sheetId];
    if (sheet && newRow) {
      let promise = sheet.addItem(newRow, this['serviceOptions_'+sheetId] || {});
      this.setState({});
      return promise;
    }
  }

  updateInDataSheet = (sheetId, row, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    let sheet = this.dataSheets[sheetId];
    if (sheet && row) {
      let promise = sheet.replaceItemByKey(row.key, row, this['serviceOptions_'+sheetId] || {});
      this.setState({});
      return promise;
    }
  }

  removeFromDataSheet = (sheetId, row) => {
    let sheet = this.dataSheets[sheetId];
    if (sheet && row) {
      let promise = sheet.removeItem(row, this['serviceOptions_'+sheetId] || {});
      this.setState({});
      return promise;
    }
  }

  updateDataSlot = (slotId, value, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    if (value === this.dataSlots[slotId])
      return;

    this.dataSlots[slotId] = value;

    if (slotId === 'ds_activeLang') {
      this.locStrings.setLanguage(value);
    }

    if (this.serviceOptions_users.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['users'].expandSlotTemplateString(this.serviceOptions_users.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebaseConnection(this.dataSheets['users'], this.serviceOptions_users, false);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['users'].expandSlotTemplateString("users", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_users.servicePath = servicePath;
        this.loadData_firebaseConnection(this.dataSheets['users'], this.serviceOptions_users, false);
      }
    }
    if (this.serviceOptions_inviteMailList.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['inviteMailList'].expandSlotTemplateString(this.serviceOptions_inviteMailList.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebaseConnection(this.dataSheets['inviteMailList'], this.serviceOptions_inviteMailList, false);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['inviteMailList'].expandSlotTemplateString("InviteMailList", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_inviteMailList.servicePath = servicePath;
        this.loadData_firebaseConnection(this.dataSheets['inviteMailList'], this.serviceOptions_inviteMailList, false);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['plans'].expandSlotTemplateString("Plans", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_plans.servicePath = servicePath;
        this.loadData_firebaseConnection(this.dataSheets['plans'], this.serviceOptions_plans, false);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['projects'].expandSlotTemplateString("group:projects", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_projects.servicePath = servicePath;
        this.loadData_firebaseConnection(this.dataSheets['projects'], this.serviceOptions_projects, false);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['partnershipMessages'].expandSlotTemplateString("partnershipMessages", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_partnershipMessages.servicePath = servicePath;
        this.loadData_firebaseConnection(this.dataSheets['partnershipMessages'], this.serviceOptions_partnershipMessages, false);
      }
    }
    if (slotId === 'ds_activeLang') {
      localStorage.setItem('ds_activeLang', value);
    }
    if (slotId === 'ds_userType') {
      localStorage.setItem('ds_userType', value);
    }
    if (slotId === 'ds_SlotUserName') {
      localStorage.setItem('ds_SlotUserName', value);
    }
    if (slotId === 'ds_SlotUserEmail') {
      localStorage.setItem('ds_SlotUserEmail', value);
    }
    if (slotId === 'ds_SlotPhoneNumber') {
      localStorage.setItem('ds_SlotPhoneNumber', value);
    }
    if (slotId === 'ds_SlotUserPhoto') {
      localStorage.setItem('ds_SlotUserPhoto', value);
    }
    if (slotId === 'ds_SlotUserId') {
      localStorage.setItem('ds_SlotUserId', value);
    }
    if (slotId === 'ds_userUpdatedFullName') {
      localStorage.setItem('ds_userUpdatedFullName', value);
    }
    if (slotId === 'ds_userName') {
      localStorage.setItem('ds_userName', value);
    }
    if (slotId === 'ds_userIsSubscribed') {
      localStorage.setItem('ds_userIsSubscribed', value);
    }
    if (slotId === 'ds_SlotCountry') {
      localStorage.setItem('ds_SlotCountry', value);
    }
    if (slotId === 'ds_SlotAge') {
      localStorage.setItem('ds_SlotAge', value);
    }
    if (slotId === 'ds_mygeo') {
      localStorage.setItem('ds_mygeo', value);
    }
    if (slotId === 'ds_SlotHash') {
      localStorage.setItem('ds_SlotHash', value);
    }
    if (slotId === 'ds_SlotLat') {
      localStorage.setItem('ds_SlotLat', value);
    }
    if (slotId === 'ds_SlotLon') {
      localStorage.setItem('ds_SlotLon', value);
    }
    if (slotId === 'ds_SlotCity') {
      localStorage.setItem('ds_SlotCity', value);
    }
    if (slotId === 'ds_SlotGender') {
      localStorage.setItem('ds_SlotGender', value);
    }
    if (slotId === 'ds_Neighborhood') {
      localStorage.setItem('ds_Neighborhood', value);
    }
    if (slotId === 'ds_SlotFullName') {
      localStorage.setItem('ds_SlotFullName', value);
    }
    if (slotId === 'ds_SlotLoadingAnim') {
      localStorage.setItem('ds_SlotLoadingAnim', value);
    }
    if (slotId === 'ds_locationName') {
      localStorage.setItem('ds_locationName', value);
    }
    if (slotId === 'ds_locationId') {
      localStorage.setItem('ds_locationId', value);
    }
    if (slotId === 'ds_organizationName') {
      localStorage.setItem('ds_organizationName', value);
    }
    if (slotId === 'ds_organizationId') {
      localStorage.setItem('ds_organizationId', value);
    }
    if (slotId === 'ds_userStatus') {
      localStorage.setItem('ds_userStatus', value);
    }
    if (slotId === 'ds_screen_state') {
      localStorage.setItem('ds_screen_state', value);
    }
    if (slotId === 'ds_internetConnection') {
      localStorage.setItem('ds_internetConnection', value);
    }
    if (slotId === 'ds_readConditions') {
      localStorage.setItem('ds_readConditions', value);
    }
    if (slotId === 'ds_SlotLangSelector') {
      localStorage.setItem('ds_SlotLangSelector', value);
    }
    if (slotId === 'ds_SlotBgWide') {
      localStorage.setItem('ds_SlotBgWide', value);
    }
    if (slotId === 'ds_SlotWideMenu') {
      localStorage.setItem('ds_SlotWideMenu', value);
    }
    if (slotId === 'ds_careAbout') {
      localStorage.setItem('ds_careAbout', value);
    }
    if (slotId === 'ds_profession') {
      localStorage.setItem('ds_profession', value);
    }
    if (slotId === 'ds_type') {
      localStorage.setItem('ds_type', value);
    }
    if (slotId === 'ds_SlotprojectId') {
      localStorage.setItem('ds_SlotprojectId', value);
    }
    if (slotId === 'ds_SlotIprojectIssue') {
      localStorage.setItem('ds_SlotIprojectIssue', value);
    }
    if (slotId === 'ds_SlotprojectTitle') {
      localStorage.setItem('ds_SlotprojectTitle', value);
    }
    if (slotId === 'ds_SlotEmailAuthor') {
      localStorage.setItem('ds_SlotEmailAuthor', value);
    }
    if (slotId === 'ds_SlotprojectName') {
      localStorage.setItem('ds_SlotprojectName', value);
    }
    if (slotId === 'ds_addressComplement') {
      localStorage.setItem('ds_addressComplement', value);
    }
    if (slotId === 'ds_homebackground') {
      localStorage.setItem('ds_homebackground', value);
    }
    if (slotId === 'ds_projectSummary') {
      localStorage.setItem('ds_projectSummary', value);
    }
    if (slotId === 'ds_projectQuestion') {
      localStorage.setItem('ds_projectQuestion', value);
    }
    if (slotId === 'ds_projectType') {
      localStorage.setItem('ds_projectType', value);
    }
    if (slotId === 'ds_postType') {
      localStorage.setItem('ds_postType', value);
    }
    if (slotId === 'ds_bgmap') {
      localStorage.setItem('ds_bgmap', value);
    }
    if (slotId === 'ds_projectStatus') {
      localStorage.setItem('ds_projectStatus', value);
    }
    if (slotId === 'ds_state') {
      localStorage.setItem('ds_state', value);
    }
    if (slotId === 'ds_changeState') {
      localStorage.setItem('ds_changeState', value);
    }
    if (slotId === 'ds_mapLabels') {
      localStorage.setItem('ds_mapLabels', value);
    }
    if (slotId === 'ds_menuNewIdea') {
      localStorage.setItem('ds_menuNewIdea', value);
    }
    if (slotId === 'ds_menuNewPoll') {
      localStorage.setItem('ds_menuNewPoll', value);
    }
    if (slotId === 'ds_menuNewProject') {
      localStorage.setItem('ds_menuNewProject', value);
    }
    if (slotId === 'ds_menuNewChallenge') {
      localStorage.setItem('ds_menuNewChallenge', value);
    }
    if (slotId === 'ds_menunewReport') {
      localStorage.setItem('ds_menunewReport', value);
    }
    if (slotId === 'ds_menuCreate') {
      localStorage.setItem('ds_menuCreate', value);
    }
    if (slotId === 'ds_mapCreate') {
      localStorage.setItem('ds_mapCreate', value);
    }
    if (slotId === 'ds_postPhoto') {
      localStorage.setItem('ds_postPhoto', value);
    }
    if (slotId === 'ds_projectDescript') {
      localStorage.setItem('ds_projectDescript', value);
    }
    if (slotId === 'ds_coverPhoto') {
      localStorage.setItem('ds_coverPhoto', value);
    }
    if (slotId === 'ds_heroPhoto') {
      localStorage.setItem('ds_heroPhoto', value);
    }
    if (slotId === 'ds_catchphrase') {
      localStorage.setItem('ds_catchphrase', value);
    }
    if (slotId === 'ds_gmapstatic') {
      localStorage.setItem('ds_gmapstatic', value);
    }
    if (slotId === 'ds_preloadergif') {
      localStorage.setItem('ds_preloadergif', value);
    }
    if (slotId === 'ds_ProjectDescripTotalChar') {
      localStorage.setItem('ds_ProjectDescripTotalChar', value);
    }
    if (slotId === 'ds_fase') {
      localStorage.setItem('ds_fase', value);
    }
    if (slotId === 'ds_faseDescription') {
      localStorage.setItem('ds_faseDescription', value);
    }
    if (slotId === 'ds_projectName') {
      localStorage.setItem('ds_projectName', value);
    }
    if (slotId === 'ds_projectBrief') {
      localStorage.setItem('ds_projectBrief', value);
    }
    if (slotId === 'ds_pickerDefaultValue') {
      localStorage.setItem('ds_pickerDefaultValue', value);
    }
    if (slotId === 'ds_url') {
      localStorage.setItem('ds_url', value);
    }
    this.setState({});
  }

  dataSheetDidUpdate = (dataSheet) => {
    // This method is the default implementation and could be customized by a state management plugin.
    this.setState({});
  }

  updateLocalizationFromDataSheet = (dataSheet) => {
    const stringsObj = dataSheet.getStringsByLanguage();
    if (stringsObj && Object.keys(stringsObj).length > 0) {
      this.locStrings = new LocalizedStrings(stringsObj);
    } else {
      this.locStrings = new LocalizedStrings({en: {}});
    }
    this.locStrings.setLanguage(this.dataSlots['ds_activeLang']);
  }

  loadData_firebaseConnection = (dataSheet, options, firstLoad) => {
    // This method was written by data plugin 'Firebase (Cloud Firestore)'.
    this.setState({loading: true});
    
    // clear any placeholder data before load
    if (firstLoad) {
      dataSheet.items = [];
    }
    
    const fetchComplete = (err) => {
      if (err) {
        // This error handling comes from React Studio
        // and currently doesn't do anything useful.
        console.error('** Web service load failed: ', err);
      } else {
      }
      this.setState({loading: false});
    }
    
    const db = firebase.firestore();
    let isCollectionGroup = options.servicePath.startsWith("group:");
    let collection;
    if (isCollectionGroup) {
      collection = db.collectionGroup(options.servicePath.substring(6));
    } else {
      let path = options.servicePath.trim();
      if (path.startsWith("/")) path = path.substring(1);
      if (path.endsWith("/")) path = path.substring(0, path.length-1);
      if ((path.split("/").length-1)%2===0) {
        collection = db.collection(path);
      } else {
        collection = db.doc(path);
      }
    }
    const query = dataSheet.expandSlotTemplateString(options.query, this.dataSlots);
    let queryObj;
    
    if (query.length < 1) {
      queryObj = collection;
    } else {
      console.log("loading firebase data for '%s' with query: %s", options.servicePath, query);
      try {
        queryObj = eval(`(function(c){ return c.${query}; })(collection)`);
      } catch (e) {
        console.log("** error creating firebase query object from '%s': ", query, e)
        return;
      }
    }
    
    queryObj.onSnapshot(
      (querySnapshot) => {
        let jsonArr = [];
        
        if (querySnapshot.docs) {
          querySnapshot.forEach((doc) => {
            const data = { ...doc.data(), document_key: doc.id, document_path: doc.ref.path };
            jsonArr.push(data);
          });
        } else if (querySnapshot.data) {
          const doc = querySnapshot;
          const data = { ...doc.data(), document_key: doc.id, document_path: doc.ref.path };
          jsonArr.push(data);
        }    
            
        dataSheet.loadFromJson(jsonArr);
        fetchComplete(null, options);  
      },
      (err) => {
        fetchComplete(err, options);
      });  
    
    
     /*
    dbLoadingPromise.get().then((querySnapshot) => {
        let jsonArr = [];
    
        querySnapshot.forEach((doc) => {
          const data = { ...doc.data(), key: doc.id };
          jsonArr.push(data);
        });
            
        dataSheet.loadFromJson(jsonArr);
        fetchComplete(null, options);
      },
      (err) => {
        fetchComplete(err, options);
      });  
      */
    
  }

  createImageUrlFromProp = (prop) => {
    if (prop instanceof Object) {
      if (prop.type != null && prop.type === 'image' && prop.path != null) {
        return "(null)"+prop.path;
      }
    }
    return prop;
  }

  render() {
    const makeScreen = (screenId, baseProps, atTop, forward, subpathId) => {
      let screenCtxProps = {
        ...baseProps,
        atTopOfScreenStack: atTop,
        transitionForward: forward,
        appActions: this,
        dataSheets: this.dataSheets,
        locStrings: this.locStrings,
        deviceInfo: {
          screenFormatId: this.state.screenFormatId
        },
        'ds_activeLang': this.dataSlots['ds_activeLang'],
        'ds_userType': this.dataSlots['ds_userType'],
        'ds_SlotUserName': this.dataSlots['ds_SlotUserName'],
        'ds_SlotUserEmail': this.dataSlots['ds_SlotUserEmail'],
        'ds_SlotPhoneNumber': this.dataSlots['ds_SlotPhoneNumber'],
        'ds_SlotUserPhoto': this.dataSlots['ds_SlotUserPhoto'],
        'ds_SlotUserId': this.dataSlots['ds_SlotUserId'],
        'ds_userUpdatedFullName': this.dataSlots['ds_userUpdatedFullName'],
        'ds_userName': this.dataSlots['ds_userName'],
        'ds_userIsSubscribed': this.dataSlots['ds_userIsSubscribed'],
        'ds_SlotCountry': this.dataSlots['ds_SlotCountry'],
        'ds_SlotAge': this.dataSlots['ds_SlotAge'],
        'ds_mygeo': this.dataSlots['ds_mygeo'],
        'ds_SlotHash': this.dataSlots['ds_SlotHash'],
        'ds_SlotLat': this.dataSlots['ds_SlotLat'],
        'ds_SlotLon': this.dataSlots['ds_SlotLon'],
        'ds_SlotCity': this.dataSlots['ds_SlotCity'],
        'ds_SlotGender': this.dataSlots['ds_SlotGender'],
        'ds_Neighborhood': this.dataSlots['ds_Neighborhood'],
        'ds_SlotFullName': this.dataSlots['ds_SlotFullName'],
        'ds_SlotLoadingAnim': this.dataSlots['ds_SlotLoadingAnim'],
        'ds_locationName': this.dataSlots['ds_locationName'],
        'ds_locationId': this.dataSlots['ds_locationId'],
        'ds_organizationName': this.dataSlots['ds_organizationName'],
        'ds_organizationId': this.dataSlots['ds_organizationId'],
        'ds_userStatus': this.dataSlots['ds_userStatus'],
        'ds_screen_state': this.dataSlots['ds_screen_state'],
        'ds_internetConnection': this.dataSlots['ds_internetConnection'],
        'ds_readConditions': this.dataSlots['ds_readConditions'],
        'ds_SlotLangSelector': this.dataSlots['ds_SlotLangSelector'],
        'ds_SlotBgWide': this.dataSlots['ds_SlotBgWide'],
        'ds_SlotWideMenu': this.dataSlots['ds_SlotWideMenu'],
        'ds_SlotLangSelectorMobile': this.dataSlots['ds_SlotLangSelectorMobile'],
        'ds_SlotDrawer': this.dataSlots['ds_SlotDrawer'],
        'ds_careAbout': this.dataSlots['ds_careAbout'],
        'ds_profession': this.dataSlots['ds_profession'],
        'ds_type': this.dataSlots['ds_type'],
        'ds_SlotprojectId': this.dataSlots['ds_SlotprojectId'],
        'ds_SlotIprojectIssue': this.dataSlots['ds_SlotIprojectIssue'],
        'ds_SlotprojectTitle': this.dataSlots['ds_SlotprojectTitle'],
        'ds_SlotEmailAuthor': this.dataSlots['ds_SlotEmailAuthor'],
        'ds_SlotprojectName': this.dataSlots['ds_SlotprojectName'],
        'ds_addressComplement': this.dataSlots['ds_addressComplement'],
        'ds_homebackground': this.dataSlots['ds_homebackground'],
        'ds_projectSummary': this.dataSlots['ds_projectSummary'],
        'ds_projectQuestion': this.dataSlots['ds_projectQuestion'],
        'ds_projectType': this.dataSlots['ds_projectType'],
        'ds_postType': this.dataSlots['ds_postType'],
        'ds_bgmap': this.dataSlots['ds_bgmap'],
        'ds_projectStatus': this.dataSlots['ds_projectStatus'],
        'ds_state': this.dataSlots['ds_state'],
        'ds_changeState': this.dataSlots['ds_changeState'],
        'ds_mapLabels': this.dataSlots['ds_mapLabels'],
        'ds_menuNewIdea': this.dataSlots['ds_menuNewIdea'],
        'ds_menuNewPoll': this.dataSlots['ds_menuNewPoll'],
        'ds_menuNewProject': this.dataSlots['ds_menuNewProject'],
        'ds_menuNewChallenge': this.dataSlots['ds_menuNewChallenge'],
        'ds_menunewReport': this.dataSlots['ds_menunewReport'],
        'ds_menuCreate': this.dataSlots['ds_menuCreate'],
        'ds_mapCreate': this.dataSlots['ds_mapCreate'],
        'ds_textcolor': this.dataSlots['ds_textcolor'],
        'ds_postPhoto': this.dataSlots['ds_postPhoto'],
        'ds_projectDescript': this.dataSlots['ds_projectDescript'],
        'ds_coverPhoto': this.dataSlots['ds_coverPhoto'],
        'ds_heroPhoto': this.dataSlots['ds_heroPhoto'],
        'ds_catchphrase': this.dataSlots['ds_catchphrase'],
        'ds_coverstate': this.dataSlots['ds_coverstate'],
        'ds_herostate': this.dataSlots['ds_herostate'],
        'ds_editOnmap': this.dataSlots['ds_editOnmap'],
        'ds_gmapstatic': this.dataSlots['ds_gmapstatic'],
        'ds_preloadergif': this.dataSlots['ds_preloadergif'],
        'ds_ProjectDescripTotalChar': this.dataSlots['ds_ProjectDescripTotalChar'],
        'ds_fase': this.dataSlots['ds_fase'],
        'ds_faseDescription': this.dataSlots['ds_faseDescription'],
        'ds_projectName': this.dataSlots['ds_projectName'],
        'ds_projectBrief': this.dataSlots['ds_projectBrief'],
        'ds_pickerDefaultValue': this.dataSlots['ds_pickerDefaultValue'],
        'ds_url': this.dataSlots['ds_url'],
        'ds_loginmodal': this.dataSlots['ds_loginmodal'],
      };
      let screen;
      switch (screenId) {
        case 'home':
          screen = <HomeScreen {...baseProps} />;
          break;
        case 'n404':
          screen = <N404Screen {...baseProps} />;
          break;
        case 'failedtologin':
          screen = <FailedtologinScreen {...baseProps} />;
          break;
        case 'subscribe':
          screen = <SubscribeScreen {...baseProps} />;
          break;
        case 'accountSetup':
          screen = <AccountSetupScreen {...baseProps} />;
          break;
        case 'error':
          screen = <ErrorScreen {...baseProps} />;
          break;
        case 'feed':
          screen = <FeedScreen {...baseProps} />;
          break;
        case 'wait':
          screen = <WaitScreen {...baseProps} />;
          break;
        case 'welcome':
          screen = <WelcomeScreen {...baseProps} />;
          break;
        case 'geoaddress':
          screen = <GeoaddressScreen {...baseProps} />;
          break;
        case 'editonmap':
          screen = <EditonmapScreen {...baseProps} />;
          break;
        case 'postLoginLandingPage':
          screen = <PostLoginLandingPageScreen {...baseProps} />;
          break;
        case 'privacyPolicy':
          screen = <PrivacyPolicyScreen {...baseProps} />;
          break;
        case 'termsandConditions':
          screen = <TermsandConditionsScreen {...baseProps} />;
          break;
        case 'lastquestion':
          screen = <LastquestionScreen {...baseProps} />;
          break;
        case 'loadingfeed':
          screen = <LoadingfeedScreen {...baseProps} />;
          break;
        case 'collaborative_map':
          screen = <Collaborative_mapScreen {...baseProps} />;
          break;
        case 'requestpartnership':
          screen = <RequestpartnershipScreen {...baseProps} />;
          break;
        case 'newProject':
          screen = <NewProjectScreen {...baseProps} />;
          break;
        case 'newPoll':
          screen = <NewPollScreen {...baseProps} />;
          break;
        case 'newQuestion':
          screen = <NewQuestionScreen {...baseProps} />;
          break;
        case 'createonmap':
          screen = <CreateonmapScreen {...baseProps} />;
          break;
        case 'listProjects':
          screen = <ListProjectsScreen {...baseProps} />;
          break;
        case 'project':
      // A data sheet row was specified as the data source for this screen, so carry those props + 'dataSheetRow'.
      const dataSheetRow_ProjectScreen = this.dataSheets['projects'].items[parseInt((this.dataSlots.ds_url !== undefined) ? this.dataSlots.ds_url : 0, 10)];
      const screenData_ProjectScreen = {
        ...dataSheetRow_ProjectScreen,
        dataSheetRow: dataSheetRow_ProjectScreen,
      }
          screen = <ProjectScreen {...baseProps} {...screenData_ProjectScreen} />;
          break;
        default:
          screen = null;
      }
      if (screen) {
        return <ScreenContext.Provider value={screenCtxProps}>{screen}</ScreenContext.Provider>;
      }
    }

    return (
      <div className="App">
        <Switch>
          <Route path="/" render={(props) => makeScreen('home', props.location.state, true, true)} exact />
          <Route path="/home" render={(props) => {
            return makeScreen('home', props.location.state, true, true);
          }} />
          <Route path="/n404" render={(props) => {
            return makeScreen('n404', props.location.state, true, true);
          }} />
          <Route path="/failedtologin" render={(props) => {
            return makeScreen('failedtologin', props.location.state, true, true);
          }} />
          <Route path="/subscribe" render={(props) => {
            return makeScreen('subscribe', props.location.state, true, true);
          }} />
          <Route path="/accountSetup" render={(props) => {
            return makeScreen('accountSetup', props.location.state, true, true);
          }} />
          <Route path="/error" render={(props) => {
            return makeScreen('error', props.location.state, true, true);
          }} />
          <Route path="/feed" render={(props) => {
            return makeScreen('feed', props.location.state, true, true);
          }} />
          <Route path="/wait" render={(props) => {
            return makeScreen('wait', props.location.state, true, true);
          }} />
          <Route path="/welcome" render={(props) => {
            return makeScreen('welcome', props.location.state, true, true);
          }} />
          <Route path="/geoaddress" render={(props) => {
            return makeScreen('geoaddress', props.location.state, true, true);
          }} />
          <Route path="/editonmap" render={(props) => {
            return makeScreen('editonmap', props.location.state, true, true);
          }} />
          <Route path="/postLoginLandingPage" render={(props) => {
            return makeScreen('postLoginLandingPage', props.location.state, true, true);
          }} />
          <Route path="/privacyPolicy" render={(props) => {
            return makeScreen('privacyPolicy', props.location.state, true, true);
          }} />
          <Route path="/termsandConditions" render={(props) => {
            return makeScreen('termsandConditions', props.location.state, true, true);
          }} />
          <Route path="/lastquestion" render={(props) => {
            return makeScreen('lastquestion', props.location.state, true, true);
          }} />
          <Route path="/loadingfeed" render={(props) => {
            return makeScreen('loadingfeed', props.location.state, true, true);
          }} />
          <Route path="/collaborative_map" render={(props) => {
            return makeScreen('collaborative_map', props.location.state, true, true);
          }} />
          <Route path="/requestpartnership" render={(props) => {
            return makeScreen('requestpartnership', props.location.state, true, true);
          }} />
          <Route path="/newProject" render={(props) => {
            return makeScreen('newProject', props.location.state, true, true);
          }} />
          <Route path="/newPoll" render={(props) => {
            return makeScreen('newPoll', props.location.state, true, true);
          }} />
          <Route path="/newQuestion" render={(props) => {
            return makeScreen('newQuestion', props.location.state, true, true);
          }} />
          <Route path="/createonmap" render={(props) => {
            return makeScreen('createonmap', props.location.state, true, true);
          }} />
          <Route path="/listProjects" render={(props) => {
            return makeScreen('listProjects', props.location.state, true, true);
          }} />
          <Route path="/project/:projectName" render={(props) => {
            this.updateDataSlot('ds_url', props.match.params.projectName);
            return makeScreen('project', props.location.state, true, true);
          }} />
          <Route path="/project" render={(props) => {
            return makeScreen('project', props.location.state, true, true);
          }} />
          <Route path="/project/:projectName" render={(props) => {
            this.updateDataSlot('ds_url', props.match.params.projectName);
            return makeScreen('project', props.location.state, true, true);
          }} />
          <Route path="/project" render={(props) => {
            return makeScreen('project', props.location.state, true, true);
          }} />
          <Route path="/projeto/:projectName" render={(props) => {
            this.updateDataSlot('ds_url', props.match.params.projectName);
            return makeScreen('project', props.location.state, true, true);
          }} />
          <Route path="/projeto" render={(props) => {
            return makeScreen('project', props.location.state, true, true);
          }} />
          <Route render={(props) => makeScreen('n404', props.location.state, true, true)} />
        </Switch>
      </div>
    );
  }
}
export default withRouter(App)
