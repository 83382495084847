import React, { Component } from 'react';
import './App.css';
import firebase from 'firebase/app';
import 'firebase/firestore';
import FileUploader from 'react-firebase-file-uploader';

import ScreenContext from './ScreenContext';

import Nametitlephasecont from './Nametitlephasecont';
import Editor from './Editor';
import 'quill/dist/quill.snow.css';
import Quill from 'quill';
import Geolocation2 from './Geolocation2';
import CoverCont from './CoverCont';
import HeroCont from './HeroCont';
import Activeinactive from './Activeinactive';

// UI framework component imports
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Button from 'muicss/lib/react/button';

export default class Formpostproject extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    
  var toolbarOptions = [
       [{'size': ['small', false, 'large', 'huge']}],
        ['bold', 'italic'],
        //['blockquote', 'code-block'],
        //[{'header': 1}, {'header': 2}],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        //[{'indent': '-1'}, {'indent': '+1'}],
        //[{'direction': 'rtl'}],
        ['link'],//'image', 'video'
        //[{'color': []}, {'background': []}],
        //[{'font': []}],
        //[{'align': []}]
        ];


        //Control placeholder language
        var ds_activeLang = localStorage.getItem('ds_activeLang');

        if(ds_activeLang === 'en') {
          var options = {
            debug: 'info',
            modules: {
              toolbar: toolbarOptions,
              clipboard: {
                allowed: {
                    tags: ['p'],
                    attributes: ['href', 'rel', 'target', 'class']
                },
                keepSelection: true,
                substituteBlockElements: true,
                magicPasteLinks: true,
                hooks: {
                    uponSanitizeElement(node, data, config) {
                        console.log(node);
                    },
                },
            },
            },
            placeholder: 'Describe your project',
            readOnly: false,
            theme: 'snow',
          }

        } else if (ds_activeLang === 'ptbr') {
          var options = {
            debug: 'info',
            modules: {
              toolbar: toolbarOptions,
              clipboard: {
                allowed: {
                    tags: ['p'],
                    attributes: ['href', 'rel', 'target', 'class']
                },
                keepSelection: true,
                substituteBlockElements: true,
                magicPasteLinks: true,
                hooks: {
                    uponSanitizeElement(node, data, config) {
                        console.log(node);
                    },
                },
              },
            },
            placeholder: 'Descreva aqui projeto',
            readOnly: false,
            theme: 'snow',
          };
        }

        var editor = new Quill('#editor', options);
        //editor.insertText(0, 'Compose an epic here', 'bold', false); //set init value
        //Give focus to the editor
        //editor.focus();


          //display current HTML
          function callMe() {
            var html = editor.root.innerHTML;
            localStorage.setItem("ds_projectDescript", html);
          }
    
          function countMe() {

            var limit = 6000;
            if (editor.getLength() > limit) {
               editor.deleteText(limit, editor.getLength());
               const totalChar = limit - editor.getLength();
               console.log('count:', totalChar);
               localStorage.setItem("ds_ProjectDescripTotalChar", totalChar);
            }
          }

          editor.on('text-change', function(delta, oldDelta, source) {
            if (source == 'api') {
              console.log("An API call triggered this change.");
            } else if (source == 'user') {
              console.log("A user action triggered this change.");
              callMe()
              countMe()
            
              var length = editor.getLength()-1;
              console.log('length:', length);
              localStorage.setItem("ds_ProjectDescripTotalChar", length);
            }
            
          });
          //end editor code
    
  }

  componentWillUnmount() {
    window.localStorage.removeItem('ds_ProjectDescripTotalChar');
    
  }

  componentDidUpdate() {
  }



  // --- Functions for component state index 0 (1 of 5) --- 
  
  
  
  
  getValue_state0_elIssueCatpickerUs = () => {
    return this.state.issueCatpickerUs !== undefined ? this.state.issueCatpickerUs : ((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_projectType'] : ''));
  }
  
  pickerValueChanged_state0_elIssueCatpickerUs = (event) => {
    this.setState({issueCatpickerUs: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_projectType", event.target.value);
  }
  
  onClick_state0_elButton419882903 = async () => {
    const db = firebase.firestore();
    
    	//const ref = firebase.storage().ref().child('projectUploads');
    
        var uid = localStorage.getItem('ds_SlotUserId')
        var address = localStorage.getItem('ds_mygeo')
        var neighborhood = localStorage.getItem('ds_Neighborhood')
        var city = localStorage.getItem('ds_SlotCity')
        var country_name = localStorage.getItem('ds_SlotCountry')
        var lat = localStorage.getItem('ds_SlotLat')
        var lng = localStorage.getItem('ds_SlotLon')
        var postType = 'Project'
        var state = localStorage.getItem('ds_state')
        var userName = localStorage.getItem('ds_SlotUserName')
        var heroPhoto = localStorage.getItem('ds_heroPhoto')
        var catchphrase = localStorage.getItem('ds_catchphrase')
        var coverPhoto = localStorage.getItem('ds_coverPhoto')
        var userEmail = localStorage.getItem('ds_SlotUserEmail')
        var projectDescript = localStorage.getItem('ds_projectDescript')
        var status = localStorage.getItem('ds_projectStatus')
        var projectType = localStorage.getItem('ds_projectType')
        var projectTitle = localStorage.getItem('ds_SlotprojectTitle')
        var projectName = localStorage.getItem('ds_projectName')
        var projectPhase = localStorage.getItem('ds_fase')
        var phaseDescription = localStorage.getItem('ds_faseDescription')
        var summary = localStorage.getItem('ds_projectBrief')
        var projectURL = localStorage.getItem('ds_url')
        
    	var docRef = db.collection(`projects`).doc()
    	
    	   let setDoc = docRef.set({
             projectURL: projectURL,
             projectName: projectName,
             projectTitle: projectTitle,
             postType: postType,
             projectType: projectType,
             summary: summary,
    	     projectDescript: projectDescript,
             heroPhoto: heroPhoto,
             catchphrase: catchphrase,
             coverPhoto: coverPhoto,
             projectPhase: projectPhase,
             phaseDescription: phaseDescription,
             uid: uid,
             userName: userName,
             userEmail: userEmail,
             address: address,
             country_name: country_name,
             city: city,
             neighborhood: neighborhood,
             state: state,
             lng: lng,
             lat: lat,
             status: status,
    	     timestamp: firebase.firestore.FieldValue.serverTimestamp()})
           
    	    .then(setDoc => {
    	   console.log("Document successfully written!");
    	});
    
    
    //var uid = this.context.appActions.dataSlots['ds_SlotUserId']
    const agreeRef = db.collection(`users`).doc(`${uid}`)
    const increment = firebase.firestore.FieldValue.increment(1);
    agreeRef.update({ nmesscreated: increment });
    
  
    // Clear internal state for data-providing elements in this component
    this.setState({
      newprojecttext: (<div></div>),
      newprojecttext_plainText: "",
      categorytxt: (<div></div>),
      categorytxt_plainText: "",
      issueCatpickerUs: -1,
      issueCatpickerPtbr: -1,
      issueCatpickeresEs: -1,
    });
  
  
  }
  
  
  onClick_state0_elButtonCancel1225381004 = async () => {
    // Go back in screen navigation history
    this.context.appActions.goBack();
  
  }
  
  
  renderState0() {
    const style_state0_elNewprojecttext1906127225 = {
      color: '#7f7eff',
      textAlign: 'left',
     };
    const style_state0_elCategorytxt762708970 = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_issueCatpickerUs = this.getValue_state0_elIssueCatpickerUs();
    // Source datasheet and selected data column for picker element 'IssueCatpickerUs'
    const dataSource_issueCatpickerUs = this.context.appActions.getDataSheet('projectTypes');
    const valueColumnName_issueCatpickerUs = 'eng';
    const labelColumnName_issueCatpickerUs = 'eng';
    
    const style_state0_elIssueCatpickerUs = {
      pointerEvents: 'auto',
     };
    const style_state0_elQuillEditor1056489721 = {
      borderRadius: '10.0px',
     };
    const style_state0_elQuillEditor1056489721_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
      borderRadius: '10.0px',
      filter: 'drop-shadow(2.0px 0.0px 2px #eaeaea)',
      overflow: 'visible',
     };
    const style_state0_elMyquill1565220484 = {
      pointerEvents: 'auto',
     };
    let transformStateValue_state0_elCompGeo1495699650 = (input) => {
      clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
        let val = this.context.appActions.dataSlots['ds_screen_state'];
        this.context.appActions.updateDataSlot('ds_screen_state', (val + 1) % 2);
        }, 2000);
      
    
    }
    const style_state0_elCompGeo1495699650 = {
      borderRadius: '10.0px',
      boxShadow: '2.0px 2.0px 2px #eaeaea',
     };
    const style_state0_elCoverCont1891114324 = {
      borderRadius: '10.0px',
      boxShadow: '2.0px 2.0px 2px #eaeaea',
     };
    const style_state0_elHeroCont1391499760 = {
      borderRadius: '10.0px',
      boxShadow: '2.0px 0.0px 2px #eaeaea',
     };
    
    const style_state0_elButton419882903 = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elButtonCancel1225381004 = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#9f9d9f',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elRectangle1851903361 = {
      background: 'rgba(254, 255, 255, 0.000)',
     };
    
    return (
      <div className="Formpostproject">
        <div className="layoutFlow">
          <div className="state0_elNewprojecttext1906127225">
            <div className="headlineFont" style={style_state0_elNewprojecttext1906127225}>
              <div>{this.context.locStrings.formpostmsg_text2_1906127225}</div>
            </div>
          </div>
          
          <div className="state0_elCategorytxt762708970">
            <div className="font-ralewayBold" style={style_state0_elCategorytxt762708970}>
              <div>{this.context.locStrings.formpostmsg_issue_1669386944}</div>
            </div>
          </div>
          
          <div className="state0_elIssueCatpickerUs">
            <Select className="baseFont" style={style_state0_elIssueCatpickerUs}  onChange={this.pickerValueChanged_state0_elIssueCatpickerUs} value={selection_issueCatpickerUs} >
              {dataSource_issueCatpickerUs.items.every(item => {
                return item[valueColumnName_issueCatpickerUs] !== selection_issueCatpickerUs;
              }) ? <Option value=''/> : null}
              {dataSource_issueCatpickerUs.items.map(item => {
                const colValue = item[valueColumnName_issueCatpickerUs];
                const labelColValue = item[labelColumnName_issueCatpickerUs];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="hasNestedComps state0_elNametitlepashCont1340277902">
            <div>
              <Nametitlephasecont ref={(el)=> this._state0_elNametitlepashCont1340277902 = el} />
            </div>
          </div>
          
          <div className="hasNestedComps state0_elQuillEditor1056489721" style={style_state0_elQuillEditor1056489721_outer}>
            <div style={style_state0_elQuillEditor1056489721}>
              <Editor ref={(el)=> this._state0_elQuillEditor1056489721 = el} />
            </div>
          </div>
          
          <div className="state0_elMyquill1565220484">
            <div style={style_state0_elMyquill1565220484} />
          </div>
          
          <div className="hasNestedComps state0_elCompGeo1495699650">
            <div style={style_state0_elCompGeo1495699650}>
              <Geolocation2 visualStateIndex={transformStateValue_state0_elCompGeo1495699650((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_screen_state'] : ''))} ref={(el)=> this._state0_elCompGeo1495699650 = el} />
            </div>
          </div>
          
          <div className="hasNestedComps state0_elCoverCont1891114324">
            <div style={style_state0_elCoverCont1891114324}>
              <CoverCont ref={(el)=> this._state0_elCoverCont1891114324 = el} />
            </div>
          </div>
          
          <div className="hasNestedComps state0_elHeroCont1391499760">
            <div style={style_state0_elHeroCont1391499760}>
              <HeroCont ref={(el)=> this._state0_elHeroCont1391499760 = el} />
            </div>
          </div>
          
          <div className="hasNestedComps state0_elActiveInactiveTread580153632">
            <div>
              <Activeinactive ref={(el)=> this._state0_elActiveInactiveTread580153632 = el} />
            </div>
          </div>
          
          <div className="flowRow flowRow_Formpostproject_state0_elButton419882903_419882903">
          <div className="state0_elButton419882903">
            <Button className="actionFont" style={style_state0_elButton419882903}  color="accent" onClick={this.onClick_state0_elButton419882903} >
              {this.context.locStrings.formpostmsg_button_419882903}
            </Button>
          </div>
          
          <div className="state0_elButtonCancel1225381004">
            <Button className="actionFont" style={style_state0_elButtonCancel1225381004} onClick={this.onClick_state0_elButtonCancel1225381004} >
              {this.context.locStrings.formpostproject_button2_1225381004}
            </Button>
          </div>
          
          </div>
          <div className="state0_elRectangle1851903361">
            <div style={style_state0_elRectangle1851903361} />
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 5) --- 
  
  
  
  
  getValue_state1_elIssueCatpickerPtbr = () => {
    return this.state.issueCatpickerPtbr !== undefined ? this.state.issueCatpickerPtbr : ((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_projectType'] : ''));
  }
  
  pickerValueChanged_state1_elIssueCatpickerPtbr = (event) => {
    this.setState({issueCatpickerPtbr: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_projectType", event.target.value);
  }
  
  onClick_state1_elButton419882903 = async () => {
    const db = firebase.firestore();
    
    	//const ref = firebase.storage().ref().child('projectUploads');
    
        var uid = localStorage.getItem('ds_SlotUserId')
        var address = localStorage.getItem('ds_mygeo')
        var neighborhood = localStorage.getItem('ds_Neighborhood')
        var city = localStorage.getItem('ds_SlotCity')
        var country_name = localStorage.getItem('ds_SlotCountry')
        var lat = localStorage.getItem('ds_SlotLat')
        var lng = localStorage.getItem('ds_SlotLon')
        var postType = 'Project'
        var state = localStorage.getItem('ds_state')
        var userName = localStorage.getItem('ds_SlotUserName')
        var heroPhoto = localStorage.getItem('ds_heroPhoto')
        var catchphrase = localStorage.getItem('ds_catchphrase')
        var coverPhoto = localStorage.getItem('ds_coverPhoto')
        var userEmail = localStorage.getItem('ds_SlotUserEmail')
        var projectDescript = localStorage.getItem('ds_projectDescript')
        var status = localStorage.getItem('ds_projectStatus')
        var projectType = localStorage.getItem('ds_projectType')
        var projectTitle = localStorage.getItem('ds_SlotprojectTitle')
        var projectName = localStorage.getItem('ds_projectName')
        var projectPhase = localStorage.getItem('ds_fase')
        var phaseDescription = localStorage.getItem('ds_faseDescription')
        var summary = localStorage.getItem('ds_projectBrief')
        var projectURL = localStorage.getItem('ds_url')
        
    	var docRef = db.collection(`projects`).doc()
    	
    	   let setDoc = docRef.set({
             projectURL: projectURL,
             projectName: projectName,
             projectTitle: projectTitle,
             postType: postType,
             projectType: projectType,
             summary: summary,
    	     projectDescript: projectDescript,
             heroPhoto: heroPhoto,
             catchphrase: catchphrase,
             coverPhoto: coverPhoto,
             projectPhase: projectPhase,
             phaseDescription: phaseDescription,
             uid: uid,
             userName: userName,
             userEmail: userEmail,
             address: address,
             country_name: country_name,
             city: city,
             neighborhood: neighborhood,
             state: state,
             lng: lng,
             lat: lat,
             status: status,
    	     timestamp: firebase.firestore.FieldValue.serverTimestamp()})
           
    	    .then(setDoc => {
    	   console.log("Document successfully written!");
    	});
    
    
    //var uid = this.context.appActions.dataSlots['ds_SlotUserId']
    const agreeRef = db.collection(`users`).doc(`${uid}`)
    const increment = firebase.firestore.FieldValue.increment(1);
    agreeRef.update({ nmesscreated: increment });
    
  
    // Clear internal state for data-providing elements in this component
    this.setState({
      newprojecttext: (<div></div>),
      newprojecttext_plainText: "",
      categorytxt: (<div></div>),
      categorytxt_plainText: "",
      issueCatpickerUs: -1,
      issueCatpickerPtbr: -1,
      issueCatpickeresEs: -1,
    });
  
  
  }
  
  
  onClick_state1_elButtonCancel1225381004 = async () => {
    // Go back in screen navigation history
    this.context.appActions.goBack();
  
  }
  
  
  renderState1() {
    const style_state1_elNewprojecttext1906127225 = {
      color: '#7f7eff',
      textAlign: 'left',
     };
    const style_state1_elCategorytxt762708970 = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_issueCatpickerPtbr = this.getValue_state1_elIssueCatpickerPtbr();
    // Source datasheet and selected data column for picker element 'IssueCatpickerPtbr'
    const dataSource_issueCatpickerPtbr = this.context.appActions.getDataSheet('projectTypes');
    const valueColumnName_issueCatpickerPtbr = 'ptbr';
    const labelColumnName_issueCatpickerPtbr = 'ptbr';
    
    const style_state1_elIssueCatpickerPtbr = {
      pointerEvents: 'auto',
     };
    const style_state1_elQuillEditor1056489721 = {
      borderRadius: '10.0px',
     };
    const style_state1_elQuillEditor1056489721_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
      borderRadius: '10.0px',
      filter: 'drop-shadow(2.0px 0.0px 2px #eaeaea)',
      overflow: 'visible',
     };
    const style_state1_elMyquill1565220484 = {
      pointerEvents: 'auto',
     };
    let transformStateValue_state1_elCompGeo1495699650 = (input) => {
      clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
        let val = this.context.appActions.dataSlots['ds_screen_state'];
        this.context.appActions.updateDataSlot('ds_screen_state', (val + 1) % 2);
        }, 2000);
      
    
    }
    const style_state1_elCompGeo1495699650 = {
      borderRadius: '10.0px',
      boxShadow: '2.0px 2.0px 2px #eaeaea',
     };
    const style_state1_elCoverCont1891114324 = {
      borderRadius: '10.0px',
      boxShadow: '2.0px 2.0px 2px #eaeaea',
     };
    const style_state1_elHeroCont1391499760 = {
      borderRadius: '10.0px',
      boxShadow: '2.0px 0.0px 2px #eaeaea',
     };
    
    const style_state1_elButton419882903 = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elButtonCancel1225381004 = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#9f9d9f',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elRectangle1851903361 = {
      background: 'rgba(254, 255, 255, 0.000)',
     };
    
    return (
      <div className="Formpostproject">
        <div className="layoutFlow">
          <div className="state1_elNewprojecttext1906127225">
            <div className="headlineFont" style={style_state1_elNewprojecttext1906127225}>
              <div>{this.context.locStrings.formpostmsg_text2_1906127225}</div>
            </div>
          </div>
          
          <div className="state1_elCategorytxt762708970">
            <div className="font-ralewayBold" style={style_state1_elCategorytxt762708970}>
              <div>{this.context.locStrings.formpostmsg_issue_1669386944}</div>
            </div>
          </div>
          
          <div className="state1_elIssueCatpickerPtbr">
            <Select className="baseFont" style={style_state1_elIssueCatpickerPtbr}  onChange={this.pickerValueChanged_state1_elIssueCatpickerPtbr} value={selection_issueCatpickerPtbr} >
              {dataSource_issueCatpickerPtbr.items.every(item => {
                return item[valueColumnName_issueCatpickerPtbr] !== selection_issueCatpickerPtbr;
              }) ? <Option value=''/> : null}
              {dataSource_issueCatpickerPtbr.items.map(item => {
                const colValue = item[valueColumnName_issueCatpickerPtbr];
                const labelColValue = item[labelColumnName_issueCatpickerPtbr];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="hasNestedComps state1_elNametitlepashCont1340277902">
            <div>
              <Nametitlephasecont ref={(el)=> this._state1_elNametitlepashCont1340277902 = el} />
            </div>
          </div>
          
          <div className="hasNestedComps state1_elQuillEditor1056489721" style={style_state1_elQuillEditor1056489721_outer}>
            <div style={style_state1_elQuillEditor1056489721}>
              <Editor ref={(el)=> this._state1_elQuillEditor1056489721 = el} />
            </div>
          </div>
          
          <div className="state1_elMyquill1565220484">
            <div style={style_state1_elMyquill1565220484} />
          </div>
          
          <div className="hasNestedComps state1_elCompGeo1495699650">
            <div style={style_state1_elCompGeo1495699650}>
              <Geolocation2 visualStateIndex={transformStateValue_state1_elCompGeo1495699650((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_screen_state'] : ''))} ref={(el)=> this._state1_elCompGeo1495699650 = el} />
            </div>
          </div>
          
          <div className="hasNestedComps state1_elCoverCont1891114324">
            <div style={style_state1_elCoverCont1891114324}>
              <CoverCont ref={(el)=> this._state1_elCoverCont1891114324 = el} />
            </div>
          </div>
          
          <div className="hasNestedComps state1_elHeroCont1391499760">
            <div style={style_state1_elHeroCont1391499760}>
              <HeroCont ref={(el)=> this._state1_elHeroCont1391499760 = el} />
            </div>
          </div>
          
          <div className="hasNestedComps state1_elActiveInactiveTread580153632">
            <div>
              <Activeinactive ref={(el)=> this._state1_elActiveInactiveTread580153632 = el} />
            </div>
          </div>
          
          <div className="flowRow flowRow_Formpostproject_state1_elButton419882903_419882903">
          <div className="state1_elButton419882903">
            <Button className="actionFont" style={style_state1_elButton419882903}  color="accent" onClick={this.onClick_state1_elButton419882903} >
              {this.context.locStrings.formpostmsg_button_419882903}
            </Button>
          </div>
          
          <div className="state1_elButtonCancel1225381004">
            <Button className="actionFont" style={style_state1_elButtonCancel1225381004} onClick={this.onClick_state1_elButtonCancel1225381004} >
              {this.context.locStrings.formpostproject_button2_1225381004}
            </Button>
          </div>
          
          </div>
          <div className="state1_elRectangle1851903361">
            <div style={style_state1_elRectangle1851903361} />
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 5) --- 
  
  
  
  
  getValue_state2_elIssueCatpickeresEs = () => {
    return this.state.issueCatpickeresEs !== undefined ? this.state.issueCatpickeresEs : ((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_projectType'] : ''));
  }
  
  pickerValueChanged_state2_elIssueCatpickeresEs = (event) => {
    this.setState({issueCatpickeresEs: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_projectType", event.target.value);
  }
  
  onClick_state2_elButton419882903 = async () => {
    const db = firebase.firestore();
    
    	//const ref = firebase.storage().ref().child('projectUploads');
    
        var uid = localStorage.getItem('ds_SlotUserId')
        var address = localStorage.getItem('ds_mygeo')
        var neighborhood = localStorage.getItem('ds_Neighborhood')
        var city = localStorage.getItem('ds_SlotCity')
        var country_name = localStorage.getItem('ds_SlotCountry')
        var lat = localStorage.getItem('ds_SlotLat')
        var lng = localStorage.getItem('ds_SlotLon')
        var postType = 'Project'
        var state = localStorage.getItem('ds_state')
        var userName = localStorage.getItem('ds_SlotUserName')
        var heroPhoto = localStorage.getItem('ds_heroPhoto')
        var catchphrase = localStorage.getItem('ds_catchphrase')
        var coverPhoto = localStorage.getItem('ds_coverPhoto')
        var userEmail = localStorage.getItem('ds_SlotUserEmail')
        var projectDescript = localStorage.getItem('ds_projectDescript')
        var status = localStorage.getItem('ds_projectStatus')
        var projectType = localStorage.getItem('ds_projectType')
        var projectTitle = localStorage.getItem('ds_SlotprojectTitle')
        var projectName = localStorage.getItem('ds_projectName')
        var projectPhase = localStorage.getItem('ds_fase')
        var phaseDescription = localStorage.getItem('ds_faseDescription')
        var summary = localStorage.getItem('ds_projectBrief')
        var projectURL = localStorage.getItem('ds_url')
        
    	var docRef = db.collection(`projects`).doc()
    	
    	   let setDoc = docRef.set({
             projectURL: projectURL,
             projectName: projectName,
             projectTitle: projectTitle,
             postType: postType,
             projectType: projectType,
             summary: summary,
    	     projectDescript: projectDescript,
             heroPhoto: heroPhoto,
             catchphrase: catchphrase,
             coverPhoto: coverPhoto,
             projectPhase: projectPhase,
             phaseDescription: phaseDescription,
             uid: uid,
             userName: userName,
             userEmail: userEmail,
             address: address,
             country_name: country_name,
             city: city,
             neighborhood: neighborhood,
             state: state,
             lng: lng,
             lat: lat,
             status: status,
    	     timestamp: firebase.firestore.FieldValue.serverTimestamp()})
           
    	    .then(setDoc => {
    	   console.log("Document successfully written!");
    	});
    
    
    //var uid = this.context.appActions.dataSlots['ds_SlotUserId']
    const agreeRef = db.collection(`users`).doc(`${uid}`)
    const increment = firebase.firestore.FieldValue.increment(1);
    agreeRef.update({ nmesscreated: increment });
    
  
    // Clear internal state for data-providing elements in this component
    this.setState({
      newprojecttext: (<div></div>),
      newprojecttext_plainText: "",
      categorytxt: (<div></div>),
      categorytxt_plainText: "",
      issueCatpickerUs: -1,
      issueCatpickerPtbr: -1,
      issueCatpickeresEs: -1,
    });
  
  
  }
  
  
  onClick_state2_elButtonCancel1225381004 = async () => {
    // Go back in screen navigation history
    this.context.appActions.goBack();
  
  }
  
  
  renderState2() {
    const style_state2_elNewprojecttext1906127225 = {
      color: '#7f7eff',
      textAlign: 'left',
     };
    const style_state2_elCategorytxt762708970 = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_issueCatpickeresEs = this.getValue_state2_elIssueCatpickeresEs();
    // Source datasheet and selected data column for picker element 'IssueCatpickeresEs'
    const dataSource_issueCatpickeresEs = this.context.appActions.getDataSheet('projectTypes');
    const valueColumnName_issueCatpickeresEs = 'esp';
    const labelColumnName_issueCatpickeresEs = 'esp';
    
    const style_state2_elIssueCatpickeresEs = {
      pointerEvents: 'auto',
     };
    const style_state2_elQuillEditor1056489721 = {
      borderRadius: '10.0px',
     };
    const style_state2_elQuillEditor1056489721_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
      borderRadius: '10.0px',
      filter: 'drop-shadow(2.0px 0.0px 2px #eaeaea)',
      overflow: 'visible',
     };
    const style_state2_elMyquill1565220484 = {
      pointerEvents: 'auto',
     };
    let transformStateValue_state2_elCompGeo1495699650 = (input) => {
      clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
        let val = this.context.appActions.dataSlots['ds_screen_state'];
        this.context.appActions.updateDataSlot('ds_screen_state', (val + 1) % 2);
        }, 2000);
      
    
    }
    const style_state2_elCompGeo1495699650 = {
      borderRadius: '10.0px',
      boxShadow: '2.0px 2.0px 2px #eaeaea',
     };
    const style_state2_elCoverCont1891114324 = {
      borderRadius: '10.0px',
      boxShadow: '2.0px 2.0px 2px #eaeaea',
     };
    const style_state2_elHeroCont1391499760 = {
      borderRadius: '10.0px',
      boxShadow: '2.0px 0.0px 2px #eaeaea',
     };
    
    const style_state2_elButton419882903 = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state2_elButtonCancel1225381004 = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#9f9d9f',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state2_elRectangle1851903361 = {
      background: 'rgba(254, 255, 255, 0.000)',
     };
    
    return (
      <div className="Formpostproject">
        <div className="layoutFlow">
          <div className="state2_elNewprojecttext1906127225">
            <div className="headlineFont" style={style_state2_elNewprojecttext1906127225}>
              <div>{this.context.locStrings.formpostmsg_text2_1906127225}</div>
            </div>
          </div>
          
          <div className="state2_elCategorytxt762708970">
            <div className="font-ralewayBold" style={style_state2_elCategorytxt762708970}>
              <div>{this.context.locStrings.formpostmsg_issue_1669386944}</div>
            </div>
          </div>
          
          <div className="state2_elIssueCatpickeresEs">
            <Select className="baseFont" style={style_state2_elIssueCatpickeresEs}  onChange={this.pickerValueChanged_state2_elIssueCatpickeresEs} value={selection_issueCatpickeresEs} >
              {dataSource_issueCatpickeresEs.items.every(item => {
                return item[valueColumnName_issueCatpickeresEs] !== selection_issueCatpickeresEs;
              }) ? <Option value=''/> : null}
              {dataSource_issueCatpickeresEs.items.map(item => {
                const colValue = item[valueColumnName_issueCatpickeresEs];
                const labelColValue = item[labelColumnName_issueCatpickeresEs];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="hasNestedComps state2_elNametitlepashCont1340277902">
            <div>
              <Nametitlephasecont ref={(el)=> this._state2_elNametitlepashCont1340277902 = el} />
            </div>
          </div>
          
          <div className="hasNestedComps state2_elQuillEditor1056489721" style={style_state2_elQuillEditor1056489721_outer}>
            <div style={style_state2_elQuillEditor1056489721}>
              <Editor ref={(el)=> this._state2_elQuillEditor1056489721 = el} />
            </div>
          </div>
          
          <div className="state2_elMyquill1565220484">
            <div style={style_state2_elMyquill1565220484} />
          </div>
          
          <div className="hasNestedComps state2_elCompGeo1495699650">
            <div style={style_state2_elCompGeo1495699650}>
              <Geolocation2 visualStateIndex={transformStateValue_state2_elCompGeo1495699650((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_screen_state'] : ''))} ref={(el)=> this._state2_elCompGeo1495699650 = el} />
            </div>
          </div>
          
          <div className="hasNestedComps state2_elCoverCont1891114324">
            <div style={style_state2_elCoverCont1891114324}>
              <CoverCont ref={(el)=> this._state2_elCoverCont1891114324 = el} />
            </div>
          </div>
          
          <div className="hasNestedComps state2_elHeroCont1391499760">
            <div style={style_state2_elHeroCont1391499760}>
              <HeroCont ref={(el)=> this._state2_elHeroCont1391499760 = el} />
            </div>
          </div>
          
          <div className="hasNestedComps state2_elActiveInactiveTread580153632">
            <div>
              <Activeinactive ref={(el)=> this._state2_elActiveInactiveTread580153632 = el} />
            </div>
          </div>
          
          <div className="flowRow flowRow_Formpostproject_state2_elButton419882903_419882903">
          <div className="state2_elButton419882903">
            <Button className="actionFont" style={style_state2_elButton419882903}  color="accent" onClick={this.onClick_state2_elButton419882903} >
              {this.context.locStrings.formpostmsg_button_419882903}
            </Button>
          </div>
          
          <div className="state2_elButtonCancel1225381004">
            <Button className="actionFont" style={style_state2_elButtonCancel1225381004} onClick={this.onClick_state2_elButtonCancel1225381004} >
              {this.context.locStrings.formpostproject_button2_1225381004}
            </Button>
          </div>
          
          </div>
          <div className="state2_elRectangle1851903361">
            <div style={style_state2_elRectangle1851903361} />
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 3 (4 of 5) --- 
  
  renderState3() {
    const style_state3_elMyquill1565220484 = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Formpostproject">
        <div className="layoutFlow">
          <div className="state3_elMyquill1565220484">
            <div style={style_state3_elMyquill1565220484} />
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 4 (5 of 5) --- 
  
  renderState4() {
    const style_state4_elMyquill1565220484 = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Formpostproject">
        <div className="layoutFlow">
          <div className="state4_elMyquill1565220484">
            <div style={style_state4_elMyquill1565220484} />
          </div>
        </div>
        
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
      case 3:
        return this.renderState3();
      case 4:
        return this.renderState4();
    }
  }
  
}
