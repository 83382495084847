// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_gender extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "01";
    item['genderptbr'] = "Masculino";
    item['gender'] = "Male";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "02";
    item['genderptbr'] = "Feminino";
    item['gender'] = "Female";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "03";
    item['genderptbr'] = "Não binário";
    item['gender'] = "Non-binary";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "04";
    item['genderptbr'] = "Transexual";
    item['gender'] = "Transgender";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "05";
    item['genderptbr'] = "Intersex";
    item['gender'] = "Intersex";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "06";
    item['genderptbr'] = "Inconformidade de gênero";
    item['gender'] = "Gender Non-Conforming";
    item.key = key++;
  }

}
