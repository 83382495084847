import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import Nametitle from './Nametitle';
import Phase from './Phase';

export default class Nametitlephasecont extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    let transformStateValue_elColumns_col1 = (input) => {
      var coverPhoto = localStorage.getItem("ds_coverPhoto")
            if(coverPhoto === '' || coverPhoto === null){
            //  console.log('coverPhoto', coverPhoto)
              return 1;
              } else {
              return 0;
              }
    }
    
    return (
      <div className="Nametitlephasecont">
        <div className="layoutFlow">
          <div className="hasNestedComps elColumns">
            <div>
              <div className="col0">
                <Nametitle {...this.props} />
              </div>
              <div className="col1">
                <Phase {...this.props} visualStateIndex={transformStateValue_elColumns_col1((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_coverstate'] : ''))} />
              </div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
