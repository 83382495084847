import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import CompmyGeo from './CompmyGeo';
import img_state0_elMapgeo792794 from './images/Geolocation_state0_elMapgeo792794_792794.png';
import img_state1_elMapgeo792794 from './images/Geolocation_state0_elMapgeo792794_792794.png';

// UI framework component imports
import Input from 'muicss/lib/react/input';

export default class Geolocation extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  
  
  onClick_state0_elTextCopy300259 = async () => {
    // Go to screen 'editonmap'
    this.context.appActions.goToScreen('editonmap', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state0_elMapgeo792794 = async () => {
    // Go to screen 'editonmap'
    this.context.appActions.goToScreen('editonmap', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  
  textInputChanged_state0_elField52603 = (event) => {
    this.setState({field: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_addressComplement", event.target.value);
  }
  
  getValue_state0_elField52603 = () => {
    return this.state.field || '';
  }
  
  renderState0() {
    const style_state0_elText837337 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_state0_elTextCopy300259 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elMapgeo792794 = {
      backgroundImage: 'url('+img_state0_elMapgeo792794+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elText2882654 = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_state0_elField52603 = {
      display: 'block',
      paddingTop: 0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Geolocation">
        <div className="layoutFlow">
          <div className="state0_elText837337">
            <div className="headlineFont" style={style_state0_elText837337}>
              <div>{this.context.locStrings.comp1_text_837337}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state0_elCompmyGeo765090">
            <div>
              <CompmyGeo {...this.props} ref={(el)=> this._state0_elCompmyGeo765090 = el} />
            </div>
          </div>
          
          <div className="flowRow flowRow_Geolocation_state0_elTextCopy300259_300259">
          <div className="state0_elTextCopy300259">
            <div className="baseFont" style={style_state0_elTextCopy300259} onClick={this.onClick_state0_elTextCopy300259} >
              <div>{this.context.locStrings.comp1_textcopy_300259}</div>
            </div>
          </div>
          
          </div>
          <div className="state0_elMapgeo792794">
            <div style={style_state0_elMapgeo792794} onClick={this.onClick_state0_elMapgeo792794}  />
          </div>
          
          <div className="state0_elText2882654">
            <div className="font-ralewayBold" style={style_state0_elText2882654}>
              <div>{this.context.locStrings.geolocation_text2_882654}</div>
            </div>
          </div>
          
          <div className="state0_elField52603">
            <Input className="baseFont" style={style_state0_elField52603} type="text" placeholder={this.context.locStrings.geolocation_field_52603} onChange={this.textInputChanged_state0_elField52603} value={this.getValue_state0_elField52603()}  />
          </div>
          
          <div className="state0_elSpacer1513867061">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  
  
  onClick_state1_elTextCopy300259 = async () => {
    // Go to screen 'editonmap'
    this.context.appActions.goToScreen('editonmap', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state1_elMapgeo792794 = async () => {
    // Go to screen 'editonmap'
    this.context.appActions.goToScreen('editonmap', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  
  textInputChanged_state1_elField52603 = (event) => {
    this.setState({field: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_addressComplement", event.target.value);
  }
  
  getValue_state1_elField52603 = () => {
    return this.state.field || '';
  }
  
  renderState1() {
    const style_state1_elText837337 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_state1_elTextCopy300259 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elMapgeo792794 = {
      backgroundImage: 'url('+img_state1_elMapgeo792794+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elText2882654 = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_state1_elField52603 = {
      display: 'block',
      paddingTop: 0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Geolocation">
        <div className="layoutFlow">
          <div className="state1_elText837337">
            <div className="headlineFont" style={style_state1_elText837337}>
              <div>{this.context.locStrings.comp1_text_837337}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state1_elCompmyGeo765090">
            <div>
              <CompmyGeo {...this.props} ref={(el)=> this._state1_elCompmyGeo765090 = el} />
            </div>
          </div>
          
          <div className="flowRow flowRow_Geolocation_state1_elTextCopy300259_300259">
          <div className="state1_elTextCopy300259">
            <div className="baseFont" style={style_state1_elTextCopy300259} onClick={this.onClick_state1_elTextCopy300259} >
              <div>{this.context.locStrings.comp1_textcopy_300259}</div>
            </div>
          </div>
          
          </div>
          <div className="state1_elMapgeo792794">
            <div style={style_state1_elMapgeo792794} onClick={this.onClick_state1_elMapgeo792794}  />
          </div>
          
          <div className="state1_elText2882654">
            <div className="font-ralewayBold" style={style_state1_elText2882654}>
              <div>{this.context.locStrings.geolocation_text2_882654}</div>
            </div>
          </div>
          
          <div className="state1_elField52603">
            <Input className="baseFont" style={style_state1_elField52603} type="text" placeholder={this.context.locStrings.geolocation_field_52603} onChange={this.textInputChanged_state1_elField52603} value={this.getValue_state1_elField52603()}  />
          </div>
          
          <div className="state1_elSpacer1513867061">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
