import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import HeroHearderProject from './HeroHearderProject';
import ProjectItem from './ProjectItem';
import ProjectItem2 from './ProjectItem2';

export default class SingleProject extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // heroPhoto, catchphrase, dataSheetRow

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    // eslint-disable-next-line
    const dataSheet_projects = this.context.appActions.getDataSheet('projects');
    
    return (
      <div className="SingleProject">
        <div className="layoutFlow">
          <div className="hasNestedComps elHeroHearderProject">
            <div>
              <HeroHearderProject catchphrase={this.props.dataSheetRow ? this.props.dataSheetRow.catchphrase : ''} heroPhoto={this.props.dataSheetRow ? this.props.dataSheetRow.heroPhoto : ''} {...this.props} ref={(el)=> this._elHeroHearderProject = el} />
            </div>
          </div>
          
          <div className="hasNestedComps elColumns">
            <div>
              <div className="col0">
                <ProjectItem {...this.props} />
              </div>
              <div className="col1">
                <ProjectItem2 {...this.props} />
              </div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
