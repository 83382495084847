import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class Complegend extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
      borderRadius: '5.0px',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#929292',
      borderRadius: '5.0px',
     };
    const style_elMaplegend = {
      fontSize: 12.0,
      color: '#feffff',
      textAlign: 'left',
     };
    // eslint-disable-next-line no-unused-vars
    const style_elMaplegend_inner = {
      textAlign: 'left',
     };
    
    return (
      <div className="Complegend">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="foreground">
          <div className="systemFontRegular  elMaplegend" style={style_elMaplegend}>
            <div className="bottomAlignedContent">{this.context.locStrings.comp8_maplegend_519568}</div>
          </div>
        </div>
      </div>
    )
  }
  
}
