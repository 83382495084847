import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import './App.css';
import ScreenContext from './ScreenContext';

import img_state0_elEarthglobe466375 from './images/Selector_state0_elEarthglobe466375_466375.png';
import img_state1_elEarthglobe466375 from './images/Selector_state0_elEarthglobe466375_466375.png';

export default class Selector extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  
  onClick_state0_elLanguage = async () => {
    let newVal = "true";
    this.context.appActions.updateDataSlot('ds_SlotLangSelector', newVal);
  
    this.setState({visualStateIndexOverride: 1})
  
    const analytics = firebase.analytics();
    firebase.analytics().logEvent('Change Language');
  
  }
  
  
  renderState0() {
    const style_state0_elLanguage = {
      color: '#feffff',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elEarthglobe466375 = {
      backgroundImage: 'url('+img_state0_elEarthglobe466375+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    return (
      <div className="Selector">
        <div className="foreground">
          <div className="baseFont state0_elLanguage" style={style_state0_elLanguage} onClick={this.onClick_state0_elLanguage} >
            <div>{this.context.locStrings.selector_language_413938}</div>
          </div>
          <div className="state0_elEarthglobe466375" style={style_state0_elEarthglobe466375} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  
  onClick_state1_elLanguage = async () => {
    let newVal = "false";
    this.context.appActions.updateDataSlot('ds_SlotLangSelector', newVal);
  
    this.setState({visualStateIndexOverride: 0})
  
    const analytics = firebase.analytics();
    firebase.analytics().logEvent('Change Language to Ptbr');
  
  }
  
  
  renderState1() {
    const style_state1_elLanguage = {
      color: '#feffff',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elEarthglobe466375 = {
      backgroundImage: 'url('+img_state1_elEarthglobe466375+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    return (
      <div className="Selector">
        <div className="foreground">
          <div className="baseFont state1_elLanguage" style={style_state1_elLanguage} onClick={this.onClick_state1_elLanguage} >
            <div>{this.context.locStrings.selector_language_273205}</div>
          </div>
          <div className="state1_elEarthglobe466375" style={style_state1_elEarthglobe466375} />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
