import React, { Component } from 'react';
import 'firebase/storage';
import './App.css';
import ScreenContext from './ScreenContext';
import img_state0_elNoimagecont from './images/Uploadcoverphoto_state0_elNoimagecont_450140778.jpg';
import btn_icon_1503964009 from './images/btn_icon_1503964009.png';
import img_state1_elNoimagecont from './images/Uploadcoverphoto_state0_elNoimagecont_450140778.jpg';
import firebase from 'firebase';
import FileUploader from 'react-firebase-file-uploader';

export default class Uploadcoverphoto extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elIconButton = async () => {
    var storage = firebase.storage()
    var url = localStorage.getItem("ds_coverPhoto")
    let pictureRef = storage.refFromURL(url);
    // Delete the file
    pictureRef.delete().then(() => {
     // File deleted successfully
      }).catch((error) => {
        // Uh-oh, an error occurred!
      });
      //END storage_delete_file
  
  
    this.setState({visualStateIndexOverride: 1})
  
  }
  
  
  renderState0() {
    const style_state0_elNoimagecont = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_coverPhoto'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      borderRadius: '20.0px',
     };
    
    let cssClass_progress = 'circularProgressIndicator';
    if (this.context.appActions.isLoading()) 
      cssClass_progress += ' circularProgressIndicator-active';
    
    
    const style_state0_elIconButton = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1503964009+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '49.931%',
      backgroundPosition: '50% 0%',
      color: '#feffff',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Uploadcoverphoto">
        <div className="layoutFlow">
          <div className="state0_elNoimagecont">
            <div style={style_state0_elNoimagecont} />
          </div>
          
          <div className="state0_elSpacer">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <div className="state0_elProgress369463656">
            <svg className={cssClass_progress}>
              <circle className="circularProgress-animatedPath" style={{stroke: '#7f7eff'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
            </svg>
          </div>
          <button className="actionFont state0_elIconButton" style={style_state0_elIconButton} onClick={this.onClick_state0_elIconButton}  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  
  renderState1() {
    const style_state1_elNoimagecont = {
      backgroundImage: 'url('+img_state1_elNoimagecont+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      borderRadius: '20.0px',
     };
    const style_state1_elFirebaseUpload = {
      pointerEvents: 'auto',
     };
    
    let cssClass_progress = 'circularProgressIndicator';
    if (this.context.appActions.isLoading()) 
      cssClass_progress += ' circularProgressIndicator-active';
    
    const style_state1_elText = {
      color: '#feffff',
      textAlign: 'center',
     };
    
    return (
      <div className="Uploadcoverphoto">
        <div className="layoutFlow">
          <div className="state1_elNoimagecont">
            <div style={style_state1_elNoimagecont} />
          </div>
          
          <div className="state1_elFirebaseUpload">
            <div style={style_state1_elFirebaseUpload}>
              <FileUploader
                id="firebaseUpload_inputEl"
                accept="image/*"
                randomizeFilename
                storageRef={firebase.storage().ref("projectUploads")}
                onUploadStart={() => {
                  this.context.appActions.setState({loading: true});
                }}
                onUploadError={error => {
                  this.context.appActions.setState({loading: false});
                  console.error('** Firebase upload failed: ', error);
                }}
                onUploadSuccess={filename => {
                  this.context.appActions.setState({loading: false});
                  firebase.storage().ref("projectUploads").child(filename).getDownloadURL().then(url => {
                    let setDataSlotValue = (v) => { this.context.appActions.updateDataSlot("ds_coverPhoto", v) };
                    setDataSlotValue(url);
                    let setfilenameDataSlotValue = (v) => { this.context.appActions.updateDataSlot("", v) };
                    setfilenameDataSlotValue(filename);
                  }).catch(function(error) {
                    console.error('** Firebase upload failed: ', error);
                  });
                }}
                />
            </div>
          </div>
          
          <div className="state1_elSpacer">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <div className="state1_elProgress369463656">
            <svg className={cssClass_progress}>
              <circle className="circularProgress-animatedPath" style={{stroke: '#7f7eff'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
            </svg>
          </div>
          <div className="baseFont state1_elText" style={style_state1_elText}>
            <div>{this.context.locStrings.uploadcoverphoto_text_686659535}</div>
          </div>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
