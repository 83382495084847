import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class Backgroundtrans extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elBgmodal = async () => {
    let newVal = "false";
    this.context.appActions.updateDataSlot('ds_bgmap', newVal);
  
  }
  
  
  render() {
    
    const style_elBgmodal = {
      background: 'rgba(121, 121, 121, 1.000)',
      opacity: 0.90,
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Backgroundtrans">
        <div className="foreground">
          <div className="containerMinHeight elBgmodal" style={style_elBgmodal} onClick={this.onClick_elBgmodal}  />
        </div>
      </div>
    )
  }
  
}
