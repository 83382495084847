import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

// UI framework component imports
import Input from 'muicss/lib/react/input';
import Textarea from 'muicss/lib/react/textarea';

export default class Phase extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  textInputChanged_elField = (event) => {
    this.setState({field: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_fase", event.target.value);
  }
  
  getValue_elField = () => {
    return (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_fase'] : '');
  }
  
  textAreaChanged_elTextareabriefdescrip = (event) => {
    this.setState({textareabriefdescrip: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_faseDescription", event.target.value);
  }
  
  getValue_elTextareabriefdescrip = () => {
    let transformPropValue_textareabriefdescrip = (input) => {
      var maxLength = 140
      return input.substr(0, maxLength);
    }
    
    return transformPropValue_textareabriefdescrip((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_faseDescription'] : ''));
  }
  
  
  getValue_elTextareaCount = () => {
  let transformPropValue_textareaCount = (input) => {
    input = this.context.appActions.dataSlots['ds_faseDescription'].length
    var charactersLeft = 140 - input;
    return charactersLeft;
  }
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_textareaCount((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_faseDescription'] : '')));
  }
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
      borderRadius: '10.0px',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
      borderRadius: '10.0px',
      filter: 'drop-shadow(2.0px 2.0px 2px #eaeaea)',
      overflow: 'visible',
     };
    const style_elAddphoto = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elAddphototext = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elField = {
      display: 'block',
      paddingTop: 0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_elTextareabriefdescrip = {
      display: 'block',
      pointerEvents: 'auto',
     };
    
    const value_textareaCount = this.getValue_elTextareaCount();
    
    const style_elTextareaCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    
    return (
      <div className="Phase">
        <div className="background">
          <div className="elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="elAddphoto">
            <div className="font-ralewayBold" style={style_elAddphoto}>
              <div>{this.context.locStrings.herophototext2_addphoto_212002286}</div>
            </div>
          </div>
          
          <div className="elAddphototext">
            <div className="baseFont" style={style_elAddphototext}>
              <div>{this.context.locStrings.herophototext2_addphototext_442347318}</div>
            </div>
          </div>
          
          <div className="elField">
            <Input className="baseFont" style={style_elField} type="text" placeholder={this.context.locStrings.herophototext2_textarea_424826726} onChange={this.textInputChanged_elField} value={this.getValue_elField()}  />
          </div>
          
          <div className="elTextareabriefdescrip">
            <Textarea className="baseFont" style={style_elTextareabriefdescrip}  placeholder={this.context.locStrings.phase_textareacopy_1661639040} onChange={this.textAreaChanged_elTextareabriefdescrip} value={this.getValue_elTextareabriefdescrip()}  />
          </div>
          
          <div className="elTextareaCount">
            <div className="baseFont" style={style_elTextareaCount}>
              <div>{value_textareaCount}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
