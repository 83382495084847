import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

import Component1home from './Component1home';
import Spot1 from './Spot1';
import Spot2 from './Spot2';
import Spot3 from './Spot3';
import Spot4 from './Spot4';
import Menumobile from './Menumobile';
import Menu from './Menu';
import OpenlanguageSelector from './OpenlanguageSelector';
import MenuWeb from './MenuWeb';
import Empty from './Empty';
import Backgroundtrans from './Backgroundtrans';
import Menucreate from './Menucreate';
import Compnavfotterh from './Compnavfotterh';

export default class FeedScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
      elCompnafooter_stateIndex: 0,
    };
  }

  componentDidMount() {
    
    window.localStorage.removeItem('ds_bgmap')
	window.localStorage.removeItem('ds_menuCreate')
    
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elCompbg = async () => {
    let newVal = "false";
    this.context.appActions.updateDataSlot('ds_bgmap', newVal);
  
    newVal = "false";
    this.context.appActions.updateDataSlot('ds_menuCreate', newVal);
  
    this.setState({elCompnafooter_stateIndex: 0});
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    
    let contentElement_elSwapper;  // This element's content can vary based on screen size
    contentElement_elSwapper = (<Menumobile />);
    if (this.context.deviceInfo.screenFormatId === 'narrow-phone') {
      contentElement_elSwapper = (<Menumobile />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'wide-phone') {
      contentElement_elSwapper = (<Menumobile />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'narrow-tablet') {
      contentElement_elSwapper = (<Menu />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'wide-tablet') {
      contentElement_elSwapper = (<Menu />);
    }
    
    const elOpenlanguageSelector = ((val) => { return val === "true" || val == true || val == 1 })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotLangSelector'] : '')) ? (
      <div className="hasNestedComps elOpenlanguageSelector">
        <OpenlanguageSelector ref={(el)=> this._elOpenlanguageSelector = el} />
      </div>
     ) : null;
    
    let contentElement_elSwapperSidebar;  // This element's content can vary based on screen size
    contentElement_elSwapperSidebar = (<MenuWeb />);
    if (this.context.deviceInfo.screenFormatId === 'narrow-phone') {
      contentElement_elSwapperSidebar = (<Empty />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'wide-phone') {
      contentElement_elSwapperSidebar = (<Empty />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'narrow-tablet') {
      contentElement_elSwapperSidebar = (<MenuWeb />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'wide-tablet') {
      contentElement_elSwapperSidebar = (<MenuWeb />);
    }
    
    
    const style_elRect = {
      background: 'rgba(213, 186, 0, 1.000)',
     };
    const style_elCompbg = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elCompbg = ((val) => { return val === "true" || val == true || val == 1 })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_bgmap'] : '')) ? (
      <div className="hasNestedComps elCompbg" style={style_elCompbg} onClick={this.onClick_elCompbg} >
        <Backgroundtrans ref={(el)=> this._elCompbg = el} />
      </div>
     ) : null;
    const elMenucreate = ((val) => { return val === "true" || val == true || val == 1 })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_menuCreate'] : '')) ? (
      <div className="hasNestedComps elMenucreate">
        <Menucreate ref={(el)=> this._elMenucreate = el} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen FeedScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="hasNestedComps elColumns">
            <div>
              <div className="col0">
                <Component1home />
              </div>
              <div className="col1">
                <Spot1 />
              </div>
              <div className="col2">
                <Spot2 />
              </div>
            </div>
          </div>
          
          <div className="hasNestedComps elColumns2">
            <div>
              <div className="col0">
                <Spot3 />
              </div>
              <div className="col1">
                <Spot4 />
              </div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps elSwapper">
              {contentElement_elSwapper}
            </div>
            { elOpenlanguageSelector }
            <div className="hasNestedComps elSwapperSidebar">
              {contentElement_elSwapperSidebar}
            </div>
            <div className="elRect" style={style_elRect} />
            { elCompbg }
            { elMenucreate }
            <div className="hasNestedComps elCompnafooter">
              <Compnavfotterh visualStateIndex={(this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_changeState'] : '')} ref={(el)=> this._elCompnafooter = el} />
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
