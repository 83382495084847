import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import ProjectLisItem2 from './ProjectLisItem2';

export default class ProjectslisComp extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // neighborhood, projectName, summary, coverPhoto, projectURL, projectDescript, phase, heroPhoto, address, projectTitle, projectPhase

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  render() {
    // eslint-disable-next-line
    const dataSheet_projects = this.context.appActions.getDataSheet('projects');
    const style_elNewprojecttextCopy = {
      color: '#7f7eff',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.context.appActions.getDataSheet('projects').items);
    this._elList_items = [];
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    
    return (
      <div className="ProjectslisComp appBg">
        <div className="layoutFlow">
          <div className="elNewprojecttextCopy">
            <div className="headlineFont" style={style_elNewprojecttextCopy}>
              <div>{this.context.locStrings.project2_newprojecttext_474708263}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elList">
            <div style={style_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <ProjectLisItem2 neighborhood={row.neighborhood} projectName={row.projectName} summary={row.summary} coverPhoto={row.coverPhoto} projectURL={row.projectURL} projectDescript={row.projectDescript} phase={row.phase} heroPhoto={row.heroPhoto} address={row.address} projectTitle={row.projectTitle} projectPhase={row.projectPhase} dataSheetId={'projects'} dataSheetRow={row} {...{ 'phaseExpectedEnd': row['phaseExpectedEnd'], 'phaseDescription': row['phaseDescription'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
                return (<div key={row.key || index}>
                    {itemComp}
                  </div>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
