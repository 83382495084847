import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import Selector from './Selector';
import img_state0_elCourbaniza from './images/Menu_state0_elCoUrbaniza461992_461992.png';

export default class Menulogout extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  
  onClick_state0_elContato1713573071 = async () => {
    // Go to screen 'requestpartnership'
    this.context.appActions.goToScreen('requestpartnership', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state0_elCourbaniza = async () => {
  
  }
  
  
  renderState0() {
    const style_state0_elBackground15030526 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground15030526_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
     };
    const style_state0_elContato1713573071 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elRect2009086594 = {
      background: 'rgba(119, 119, 119, 1.000)',
     };
    const style_state0_elCourbaniza = {
      backgroundImage: 'url('+img_state0_elCourbaniza+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Menulogout">
        <div className="background">
          <div className="containerMinHeight state0_elBackground15030526" style={style_state0_elBackground15030526_outer}>
            <div style={style_state0_elBackground15030526} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="flowRow flowRow_Menulogout_state0_elContato1713573071_1713573071">
          <div className="state0_elContato1713573071">
            <div className="baseFont" style={style_state0_elContato1713573071} onClick={this.onClick_state0_elContato1713573071} >
              <div>{this.context.locStrings.menu2_contato_1713573071}</div>
            </div>
          </div>
          
          </div>
          <div className="hasNestedComps state0_elSelector306729510">
            <div>
              <Selector ref={(el)=> this._state0_elSelector306729510 = el} />
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <div className="state0_elRect2009086594" style={style_state0_elRect2009086594} />
          <div className="state0_elCourbaniza" style={style_state0_elCourbaniza} onClick={this.onClick_state0_elCourbaniza}  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  
  onClick_state1_elContato1713573071 = async () => {
    // Go to screen 'requestpartnership'
    this.context.appActions.goToScreen('requestpartnership', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  renderState1() {
    const style_state1_elBackground15030526 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBackground15030526_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
     };
    const style_state1_elContato1713573071 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elRect2009086594 = {
      background: 'rgba(119, 119, 119, 1.000)',
     };
    
    return (
      <div className="Menulogout">
        <div className="background">
          <div className="containerMinHeight state1_elBackground15030526" style={style_state1_elBackground15030526_outer}>
            <div style={style_state1_elBackground15030526} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="flowRow flowRow_Menulogout_state1_elContato1713573071_1713573071">
          <div className="state1_elContato1713573071">
            <div className="baseFont" style={style_state1_elContato1713573071} onClick={this.onClick_state1_elContato1713573071} >
              <div>{this.context.locStrings.menu2_contato_1713573071}</div>
            </div>
          </div>
          
          </div>
          <div className="hasNestedComps state1_elSelector306729510">
            <div>
              <Selector ref={(el)=> this._state1_elSelector306729510 = el} />
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <div className="state1_elRect2009086594" style={style_state1_elRect2009086594} />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
