import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elBack from './images/PrivacyPolicyScreen_elBack_898704.png';

export default class PrivacyPolicyScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  
  
  onClick_elHotspot = async () => {
    // Go back in screen navigation history
    this.context.appActions.goBack();
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextCopy19 = {
      color: '#919191',
      textAlign: 'left',
      textShadow: '#feffff 1.0px 1.0px 2.0px',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_elRectangle = {
      background: 'rgba(201, 237, 193, 1.000)',
      boxShadow: '0.0px 1.0px 1px rgba(0, 0, 0, 0.1600)',
     };
    const style_elScrTitle = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elBack = {
      height: 'auto',
     };
    
    const style_elHotspot = {
      display: 'block',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen PrivacyPolicyScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elText2">
            <div className="headlineFont" style={style_elText2}>
              <div>{this.context.locStrings.privacypolicy_text2_671091}</div>
            </div>
          </div>
          
          <div className="elTextCopy19">
            <div className="actionFont" style={style_elTextCopy19}>
              <div>{this.context.locStrings.privacypolicy_textcopy19_641372}</div>
            </div>
          </div>
          
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div><div dangerouslySetInnerHTML={{__html: this.context.locStrings.privacypolicy_text_884086.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>
          
          <div className="elSpacer2">
            <div />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elRectangle" style={style_elRectangle} />
            <div className="baseFont elScrTitle" style={style_elScrTitle}>
              <div>{this.context.locStrings.privacypolicy_scrtitle_911336}</div>
            </div>
            <img className="elBack" style={style_elBack} src={img_elBack} alt=""  />
            <div className="actionFont elHotspot" style={style_elHotspot} onClick={this.onClick_elHotspot}  />
          </div>
        </div>
      </div>
    )
  }
  
}
