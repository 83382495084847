import React, { Component } from 'react';
import { Loader } from "@googlemaps/js-api-loader"
import firebase from 'firebase/app';
import 'firebase/firestore';
import MarkerClusterer from '@googlemaps/markerclusterer';
import './App.css';
import ScreenContext from './ScreenContext';

import Compbg2 from './Compbg2';
import Compbg from './Compbg';
import img_elClose from './images/AccountSetupScreen_state0_elClose538058_538058.png';
import img_elCloseCopy from './images/AccountSetupScreen_state0_elClose538058_538058.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class CreateonmapScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {

const google = window.google = window.google ? window.google : {} 
const geofire = require('geofire-common');
const status = document.getElementById('status');
const mapLink = document.getElementById('map-link');
const mapAddress = document.getElementById('map-address');
mapLink.href = '';
mapLink.textContent = '';
var map;
var db = firebase.firestore()
var target = document.getElementById('map');

    if ('geolocation' in navigator) {

        status.textContent = 'updating…'
        navigator.geolocation.getCurrentPosition(function (position) {

        const latitude  = Number(localStorage.getItem('ds_SlotLat')).toFixed(6)
        const longitude = Number(localStorage.getItem('ds_SlotLon')).toFixed(6)

        var pos = new google.maps.LatLng(latitude, longitude)

        var address = localStorage.getItem('ds_mygeo');
        
        status.textContent = '';
        //mapLink.href = `https://www.openstreetmap.org/#map=18/${latitude}/${longitude}&zoom=12`;
        mapLink.textContent = `Latitude: ${latitude} °, Longitude: ${longitude} °`;
        mapAddress.textContent = `${address}`;
        // appendLocation(location, 'fetched');
        // watchId = navigator.geolocation.watchPosition(appendLocation);

        //Map
        var mapOptions = {
            apiKey: "AIzaSyBJvMQUeg5bpGqay4ohIGPA-Zt5GSJ-yaU",
            mapId: 'd16bae8cf5e6ee95',
            center: new google.maps.LatLng(latitude, longitude),
            zoom: 18,
            enableHighAccuracy: true,
            disableDefaultUI: true,
          	zoomControl: true,
            streetViewControl: true,
        };
        
        map = new google.maps.Map(document.getElementById('map'), mapOptions);

        // The marker ME
        const svgMarker = {
            path: "M19.9733225,19.0625407 C19.9733225,19.0643648 19.9733225,19.0661889 19.9733225,19.0684691 C18.1323453,20.7379805 15.6885016,21.7549186 13.0079805,21.7549186 C10.3270033,21.7549186 7.88315961,20.7379805 6.04218241,19.0684691 C6.04218241,19.0661889 6.04218241,19.0643648 6.04218241,19.0625407 C6.04218241,16.285798 7.66700326,13.8889251 10.0173616,12.7702932 C10.900684,13.6513355 11.9349511,14.1260586 13.0079805,14.1260586 C14.0814658,14.1260586 15.1152769,13.6513355 15.9990554,12.7702932 C18.3494137,13.8889251 19.9733225,16.285798 19.9733225,19.0625407 Z M13.0079805,4 C15.135798,4 16.8609446,5.72469055 16.8609446,7.85250814 C16.8609446,9.98078176 15.135798,12.8911401 13.0079805,12.8911401 C10.8801629,12.8911401 9.15501629,9.98078176 9.15501629,7.85250814 C9.15501629,5.72469055 10.8801629,4 13.0079805,4 Z M0.439080139,16.0137143 C0.44610453,16.0861882 0.461714286,16.158662 0.486689895,16.2294634 C0.567972125,16.4540209 1.28925436,18.4476098 1.78832056,19.2923206 C4.34040418,24.1439443 7.83944251,28.2958049 12.188878,31.6351777 C12.2648084,31.7229268 12.3582439,31.7965157 12.4649477,31.8524878 C12.576223,31.9103554 12.6990941,31.9402369 12.8233031,31.9448084 C12.8411429,31.9453659 12.8592056,31.9459233 12.8782718,31.9459233 C13.0138537,31.9459233 13.1525575,31.9148153 13.28,31.8548293 C13.3815749,31.8061045 13.471108,31.7370871 13.5446969,31.6537979 C23.2762648,24.2011429 25.1736307,16.3900209 25.2437631,16.0845157 C25.5308711,14.9906063 25.6769338,13.8942439 25.6769338,12.8255331 C25.6768223,5.7536446 19.9232892,0 12.8509547,0 C5.77884321,0 0.0251986063,5.75409059 0.0251986063,12.8260906 C0.0251986063,13.8738397 0.16445993,14.9458955 0.439080139,16.0137143 Z",
            fillColor: "#000000",
            fillOpacity: 1.0,
            strokeWeight: 0,
            rotation: 0,
            scale: 1.3,
            anchor: new google.maps.Point(12, 26)
        }

        var marker = new google.maps.Marker({
            //position: pos,
            icon: svgMarker, //turn of the custom marker above
            map: map,
            title: "Drag me",
            draggable: true,
            mapTypeId: "roadmap",
            animation: google.maps.Animation.DROP,
            gestureHandling: "cooperative",
        })
        marker.setPosition(pos);

        //map listenner
        google.maps.event.addListener(marker, 'dragend', function (event) {  
            let markerLat = this.getPosition().lat();
            let markerLon = this.getPosition().lng();
            let hash = geofire.geohashForLocation([markerLat, markerLon]);
    
            var pos = new google.maps.LatLng(markerLat, markerLon)
            console.log(markerLat,markerLon);
            console.log(hash);

            localStorage.setItem("ds_SlotLat", markerLat);
            localStorage.setItem("ds_SlotLon", markerLon);
            localStorage.setItem("ds_SlotHash", hash);
            

            const geocoder = new google.maps.Geocoder();
       	    geocoder.geocode({'latLng': pos}, function(results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                      var address = (results[0].formatted_address);
                      console.log('address',address)
                      localStorage.setItem("ds_mygeo", address)
                      if (results[0]) {
                        //console.log(results[0]);
                        // choose from console whatever you need.
                        var city = results[0].address_components[3].short_name;
                        var neig = results[0].address_components[2].long_name;
                        var state = results[0].address_components[4].short_name;
                        console.log( "city name is: ",  city)
                        console.log( "neig name is: ",  neig)
                        console.log( "state name is: ",  state)
                        localStorage.setItem("ds_SlotCity", city)
                        localStorage.setItem("ds_Neighborhood", neig)
                        localStorage.setItem("ds_SlotState", state)
                        localStorage.setItem("ds_mygeo", address)
                        }
                } 
            });
             status.textContent = '';
             //mapAddress.textContent = '';
             var address = localStorage.getItem('ds_mygeo');
             //mapLink.href = `https://www.openstreetmap.org/#map=18/${latitude}/${longitude}&zoom=12`;
             mapAddress.textContent = `${address}`;
             mapLink.textContent = `Latitude: ${markerLat.toFixed(6)} °, Longitude: ${markerLon.toFixed(6)} °`;
             

             })
        map.setCenter(pos);
        
        });

        } else {
        console.log('geolocation not avaiable')
        }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elButtonset = async () => {
    // Go back in screen navigation history
    this.context.appActions.goBack();
  
  }
  
  
  
  
  onClick_elClose = async () => {
    // Go to screen 'collaborative_map'
    this.context.appActions.goToScreen('collaborative_map', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elCloseCopy = async () => {
    // Go back in screen navigation history
    this.context.appActions.goBack();
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    
    let cssClass_progress = 'circularProgressIndicator';
    if (this.context.appActions.isLoading()) 
      cssClass_progress += ' circularProgressIndicator-active';
    
    
    // Embedded HTML content for element 'mapembed'
    const htmlContent_mapembed = "";
    
    const style_elMapembed = {
      pointerEvents: 'auto',
     };
    
    const style_elButtonset = {
      display: 'block',
      textTransform: 'none',
      fontSize: 14.0,
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elBgcomp2 = {
      width: '100%',
      height: '100%',
      borderRadius: '8.0px',
     };
    const style_elBgcomp2_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
      borderRadius: '8.0px',
     };
    const style_elBgcomp1 = {
      borderRadius: '8.0px',
     };
    const style_elLtlng = {
      fontSize: 12.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elGuide = {
      fontSize: 12.0,
      color: '#ff0000',
      textAlign: 'left',
     };
    const style_elClose = {
      backgroundImage: 'url('+img_elClose+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elCloseCopy = {
      backgroundImage: 'url('+img_elCloseCopy+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen CreateonmapScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elProgress">
            <div>
              <svg className={cssClass_progress}>
                <circle className="circularProgress-animatedPath" style={{stroke: '#7f7eff'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
              </svg>
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="embeddedContent containerMinHeight elMapembed" style={style_elMapembed}>
              <div dangerouslySetInnerHTML={{__html: htmlContent_mapembed}}></div>
            </div>
            <Button className="font-ralewayBold  elButtonset" style={style_elButtonset}  color="accent" onClick={this.onClick_elButtonset} >
              {this.context.locStrings.editonmap2_buttonset_990898}
            </Button>
            <div className="hasNestedComps elBgcomp2" style={style_elBgcomp2_outer}>
              <div style={style_elBgcomp2}>
                <Compbg2 ref={(el)=> this._elBgcomp2 = el} />
              </div>
            </div>
            
            <div className="hasNestedComps elBgcomp1" style={style_elBgcomp1}>
              <Compbg ref={(el)=> this._elBgcomp1 = el} />
            </div>
            <div className="font-ralewayBold  elLtlng" style={style_elLtlng}>
              <div>{this.context.locStrings.editonmap2_ltlng_783713}</div>
            </div>
            <div className="font-ralewayBold  elGuide" style={style_elGuide}>
              <div>{this.context.locStrings.editonmap2_guide_213407}</div>
            </div>
            <div className="elClose" style={style_elClose} onClick={this.onClick_elClose}  />
            <div className="elCloseCopy" style={style_elCloseCopy} onClick={this.onClick_elCloseCopy}  />
          </div>
        </div>
      </div>
    )
  }
  
}


