import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

// UI framework component imports
import Input from 'muicss/lib/react/input';
import Textarea from 'muicss/lib/react/textarea';

export default class Nametitle extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  getValue_elNameforaddress = () => {
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_url'] : ''));
  }
  
  
  textInputChanged_elProjectName = (event) => {
    this.setState({projectName: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_projectName", event.target.value);
    
    {
      let newVal = event.target.value;
      
      let transformValue = (input) => {
        //method returns the Unicode Normalization Form of the string.
        //for cleanning accents in the name will be used as url of the project
        let projectNameURL = input.normalize('NFKD').replace(/[\u0300-\u036f]/g, "").replace(/[^A-Z0-9]+/ig, "").toLowerCase()
        localStorage.setItem("ds_url", projectNameURL);
        console.log('clean url', projectNameURL)
        return projectNameURL;        }
      newVal = transformValue(newVal);
      
      this.context.appActions.updateDataSlot("ds_url", newVal);
    }
  }
  
  getValue_elProjectName = () => {
    return this.state.projectName !== undefined ? this.state.projectName : ((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_projectName'] : ''));
  }
  
  
  textInputChanged_elProjectTitle = (event) => {
    this.setState({projectTitle: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_SlotprojectTitle", event.target.value);
  }
  
  getValue_elProjectTitle = () => {
    return (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotprojectTitle'] : '');
  }
  
  
  textAreaChanged_elSummary = (event) => {
    this.setState({summary: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_projectBrief", event.target.value);
  }
  
  getValue_elSummary = () => {
    let transformPropValue_summary = (input) => {
      var maxLength = 180
      return input.substr(0, maxLength);
    }
    
    return transformPropValue_summary((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_projectBrief'] : ''));
  }
  
  
  getValue_elTextareaCount = () => {
  let transformPropValue_textareaCount = (input) => {
    input = this.context.appActions.dataSlots['ds_projectBrief'].length
    var charactersLeft = 140 - input;
    return charactersLeft;
  }
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_textareaCount((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_projectBrief'] : '')));
  }
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
      borderRadius: '10.0px',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
      borderRadius: '10.0px',
      filter: 'drop-shadow(2.0px 2.0px 2px #ebebeb)',
      overflow: 'visible',
     };
    const style_elNametext = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_nameforaddress = this.getValue_elNameforaddress();
    
    const style_elNameforaddress = {
      fontSize: 12.0,
      color: '#7f7eff',
      textAlign: 'left',
     };
    const style_elProjectaddress = {
      fontSize: 12.0,
      color: '#7f7eff',
      textAlign: 'left',
     };
    
    const style_elProjectName = {
      display: 'block',
      paddingTop: 0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTitletxt = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elProjectTitle = {
      display: 'block',
      paddingTop: 0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elSummarytext = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elSummary = {
      display: 'block',
      pointerEvents: 'auto',
     };
    
    const value_textareaCount = this.getValue_elTextareaCount();
    
    const style_elTextareaCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    
    return (
      <div className="Nametitle">
        <div className="background">
          <div className="elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="elNametext">
            <div className="font-ralewayBold" style={style_elNametext}>
              <div>{this.context.locStrings.comp7_nametext_951396191}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_Nametitle_elNameforaddress_584504276">
          <div className="elNameforaddress">
            <div className="font-ralewayBold" style={style_elNameforaddress}>
              <div>{value_nameforaddress}</div>
            </div>
          </div>
          
          </div>
          <div className="elProjectaddress">
            <div className="font-ralewayBold" style={style_elProjectaddress}>
              <div>{this.context.locStrings.nametitle_nametextcopy_811178467}</div>
            </div>
          </div>
          
          <div className="elProjectName">
            <Input className="baseFont" style={style_elProjectName} type="text" placeholder={this.context.locStrings.comp7_projectname_1975465847} onChange={this.textInputChanged_elProjectName} value={this.getValue_elProjectName()}  />
          </div>
          
          <div className="elTitletxt">
            <div className="font-ralewayBold" style={style_elTitletxt}>
              <div>{this.context.locStrings.comp7_titletxt_1719370359}</div>
            </div>
          </div>
          
          <div className="elProjectTitle">
            <Input className="baseFont" style={style_elProjectTitle} type="text" placeholder={this.context.locStrings.comp7_projecttitle_2011081590} onChange={this.textInputChanged_elProjectTitle} value={this.getValue_elProjectTitle()}  />
          </div>
          
          <div className="elSummarytext">
            <div className="font-ralewayBold" style={style_elSummarytext}>
              <div>{this.context.locStrings.comp7_summarytext_1256441096}</div>
            </div>
          </div>
          
          <div className="elSummary">
            <Textarea className="baseFont" style={style_elSummary}  placeholder={this.context.locStrings.comp7_summary_2095114596} onChange={this.textAreaChanged_elSummary} value={this.getValue_elSummary()}  />
          </div>
          
          <div className="elTextareaCount">
            <div className="baseFont" style={style_elTextareaCount}>
              <div>{value_textareaCount}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
