import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elCoverimage from './images/ProjectItem_elCoverimage_1371387613.jpg';

export default class ProjectItem extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // dataSheetRow

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  getValue_elTitle = () => {
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.projectTitle : '');
  }
  
  
  getValue_elDescription = () => {
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.projectDescript : '');
  }
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
      borderRadius: '10.0px',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      borderRadius: '10.0px',
      filter: 'drop-shadow(1.0px 1.0px 2px #eaeaea)',
      overflow: 'visible',
     };
    
    const value_title = this.getValue_elTitle();
    
    const style_elTitle = {
      color: '#7f7eff',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const value_description = this.getValue_elDescription();
    
    const style_elDescription = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elCoverimage = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp(this.props.dataSheetRow ? this.props.dataSheetRow.coverPhoto : '')+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      borderRadius: '10.0px',
      boxShadow: '1.0px 1.0px 2px #eaeaea',
     };
    
    return (
      <div className="ProjectItem">
        <div className="background">
          <div className="elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="elTitle">
            <div className="headlineFont" style={style_elTitle}>
              <div><div dangerouslySetInnerHTML={{__html: value_title}}></div></div>
            </div>
          </div>
          
          <div className="elDescription">
            <div className="baseFont" style={style_elDescription}>
              <div><div dangerouslySetInnerHTML={{__html: value_description}}></div></div>
            </div>
          </div>
          
          <div className="elCoverimage">
            <div style={style_elCoverimage} />
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
