import React, { Component } from 'react';
import { Loader } from "@googlemaps/js-api-loader"
import firebase from 'firebase/app';
import 'firebase/firestore';
import './App.css';
import ScreenContext from './ScreenContext';


export default class CompmyGeo extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }
  
  componentDidMount() {
    const google = window.google = window.google ? window.google : {} 
    const geofire = require('geofire-common');
    const status = document.getElementById('status');
    const mapLink = document.getElementById('map-link');
    const mapAddress = document.getElementById('map-address');

    var db = firebase.firestore()
    var target = document.getElementById('map');
    var map;

if ('geolocation' in navigator) {

    status.textContent = 'Locating…'
    var hashslot = localStorage.getItem('ds_SlotHash');
    var address = localStorage.getItem('ds_mygeo');
    var latslot = Number(localStorage.getItem('ds_SlotLat'));
    var lonslot = Number(localStorage.getItem('ds_SlotLon'));
    var cityslot = localStorage.getItem('ds_SlotCity');
    var groupId = localStorage.getItem('ds_SlotGroupID');

    //Map initial
    //var pos = new google.maps.LatLng(latslot, lonslot)
    const latitude  = Number(latslot.toFixed(6));
    const longitude = Number(lonslot.toFixed(6));
        
        status.textContent = '';
        //mapLink.href = `https://www.openstreetmap.org/#map=18/${latitude}/${longitude}&zoom=12`;
        mapLink.textContent = `Latitude: ${latitude} °, Longitude: ${longitude} °`;
        mapAddress.textContent = `${address}`;
        // appendLocation(location, 'fetched');
        // watchId = navigator.geolocation.watchPosition(appendLocation);
  } else {
   //geolocation not avaiable
  }    
}

  componentWillUnmount() {
    
  }

  componentDidUpdate() {
    const google = window.google = window.google ? window.google : {} 
    const geofire = require('geofire-common');
    const status = document.getElementById('status');
    const mapLink = document.getElementById('map-link');
    const mapAddress = document.getElementById('map-address');

    var db = firebase.firestore()
    var target = document.getElementById('map');
    var map;

if ('geolocation' in navigator) {

    status.textContent = 'Locating…'
    var hashslot = localStorage.getItem('ds_SlotHash');
    var address = localStorage.getItem('ds_mygeo');
    var latslot = Number(localStorage.getItem('ds_SlotLat'));
    var lonslot = Number(localStorage.getItem('ds_SlotLon'));
    var cityslot = localStorage.getItem('ds_SlotCity');
    var groupId = localStorage.getItem('ds_SlotGroupID');

    //Map initial
    //var pos = new google.maps.LatLng(latslot, lonslot)
    const latitude  = Number(latslot.toFixed(6));
    const longitude = Number(lonslot.toFixed(6));
        
        status.textContent = '';
        //mapLink.href = `https://www.openstreetmap.org/#map=18/${latitude}/${longitude}&zoom=12`;
        mapLink.textContent = `Latitude: ${latitude} °, Longitude: ${longitude} °`;
        mapAddress.textContent = `${address}`;
        // appendLocation(location, 'fetched');
        // watchId = navigator.geolocation.watchPosition(appendLocation);
  } else {
   //geolocation not avaiable
  }  
  }

  render() {
    // Embedded HTML content for element 'embed'
    const htmlContent_embed = "";
    
    const style_elEmbed = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="CompmyGeo">
        <div className="layoutFlow">
          <div className="embeddedContent elEmbed">
            <div style={style_elEmbed}>
              <div dangerouslySetInnerHTML={{__html: htmlContent_embed}}></div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}