import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import CoverphotoText from './CoverphotoText';
import Uploadcoverphoto from './Uploadcoverphoto';

export default class CoverCont extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
      filter: 'drop-shadow(0.0px 0.0px 0px #feffff)',
      overflow: 'visible',
     };
    let transformStateValue_elColumns_col1 = (input) => {
      var coverPhoto = localStorage.getItem("ds_coverPhoto")
            if(coverPhoto === '' || coverPhoto === null){
            //  console.log('coverPhoto', coverPhoto)
              return 1;
              } else {
              return 0;
              }
    }
    
    return (
      <div className="CoverCont">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="hasNestedComps elColumns">
            <div>
              <div className="col0">
                <CoverphotoText {...this.props} />
              </div>
              <div className="col1">
                <Uploadcoverphoto {...this.props} visualStateIndex={transformStateValue_elColumns_col1((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_coverstate'] : ''))} />
              </div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
