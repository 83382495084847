// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_urbanPhases extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['phase'] = "Conception";
    item['document_key'] = "01";
    item['phasept'] = "Concepção";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "02";
    item['phasept'] = "Aprovação";
    item['phase'] = "Approval";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "03";
    item['phasept'] = "Execução";
    item['phase'] = "Execution";
    item.key = key++;
  }

}
