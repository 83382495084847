// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_projectTypes extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['key'] = "00";
    item['eng'] = "Choose Category";
    item['ptbr'] = "Escolher Categoria";
    
    item = {};
    this.items.push(item);
    item['eng'] = "Mobility";
    item['key'] = "01";
    item['ptbr'] = "Mobilidade";
    
    item = {};
    this.items.push(item);
    item['key'] = "02";
    item['eng'] = "Sustainability";
    item['ptbr'] = "Sustentabilidade";
  }

}
