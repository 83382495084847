import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import Selectordrawer from './Selectordrawer';
import OpenlanguageSelectormobile from './OpenlanguageSelectormobile';

export default class Draweroff extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  onClick_elText = async () => {
    // Go to screen 'requestpartnership'
    this.context.appActions.goToScreen('requestpartnership', this.context.baseScreenId, { transitionId: 'fadeIn', closeDrawer: null });
  
  }
  
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'rgba(234, 234, 234, 0.9477)',
     };
    const elOpenlanguageSelectormobile = ((val) => { return val === "true" || val == true || val == 1 })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotLangSelectorMobile'] : '')) ? (
      <div className="hasNestedComps elOpenlanguageSelectormobile">
        <div>
          <OpenlanguageSelectormobile ref={(el)=> this._elOpenlanguageSelectormobile = el} />
        </div>
      </div>
      
     ) : null;
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Draweroff">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="hasNestedComps elSelectordrawer">
            <div>
              <Selectordrawer ref={(el)=> this._elSelectordrawer = el} />
            </div>
          </div>
          
          { elOpenlanguageSelectormobile }
          <div className="elText">
            <div className="baseFont" style={style_elText} onClick={this.onClick_elText} >
              <div>{this.context.locStrings.drawer2_text_1013032431}</div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
