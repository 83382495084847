import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

// UI framework component imports
import Checkbox from 'muicss/lib/react/checkbox';

export default class Activeinactive extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  getValue_elCheckboxactive = () => {
    return this.state.checkboxactive !== undefined ? this.state.checkboxactive : ((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_projectStatus'] : ''));
  }
  
  checkboxChanged_elCheckboxactive = (event) => {
    this.setState({checkboxactive: (event.target.checked ? 'true' : 'false')}, async () => {
      let newVal = this.getValue_elCheckboxactive();
      this.context.appActions.updateDataSlot('ds_projectStatus', newVal);
      
    });
  }
  
  render() {
    const style_elStatus = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let checked_checkboxactive = this.getValue_elCheckboxactive();
    
    const style_elCheckboxactive = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Activeinactive">
        <div className="layoutFlow">
          <div className="elStatus">
            <div className="font-ralewayBold" style={style_elStatus}>
              <div>{this.context.locStrings.activeinactive_status_1438401203}</div>
            </div>
          </div>
          
          <div className="elCheckboxactive">
            <Checkbox className="baseFont" style={style_elCheckboxactive}  label={this.context.locStrings.activeinactive_checkbox_344005980} checked={checked_checkboxactive === 'true' || checked_checkboxactive === true || ''+checked_checkboxactive === '1'}  onChange={this.checkboxChanged_elCheckboxactive} />
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
