import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

import CompMap from './CompMap';
import Colorslabels from './Colorslabels';
import OpenlanguageSelector from './OpenlanguageSelector';
import Complegend from './Complegend';
import Backgroundtrans from './Backgroundtrans';
import Menucreate from './Menucreate';
import Compnavfotterh from './Compnavfotterh';
import Menumobile from './Menumobile';
import Menu from './Menu';

export default class Collaborative_mapScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
      state0_elCompnafooter_stateIndex: 0,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elColorslabels = async () => {
    let newVal = "false";
    this.context.appActions.updateDataSlot('ds_mapLabels', newVal);
  
  }
  
  
  
  onClick_state0_elComplegend = async () => {
    let newVal = "true";
    this.context.appActions.updateDataSlot('ds_mapLabels', newVal);
  
  }
  
  
  onClick_state0_elCompbg = async () => {
    let newVal = "false";
        this.context.appActions.updateDataSlot('ds_bgmap', newVal);
    
    newVal = "false";
        this.context.appActions.updateDataSlot('ds_menuCreate', newVal);
    
    
  
    this.setState({state0_elCompnafooter_stateIndex: 0});
  
  }
  
  
  renderState0() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state0_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    const style_state0_elColorslabels = {
      borderRadius: '10.0px',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const state0_elColorslabels = ((val) => { return val === "true" || val == true || val == 1 })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_mapLabels'] : '')) ? (
      <div className="hasNestedComps state0_elColorslabels" style={style_state0_elColorslabels} onClick={this.onClick_state0_elColorslabels} >
        <Colorslabels ref={(el)=> this._state0_elColorslabels = el} />
      </div>
     ) : null;
    const style_state0_elDhashboardtitle = {
      fontSize: 18.0,
      color: '#919191',
      textAlign: 'left',
     };
    const state0_elOpenlanguageSelector = ((val) => { return val === "true" || val == true || val == 1 })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotLangSelector'] : '')) ? (
      <div className="hasNestedComps state0_elOpenlanguageSelector">
        <OpenlanguageSelector ref={(el)=> this._state0_elOpenlanguageSelector = el} />
      </div>
     ) : null;
    const style_state0_elComplegend = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elCompbg = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const state0_elCompbg = ((val) => { return val === "true" || val == true || val == 1 })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_bgmap'] : '')) ? (
      <div className="hasNestedComps state0_elCompbg" style={style_state0_elCompbg} onClick={this.onClick_state0_elCompbg} >
        <Backgroundtrans ref={(el)=> this._state0_elCompbg = el} />
      </div>
     ) : null;
    const state0_elMenucreate = ((val) => { return val === "true" || val == true || val == 1 })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_menuCreate'] : '')) ? (
      <div className="hasNestedComps state0_elMenucreate">
        <Menucreate ref={(el)=> this._state0_elMenucreate = el} />
      </div>
     ) : null;
    
    let contentElement_state0_elSwapper;  // This element's content can vary based on screen size
    contentElement_state0_elSwapper = (<Menumobile />);
    if (this.context.deviceInfo.screenFormatId === 'narrow-phone') {
      contentElement_state0_elSwapper = (<Menumobile />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'wide-phone') {
      contentElement_state0_elSwapper = (<Menumobile />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'narrow-tablet') {
      contentElement_state0_elSwapper = (<Menu />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'wide-tablet') {
      contentElement_state0_elSwapper = (<Menu />);
    }
    
    
    return (
      <div className="AppScreen Collaborative_mapScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state0_elBackground" style={style_state0_elBackground_outer}>
            <div className="appBg" style={style_state0_elBackground} />
          </div>
          
          <div className="hasNestedComps state0_elCompMap">
            <CompMap ref={(el)=> this._state0_elCompMap = el} />
          </div>
          { state0_elColorslabels }
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state0_elDhashboardtitle">
            <div className="font-ralewayBold" style={style_state0_elDhashboardtitle}>
              <div>{this.context.locStrings.socialmap_dhashboardtitle_699482}</div>
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            { state0_elOpenlanguageSelector }
            <div className="hasNestedComps state0_elComplegend" style={style_state0_elComplegend} onClick={this.onClick_state0_elComplegend} >
              <Complegend ref={(el)=> this._state0_elComplegend = el} />
            </div>
            { state0_elCompbg }
            { state0_elMenucreate }
            <div className="hasNestedComps state0_elCompnafooter">
              <Compnavfotterh visualStateIndex={this.state.state0_elCompnafooter_stateIndex} ref={(el)=> this._state0_elCompnafooter = el} />
            </div>
            <div className="hasNestedComps state0_elSwapper">
              {contentElement_state0_elSwapper}
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  renderState1() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    
    return (
      <div className="AppScreen Collaborative_mapScreen" style={baseStyle}>
      </div>
    )
  }
  
  
  render() {
    switch (0) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
