import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_state0_elMapnav861227 from './images/Geolocation_state0_elMapgeo792794_792794.png';
import img_state0_elTimelinenav759849 from './images/Compnavfotterh_state0_elTimelinenav759849_759849.png';
import btn_icon_995410 from './images/btn_icon_995410.png';
import img_state1_elMapnav861227 from './images/Geolocation_state0_elMapgeo792794_792794.png';
import img_state1_elTimelinenav759849 from './images/Compnavfotterh_state0_elTimelinenav759849_759849.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class Compnavfotterh extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elHotspotmap885233 = async () => {
    let ds_bgmap = "false";
        this.context.appActions.updateDataSlot('ds_bgmap', ds_bgmap);
    
    let ds_menuCreate = "false";
        this.context.appActions.updateDataSlot('ds_menuCreate', ds_menuCreate);
    
    let ds_preloadergif = "true";
        this.context.appActions.updateDataSlot('ds_preloadergif', ds_preloadergif);
    
    
    
  
    // Go to screen 'collaborative_map'
    this.context.appActions.goToScreen('collaborative_map', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state0_elHotspotfeed270744 = async () => {
    let newVal = "false";
        this.context.appActions.updateDataSlot('ds_bgmap', newVal);
    
    newVal = "false";
        this.context.appActions.updateDataSlot('ds_menuCreate', newVal);
    
    
    
    
  
    // Go to screen 'Feed'
    this.context.appActions.goToScreen('feed', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state0_elHotspotCreate = async () => {
    let url = "...";
    this.context.appActions.updateDataSlot('ds_url', url);
    
    let bgmap = "true";
        this.context.appActions.updateDataSlot('ds_bgmap', bgmap);
    
    let menucreate = "true";
        this.context.appActions.updateDataSlot('ds_menuCreate', menucreate);
    
    var length0 = 0
        localStorage.setItem("ds_ProjectDescripTotalChar", length0);
  
    this.context.appActions.updateDataSlot('ds_catchphrase', "");
    this.context.appActions.updateDataSlot('ds_projectType', "");
    this.context.appActions.updateDataSlot('ds_projectDescript', "");
    this.context.appActions.updateDataSlot('ds_heroPhoto', "");
    this.context.appActions.updateDataSlot('ds_ProjectDescripTotalChar', "");
    this.context.appActions.updateDataSlot('ds_projectSummary', "");
    this.context.appActions.updateDataSlot('ds_projectStatus', "");
    this.context.appActions.updateDataSlot('ds_projectName', "");
    this.context.appActions.updateDataSlot('ds_SlotprojectTitle', "");
    this.context.appActions.updateDataSlot('ds_coverstate', "");
    this.context.appActions.updateDataSlot('ds_fase', "");
    this.context.appActions.updateDataSlot('ds_postPhoto', "");
    this.context.appActions.updateDataSlot('ds_projectBrief', "");
    this.context.appActions.updateDataSlot('ds_coverPhoto', "");
    this.context.appActions.updateDataSlot('ds_photoHero', "");
    this.context.appActions.updateDataSlot('ds_faseDescription', "");
  
    this.setState({visualStateIndexOverride: 1})
  
  }
  
  
  renderState0() {
    
    const style_state0_elLine365203 = {
      background: 'rgba(39, 39, 39, 0.497)',
     };
    
    const style_state0_elBarfooter275208 = {
      background: 'rgba(255, 255, 255, 1.000)',
     };
    const style_state0_elMapnav861227 = {
      backgroundImage: 'url('+img_state0_elMapnav861227+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_state0_elTimelinenav759849 = {
      backgroundImage: 'url('+img_state0_elTimelinenav759849+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    const style_state0_elHotspotmap885233 = {
      display: 'block',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elHotspotfeed270744 = {
      display: 'block',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elFab995410 = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
     };
    
    const style_state0_elHotspotCreate = {
      display: 'block',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Compnavfotterh">
        <div className="foreground">
          <div className="state0_elLine365203" style={style_state0_elLine365203} />
          <div className="state0_elBarfooter275208" style={style_state0_elBarfooter275208} />
          <div className="state0_elMapnav861227" style={style_state0_elMapnav861227} />
          <div className="state0_elTimelinenav759849" style={style_state0_elTimelinenav759849} />
          <div className="actionFont state0_elHotspotmap885233" style={style_state0_elHotspotmap885233} onClick={this.onClick_state0_elHotspotmap885233}  />
          <div className="actionFont state0_elHotspotfeed270744" style={style_state0_elHotspotfeed270744} onClick={this.onClick_state0_elHotspotfeed270744}  />
          <Button className="actionFont state0_elFab995410" style={style_state0_elFab995410}  variant="fab" color="accent" >
            <img src={btn_icon_995410} alt="" style={{width: '50.000%', marginTop: '25.000%'}} />
          </Button>
          <div className="actionFont state0_elHotspotCreate" style={style_state0_elHotspotCreate} onClick={this.onClick_state0_elHotspotCreate}  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elHotspotmap885233 = async () => {
    let ds_bgmap = "false";
        this.context.appActions.updateDataSlot('ds_bgmap', ds_bgmap);
    
    let ds_menuCreate = "false";
        this.context.appActions.updateDataSlot('ds_menuCreate', ds_menuCreate);
    
    let ds_preloadergif = "true";
        this.context.appActions.updateDataSlot('ds_preloadergif', ds_preloadergif);
    
    
    
  
    // Go to screen 'collaborative_map'
    this.context.appActions.goToScreen('collaborative_map', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state1_elHotspotfeed270744 = async () => {
    let newVal = "false";
        this.context.appActions.updateDataSlot('ds_bgmap', newVal);
    
    newVal = "false";
        this.context.appActions.updateDataSlot('ds_menuCreate', newVal);
    
    
    
    
  
    // Go to screen 'Feed'
    this.context.appActions.goToScreen('feed', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state1_elHotspotCreate = async () => {
    let bgmap = "false";
        this.context.appActions.updateDataSlot('ds_bgmap', bgmap);
    
    let menucreate = "false";
        this.context.appActions.updateDataSlot('ds_menuCreate', menucreate);
    
    var length0 = 0
        localStorage.setItem("ds_ProjectDescripTotalChar", length0);
    
  
    this.setState({visualStateIndexOverride: 0})
  
    this.context.appActions.updateDataSlot('ds_catchphrase', "");
    this.context.appActions.updateDataSlot('ds_heroPhoto', "");
    this.context.appActions.updateDataSlot('ds_coverPhoto', "");
    this.context.appActions.updateDataSlot('ds_projectDescript', "");
    this.context.appActions.updateDataSlot('ds_photoHero', "");
    this.context.appActions.updateDataSlot('ds_postPhoto', "");
  
  }
  
  
  renderState1() {
    
    const style_state1_elLine365203 = {
      background: 'rgba(39, 39, 39, 0.497)',
     };
    
    const style_state1_elBarfooter275208 = {
      background: 'rgba(255, 255, 255, 1.000)',
     };
    const style_state1_elMapnav861227 = {
      backgroundImage: 'url('+img_state1_elMapnav861227+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_state1_elTimelinenav759849 = {
      backgroundImage: 'url('+img_state1_elTimelinenav759849+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    const style_state1_elHotspotmap885233 = {
      display: 'block',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elHotspotfeed270744 = {
      display: 'block',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elFab995410 = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
     };
    
    const style_state1_elHotspotCreate = {
      display: 'block',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Compnavfotterh">
        <div className="foreground">
          <div className="state1_elLine365203" style={style_state1_elLine365203} />
          <div className="state1_elBarfooter275208" style={style_state1_elBarfooter275208} />
          <div className="state1_elMapnav861227" style={style_state1_elMapnav861227} />
          <div className="state1_elTimelinenav759849" style={style_state1_elTimelinenav759849} />
          <div className="actionFont state1_elHotspotmap885233" style={style_state1_elHotspotmap885233} onClick={this.onClick_state1_elHotspotmap885233}  />
          <div className="actionFont state1_elHotspotfeed270744" style={style_state1_elHotspotfeed270744} onClick={this.onClick_state1_elHotspotfeed270744}  />
          <Button className="actionFont state1_elFab995410" style={style_state1_elFab995410}  variant="fab" color="accent" >
            <img src={btn_icon_995410} alt="" style={{width: '50.000%', marginTop: '25.000%'}} />
          </Button>
          <div className="actionFont state1_elHotspotCreate" style={style_state1_elHotspotCreate} onClick={this.onClick_state1_elHotspotCreate}  />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
