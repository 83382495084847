import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class Empty extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    
    return (
      <div className="Empty">
      </div>
    )
  }
  
}
