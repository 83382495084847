import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

import img_state0_elBgphoto389432 from './images/AccountSetupScreen_state0_elBgphoto389432_389432.jpg';
import img_state0_elProgressBar1858633 from './images/AccountSetupScreen_state0_elProgressBar1858633_858633.png';
import CompmyGeo from './CompmyGeo';
import img_state0_elClose538058 from './images/AccountSetupScreen_state0_elClose538058_538058.png';
import img_state1_elBgphoto389432 from './images/AccountSetupScreen_state0_elBgphoto389432_389432.jpg';
import img_state1_elProgressBar1858633 from './images/AccountSetupScreen_state0_elProgressBar1858633_858633.png';
import img_state1_elClose538058 from './images/AccountSetupScreen_state0_elClose538058_538058.png';
import img_state2_elBgphoto389432 from './images/AccountSetupScreen_state0_elBgphoto389432_389432.jpg';
import img_state2_elProgressBar1858633 from './images/AccountSetupScreen_state0_elProgressBar1858633_858633.png';
import img_state2_elClose538058 from './images/AccountSetupScreen_state0_elClose538058_538058.png';

// UI framework component imports
import Input from 'muicss/lib/react/input';
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Button from 'muicss/lib/react/button';

export default class AccountSetupScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
      state0_elValidator264817_visible: false,
      state1_elValidator264817_visible: false,
      state2_elValidator264817_visible: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  // --- Functions for component state index 0 (1 of 3) --- 
  
  
  
  
  textInputChanged_state0_elNamefield988237 = (event) => {
    this.setState({namefield: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_SlotFullName", event.target.value);
  }
  
  getValue_state0_elNamefield988237 = () => {
    return this.state.namefield || '';
  }
  
  
  
  getValue_state0_elAgepicker820017 = () => {
    return this.state.agepicker !== undefined ? this.state.agepicker : ((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : ''));
  }
  
  pickerValueChanged_state0_elAgepicker820017 = (event) => {
    this.setState({agepicker: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_SlotAge", event.target.value);
  }
  
  
  
  getValue_state0_elGenderPickerEng = () => {
    return this.state.genderPickerEng !== undefined ? this.state.genderPickerEng : (this.props.gender);
  }
  
  pickerValueChanged_state0_elGenderPickerEng = (event) => {
    this.setState({genderPickerEng: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_SlotGender", event.target.value);
  }
  
  
  onClick_state0_elButton761106 = async () => {
    // Perform action 'Validate' on element 'validator'
    const items = [
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '').toString().length > 0, name: 'ds_SlotAge', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '') },
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '').toString().length > 0, name: 'ds_SlotGender', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '') },
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotFullName'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotFullName'] : '').toString().length > 0, name: 'ds_SlotFullName', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotFullName'] : '') },
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '').toString().length > 0, name: 'ds_SlotAge', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '') },
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '').toString().length > 0, name: 'ds_SlotGender', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '') },
    ];
    
    let valid = true;
    for (let i = 0; i < items.length; ++i) {
      let item = items[i];
      valid &= item.result;
      if ( !valid) {
        if ( !process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          console.log("Validator 'validator' failed: item '" + item.name + "' is not valid, value: '" + item.value + "'");
        }
        break;
      }
    };
    this.setState({state0_elValidator264817_visible: !valid});
    
    if ( !valid) {
      return;
    }
  
    this.context.appActions.updateDataSlot('ds_screen_state', "");
  
    // Go to screen 'Wait'
    this.context.appActions.goToScreen('wait', this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state0_elClose538058 = async () => {
  
    this.context.appActions.updateDataSlot('ds_userIsSubscribed', "");
  
  }
  
  
  renderState0() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state0_elBgphoto389432 = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_homebackground'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_state0_elBackground735289 = {
      width: '100%',
      height: '100%',
      borderRadius: '20.0px',
     };
    const style_state0_elBackground735289_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
      borderRadius: '20.0px',
     };
    const style_state0_elText915386 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_state0_elProgressBar1858633 = {
      backgroundImage: 'url('+img_state0_elProgressBar1858633+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_state0_elText3757268 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_state0_elText275683 = {
      color: '#00a9d5',
      textAlign: 'center',
     };
    
    const style_state0_elNamefield988237 = {
      display: 'block',
      paddingTop: 0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state0_elAgegroup540737 = {
      fontSize: 14.0,
      color: '#919191',
      textAlign: 'center',
     };
    
    let selection_agepicker = this.getValue_state0_elAgepicker820017();
    // Source datasheet and selected data column for picker element 'agepicker'
    const dataSource_agepicker = this.context.appActions.getDataSheet('agegroup');
    const valueColumnName_agepicker = 'agegroup';
    const labelColumnName_agepicker = 'agegroup';
    
    const style_state0_elAgepicker820017 = {
      pointerEvents: 'auto',
     };
    const style_state0_elIssueCopy948448 = {
      fontSize: 16.0,
      color: '#919191',
      textAlign: 'center',
     };
    
    let selection_genderPickerEng = this.getValue_state0_elGenderPickerEng();
    // Source datasheet and selected data column for picker element 'genderPickerEng'
    const dataSource_genderPickerEng = this.context.appActions.getDataSheet('gender');
    const valueColumnName_genderPickerEng = 'gender';
    const labelColumnName_genderPickerEng = 'gender';
    
    const style_state0_elGenderPickerEng = {
      pointerEvents: 'auto',
     };
    const style_state0_elCompmyGeo527660_outer = {
      display: 'none',
     };
    const style_state0_elValidator264817 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const state0_elValidator264817 = this.state.state0_elValidator264817_visible ? (
      <div className="state0_elValidator264817">
        <div className="baseFont" style={style_state0_elValidator264817}>
          <div>{this.context.locStrings.accountsetup_validator_264817}</div>
        </div>
      </div>
      
     ) : null;
    
    const style_state0_elButton761106 = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elClose538058 = {
      backgroundImage: 'url('+img_state0_elClose538058+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen AccountSetupScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state0_elBgphoto389432" style={style_state0_elBgphoto389432} />
          <div className="state0_elBackground735289" style={style_state0_elBackground735289_outer}>
            <div className="appBg" style={style_state0_elBackground735289} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state0_elText915386">
            <div className="headlineFont" style={style_state0_elText915386}>
              <div>{this.context.locStrings.accountsetup_text_915386}</div>
            </div>
          </div>
          
          <div className="state0_elProgressBar1858633">
            <div style={style_state0_elProgressBar1858633} />
          </div>
          
          <div className="state0_elText3757268">
            <div className="baseFont" style={style_state0_elText3757268}>
              <div>{this.context.locStrings.accountsetup_text3_757268}</div>
            </div>
          </div>
          
          <div className="state0_elText275683">
            <div className="headlineFont" style={style_state0_elText275683}>
              <div>{this.context.locStrings.accountsetup_text2_75683}</div>
            </div>
          </div>
          
          <div className="state0_elNamefield988237">
            <Input className="baseFont" style={style_state0_elNamefield988237} type="text" placeholder={this.context.locStrings.accountsetup_field_988237} onChange={this.textInputChanged_state0_elNamefield988237} value={this.getValue_state0_elNamefield988237()}  />
          </div>
          
          <div className="state0_elAgegroup540737">
            <div className="font-AppleSystemUIFont" style={style_state0_elAgegroup540737}>
              <div>{this.context.locStrings.accountsetup_agegroup_540737}</div>
            </div>
          </div>
          
          <div className="state0_elAgepicker820017">
            <Select className="baseFont" style={style_state0_elAgepicker820017}  onChange={this.pickerValueChanged_state0_elAgepicker820017} value={selection_agepicker} >
              {dataSource_agepicker.items.every(item => {
                return item[valueColumnName_agepicker] !== selection_agepicker;
              }) ? <Option value=''/> : null}
              {dataSource_agepicker.items.map(item => {
                const colValue = item[valueColumnName_agepicker];
                const labelColValue = item[labelColumnName_agepicker];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elIssueCopy948448">
            <div className="font-AppleSystemUIFont" style={style_state0_elIssueCopy948448}>
              <div>{this.context.locStrings.accountsetup_issuecopy_948448}</div>
            </div>
          </div>
          
          <div className="state0_elGenderPickerEng">
            <Select className="baseFont" style={style_state0_elGenderPickerEng}  onChange={this.pickerValueChanged_state0_elGenderPickerEng} value={selection_genderPickerEng} >
              {dataSource_genderPickerEng.items.every(item => {
                return item[valueColumnName_genderPickerEng] !== selection_genderPickerEng;
              }) ? <Option value=''/> : null}
              {dataSource_genderPickerEng.items.map(item => {
                const colValue = item[valueColumnName_genderPickerEng];
                const labelColValue = item[labelColumnName_genderPickerEng];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="flowRow flowRow_AccountSetupScreen_state0_elCompmyGeo527660_527660">
          <div className="hasNestedComps state0_elCompmyGeo527660" style={style_state0_elCompmyGeo527660_outer}>
            <div>
              <CompmyGeo ref={(el)=> this._state0_elCompmyGeo527660 = el} />
            </div>
          </div>
          
          </div>
          { state0_elValidator264817 }
          <div className="state0_elButton761106">
            <Button className="actionFont" style={style_state0_elButton761106}  color="accent" onClick={this.onClick_state0_elButton761106} >
              {this.context.locStrings.accountsetup_button_761106}
            </Button>
          </div>
          
          <div className="state0_elSpacer774283">
            <div />
          </div>
          
          <div className="state0_elSpacerCopy455585">
            <div />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="state0_elClose538058" style={style_state0_elClose538058} onClick={this.onClick_state0_elClose538058}  />
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 3) --- 
  
  
  
  
  textInputChanged_state1_elNamefield988237 = (event) => {
    this.setState({namefield: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_SlotFullName", event.target.value);
  }
  
  getValue_state1_elNamefield988237 = () => {
    return this.state.namefield || '';
  }
  
  
  
  getValue_state1_elAgepicker820017 = () => {
    return this.state.agepicker !== undefined ? this.state.agepicker : ((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : ''));
  }
  
  pickerValueChanged_state1_elAgepicker820017 = (event) => {
    this.setState({agepicker: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_SlotAge", event.target.value);
  }
  
  
  
  getValue_state1_elGenderPickerbr = () => {
    return this.state.genderPickerbr !== undefined ? this.state.genderPickerbr : (this.props.genderptbr);
  }
  
  pickerValueChanged_state1_elGenderPickerbr = (event) => {
    this.setState({genderPickerbr: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_SlotGender", event.target.value);
  }
  
  
  onClick_state1_elButton761106 = async () => {
    // Perform action 'Validate' on element 'validator'
    const items = [
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '').toString().length > 0, name: 'ds_SlotAge', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '') },
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '').toString().length > 0, name: 'ds_SlotGender', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '') },
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotFullName'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotFullName'] : '').toString().length > 0, name: 'ds_SlotFullName', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotFullName'] : '') },
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '').toString().length > 0, name: 'ds_SlotAge', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '') },
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '').toString().length > 0, name: 'ds_SlotGender', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '') },
    ];
    
    let valid = true;
    for (let i = 0; i < items.length; ++i) {
      let item = items[i];
      valid &= item.result;
      if ( !valid) {
        if ( !process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          console.log("Validator 'validator' failed: item '" + item.name + "' is not valid, value: '" + item.value + "'");
        }
        break;
      }
    };
    this.setState({state1_elValidator264817_visible: !valid});
    
    if ( !valid) {
      return;
    }
  
    this.context.appActions.updateDataSlot('ds_screen_state', "");
  
    // Go to screen 'Wait'
    this.context.appActions.goToScreen('wait', this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state1_elClose538058 = async () => {
  
    this.context.appActions.updateDataSlot('ds_userIsSubscribed', "");
  
  }
  
  
  renderState1() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state1_elBgphoto389432 = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_homebackground'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_state1_elBackground735289 = {
      width: '100%',
      height: '100%',
      borderRadius: '20.0px',
     };
    const style_state1_elBackground735289_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
      borderRadius: '20.0px',
     };
    const style_state1_elText915386 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_state1_elProgressBar1858633 = {
      backgroundImage: 'url('+img_state1_elProgressBar1858633+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_state1_elText3757268 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_state1_elText275683 = {
      color: '#00a9d5',
      textAlign: 'center',
     };
    
    const style_state1_elNamefield988237 = {
      display: 'block',
      paddingTop: 0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state1_elAgegroup540737 = {
      fontSize: 14.0,
      color: '#919191',
      textAlign: 'center',
     };
    
    let selection_agepicker = this.getValue_state1_elAgepicker820017();
    // Source datasheet and selected data column for picker element 'agepicker'
    const dataSource_agepicker = this.context.appActions.getDataSheet('agegroup');
    const valueColumnName_agepicker = 'agegroup';
    const labelColumnName_agepicker = 'agegroup';
    
    const style_state1_elAgepicker820017 = {
      pointerEvents: 'auto',
     };
    const style_state1_elIssueCopy948448 = {
      fontSize: 16.0,
      color: '#919191',
      textAlign: 'center',
     };
    
    let selection_genderPickerbr = this.getValue_state1_elGenderPickerbr();
    // Source datasheet and selected data column for picker element 'genderPickerbr'
    const dataSource_genderPickerbr = this.context.appActions.getDataSheet('gender');
    const valueColumnName_genderPickerbr = 'genderptbr';
    const labelColumnName_genderPickerbr = 'genderptbr';
    
    const style_state1_elGenderPickerbr = {
      pointerEvents: 'auto',
     };
    const style_state1_elCompmyGeo527660_outer = {
      display: 'none',
     };
    const style_state1_elValidator264817 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const state1_elValidator264817 = this.state.state1_elValidator264817_visible ? (
      <div className="state1_elValidator264817">
        <div className="baseFont" style={style_state1_elValidator264817}>
          <div>{this.context.locStrings.accountsetup_validator_264817}</div>
        </div>
      </div>
      
     ) : null;
    
    const style_state1_elButton761106 = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elClose538058 = {
      backgroundImage: 'url('+img_state1_elClose538058+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen AccountSetupScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state1_elBgphoto389432" style={style_state1_elBgphoto389432} />
          <div className="state1_elBackground735289" style={style_state1_elBackground735289_outer}>
            <div className="appBg" style={style_state1_elBackground735289} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state1_elText915386">
            <div className="headlineFont" style={style_state1_elText915386}>
              <div>{this.context.locStrings.accountsetup_text_915386}</div>
            </div>
          </div>
          
          <div className="state1_elProgressBar1858633">
            <div style={style_state1_elProgressBar1858633} />
          </div>
          
          <div className="state1_elText3757268">
            <div className="baseFont" style={style_state1_elText3757268}>
              <div>{this.context.locStrings.accountsetup_text3_757268}</div>
            </div>
          </div>
          
          <div className="state1_elText275683">
            <div className="headlineFont" style={style_state1_elText275683}>
              <div>{this.context.locStrings.accountsetup_text2_75683}</div>
            </div>
          </div>
          
          <div className="state1_elNamefield988237">
            <Input className="baseFont" style={style_state1_elNamefield988237} type="text" placeholder={this.context.locStrings.accountsetup_field_988237} onChange={this.textInputChanged_state1_elNamefield988237} value={this.getValue_state1_elNamefield988237()}  />
          </div>
          
          <div className="state1_elAgegroup540737">
            <div className="font-AppleSystemUIFont" style={style_state1_elAgegroup540737}>
              <div>{this.context.locStrings.accountsetup_agegroup_540737}</div>
            </div>
          </div>
          
          <div className="state1_elAgepicker820017">
            <Select className="baseFont" style={style_state1_elAgepicker820017}  onChange={this.pickerValueChanged_state1_elAgepicker820017} value={selection_agepicker} >
              {dataSource_agepicker.items.every(item => {
                return item[valueColumnName_agepicker] !== selection_agepicker;
              }) ? <Option value=''/> : null}
              {dataSource_agepicker.items.map(item => {
                const colValue = item[valueColumnName_agepicker];
                const labelColValue = item[labelColumnName_agepicker];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elIssueCopy948448">
            <div className="font-AppleSystemUIFont" style={style_state1_elIssueCopy948448}>
              <div>{this.context.locStrings.accountsetup_issuecopy_948448}</div>
            </div>
          </div>
          
          <div className="state1_elGenderPickerbr">
            <Select className="baseFont" style={style_state1_elGenderPickerbr}  onChange={this.pickerValueChanged_state1_elGenderPickerbr} value={selection_genderPickerbr} >
              {dataSource_genderPickerbr.items.every(item => {
                return item[valueColumnName_genderPickerbr] !== selection_genderPickerbr;
              }) ? <Option value=''/> : null}
              {dataSource_genderPickerbr.items.map(item => {
                const colValue = item[valueColumnName_genderPickerbr];
                const labelColValue = item[labelColumnName_genderPickerbr];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="flowRow flowRow_AccountSetupScreen_state1_elCompmyGeo527660_527660">
          <div className="hasNestedComps state1_elCompmyGeo527660" style={style_state1_elCompmyGeo527660_outer}>
            <div>
              <CompmyGeo ref={(el)=> this._state1_elCompmyGeo527660 = el} />
            </div>
          </div>
          
          </div>
          { state1_elValidator264817 }
          <div className="state1_elButton761106">
            <Button className="actionFont" style={style_state1_elButton761106}  color="accent" onClick={this.onClick_state1_elButton761106} >
              {this.context.locStrings.accountsetup_button_761106}
            </Button>
          </div>
          
          <div className="state1_elSpacer774283">
            <div />
          </div>
          
          <div className="state1_elSpacerCopy455585">
            <div />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="state1_elClose538058" style={style_state1_elClose538058} onClick={this.onClick_state1_elClose538058}  />
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 3) --- 
  
  
  
  
  textInputChanged_state2_elNamefield988237 = (event) => {
    this.setState({namefield: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_SlotFullName", event.target.value);
  }
  
  getValue_state2_elNamefield988237 = () => {
    return this.state.namefield || '';
  }
  
  
  
  getValue_state2_elAgepicker820017 = () => {
    return this.state.agepicker !== undefined ? this.state.agepicker : ((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : ''));
  }
  
  pickerValueChanged_state2_elAgepicker820017 = (event) => {
    this.setState({agepicker: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_SlotAge", event.target.value);
  }
  
  
  
  getValue_state2_elGenderPickeresp = () => {
    return this.state.genderPickeresp !== undefined ? this.state.genderPickeresp : (this.props.genderesp);
  }
  
  pickerValueChanged_state2_elGenderPickeresp = (event) => {
    this.setState({genderPickeresp: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_SlotGender", event.target.value);
  }
  
  
  onClick_state2_elButton761106 = async () => {
    // Perform action 'Validate' on element 'validator'
    const items = [
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '').toString().length > 0, name: 'ds_SlotAge', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '') },
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '').toString().length > 0, name: 'ds_SlotGender', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '') },
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotFullName'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotFullName'] : '').toString().length > 0, name: 'ds_SlotFullName', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotFullName'] : '') },
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '').toString().length > 0, name: 'ds_SlotAge', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '') },
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '').toString().length > 0, name: 'ds_SlotGender', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '') },
    ];
    
    let valid = true;
    for (let i = 0; i < items.length; ++i) {
      let item = items[i];
      valid &= item.result;
      if ( !valid) {
        if ( !process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          console.log("Validator 'validator' failed: item '" + item.name + "' is not valid, value: '" + item.value + "'");
        }
        break;
      }
    };
    this.setState({state2_elValidator264817_visible: !valid});
    
    if ( !valid) {
      return;
    }
  
    this.context.appActions.updateDataSlot('ds_screen_state', "");
  
    // Go to screen 'Wait'
    this.context.appActions.goToScreen('wait', this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state2_elClose538058 = async () => {
  
    this.context.appActions.updateDataSlot('ds_userIsSubscribed', "");
  
  }
  
  
  renderState2() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state2_elBgphoto389432 = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_homebackground'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_state2_elBackground735289 = {
      width: '100%',
      height: '100%',
      borderRadius: '20.0px',
     };
    const style_state2_elBackground735289_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
      borderRadius: '20.0px',
     };
    const style_state2_elText915386 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_state2_elProgressBar1858633 = {
      backgroundImage: 'url('+img_state2_elProgressBar1858633+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_state2_elText3757268 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_state2_elText275683 = {
      color: '#00a9d5',
      textAlign: 'center',
     };
    
    const style_state2_elNamefield988237 = {
      display: 'block',
      paddingTop: 0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state2_elAgegroup540737 = {
      fontSize: 14.0,
      color: '#919191',
      textAlign: 'center',
     };
    
    let selection_agepicker = this.getValue_state2_elAgepicker820017();
    // Source datasheet and selected data column for picker element 'agepicker'
    const dataSource_agepicker = this.context.appActions.getDataSheet('agegroup');
    const valueColumnName_agepicker = 'agegroup';
    const labelColumnName_agepicker = 'agegroup';
    
    const style_state2_elAgepicker820017 = {
      pointerEvents: 'auto',
     };
    const style_state2_elIssueCopy948448 = {
      fontSize: 16.0,
      color: '#919191',
      textAlign: 'center',
     };
    
    let selection_genderPickeresp = this.getValue_state2_elGenderPickeresp();
    // Source datasheet and selected data column for picker element 'genderPickeresp'
    const dataSource_genderPickeresp = this.context.appActions.getDataSheet('gender');
    const valueColumnName_genderPickeresp = 'genderesp';
    const labelColumnName_genderPickeresp = 'genderesp';
    
    const style_state2_elGenderPickeresp = {
      pointerEvents: 'auto',
     };
    const style_state2_elCompmyGeo527660_outer = {
      display: 'none',
     };
    const style_state2_elValidator264817 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const state2_elValidator264817 = this.state.state2_elValidator264817_visible ? (
      <div className="state2_elValidator264817">
        <div className="baseFont" style={style_state2_elValidator264817}>
          <div>{this.context.locStrings.accountsetup_validator_264817}</div>
        </div>
      </div>
      
     ) : null;
    
    const style_state2_elButton761106 = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state2_elClose538058 = {
      backgroundImage: 'url('+img_state2_elClose538058+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen AccountSetupScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state2_elBgphoto389432" style={style_state2_elBgphoto389432} />
          <div className="state2_elBackground735289" style={style_state2_elBackground735289_outer}>
            <div className="appBg" style={style_state2_elBackground735289} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state2_elText915386">
            <div className="headlineFont" style={style_state2_elText915386}>
              <div>{this.context.locStrings.accountsetup_text_915386}</div>
            </div>
          </div>
          
          <div className="state2_elProgressBar1858633">
            <div style={style_state2_elProgressBar1858633} />
          </div>
          
          <div className="state2_elText3757268">
            <div className="baseFont" style={style_state2_elText3757268}>
              <div>{this.context.locStrings.accountsetup_text3_757268}</div>
            </div>
          </div>
          
          <div className="state2_elText275683">
            <div className="headlineFont" style={style_state2_elText275683}>
              <div>{this.context.locStrings.accountsetup_text2_75683}</div>
            </div>
          </div>
          
          <div className="state2_elNamefield988237">
            <Input className="baseFont" style={style_state2_elNamefield988237} type="text" placeholder={this.context.locStrings.accountsetup_field_988237} onChange={this.textInputChanged_state2_elNamefield988237} value={this.getValue_state2_elNamefield988237()}  />
          </div>
          
          <div className="state2_elAgegroup540737">
            <div className="font-AppleSystemUIFont" style={style_state2_elAgegroup540737}>
              <div>{this.context.locStrings.accountsetup_agegroup_540737}</div>
            </div>
          </div>
          
          <div className="state2_elAgepicker820017">
            <Select className="baseFont" style={style_state2_elAgepicker820017}  onChange={this.pickerValueChanged_state2_elAgepicker820017} value={selection_agepicker} >
              {dataSource_agepicker.items.every(item => {
                return item[valueColumnName_agepicker] !== selection_agepicker;
              }) ? <Option value=''/> : null}
              {dataSource_agepicker.items.map(item => {
                const colValue = item[valueColumnName_agepicker];
                const labelColValue = item[labelColumnName_agepicker];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state2_elIssueCopy948448">
            <div className="font-AppleSystemUIFont" style={style_state2_elIssueCopy948448}>
              <div>{this.context.locStrings.accountsetup_issuecopy_948448}</div>
            </div>
          </div>
          
          <div className="state2_elGenderPickeresp">
            <Select className="baseFont" style={style_state2_elGenderPickeresp}  onChange={this.pickerValueChanged_state2_elGenderPickeresp} value={selection_genderPickeresp} >
              {dataSource_genderPickeresp.items.every(item => {
                return item[valueColumnName_genderPickeresp] !== selection_genderPickeresp;
              }) ? <Option value=''/> : null}
              {dataSource_genderPickeresp.items.map(item => {
                const colValue = item[valueColumnName_genderPickeresp];
                const labelColValue = item[labelColumnName_genderPickeresp];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="flowRow flowRow_AccountSetupScreen_state2_elCompmyGeo527660_527660">
          <div className="hasNestedComps state2_elCompmyGeo527660" style={style_state2_elCompmyGeo527660_outer}>
            <div>
              <CompmyGeo ref={(el)=> this._state2_elCompmyGeo527660 = el} />
            </div>
          </div>
          
          </div>
          { state2_elValidator264817 }
          <div className="state2_elButton761106">
            <Button className="actionFont" style={style_state2_elButton761106}  color="accent" onClick={this.onClick_state2_elButton761106} >
              {this.context.locStrings.accountsetup_button_761106}
            </Button>
          </div>
          
          <div className="state2_elSpacer774283">
            <div />
          </div>
          
          <div className="state2_elSpacerCopy455585">
            <div />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="state2_elClose538058" style={style_state2_elClose538058} onClick={this.onClick_state2_elClose538058}  />
          </div>
        </div>
      </div>
    )
  }
  
  
  render() {
    let transformStateValue = (input) => {
      var ds_activeLang = this.context.appActions.dataSlots['ds_activeLang'];
      console.log('activeLang', ds_activeLang)
      
      if(ds_activeLang === 'en') {
        return 0;
      } else {
        return 1;
      }
    }
    switch (parseInt((transformStateValue(this.context.ds_activeLang) !== undefined) ? transformStateValue(this.context.ds_activeLang) : 0, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
    }
  }
  
}
