import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class Editor extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  // --- Functions for component state index 0 (1 of 3) --- 
  
  
  
  getValue_state0_elTextareaCount686172016 = () => {
  let transformPropValue_textareaCount = (input) => {
    input = localStorage.getItem('ds_ProjectDescripTotalChar')
    return input
  }
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_textareaCount((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_ProjectDescripTotalChar'] : '')));
  }
  
  
  renderState0() {
    const style_state0_elProjectDescrition880152582 = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    // Embedded HTML content for element 'embed'
    const htmlContent_embed = "<div id=editor style='width:100%; height:300px; border-radius: 0px 0px 20px 20px; background-color:#ffffff'>";
    
    const style_state0_elEmbed341566042 = {
      pointerEvents: 'auto',
     };
    
    const value_textareaCount = this.getValue_state0_elTextareaCount686172016();
    
    const style_state0_elTextareaCount686172016 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    const style_state0_elRemainingchars1300397438 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    
    return (
      <div className="Editor">
        <div className="layoutFlow">
          <div className="state0_elProjectDescrition880152582">
            <div className="font-ralewayBold" style={style_state0_elProjectDescrition880152582}>
              <div>{this.context.locStrings.comp8_projectdescrition_880152582}</div>
            </div>
          </div>
          
          <div className="embeddedContent state0_elEmbed341566042">
            <div style={style_state0_elEmbed341566042}>
              <div dangerouslySetInnerHTML={{__html: htmlContent_embed}}></div>
            </div>
          </div>
          
          <div className="state0_elTextareaCount686172016">
            <div className="baseFont" style={style_state0_elTextareaCount686172016}>
              <div>{value_textareaCount}</div>
            </div>
          </div>
          
          <div className="state0_elRemainingchars1300397438">
            <div className="baseFont" style={style_state0_elRemainingchars1300397438}>
              <div>{this.context.locStrings.comp8_remainingchars_1300397438}</div>
            </div>
          </div>
          
          <div className="state0_elSpacer1261194532">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 3) --- 
  
  
  
  getValue_state1_elTextareaCount686172016 = () => {
  let transformPropValue_textareaCount = (input) => {
    input = localStorage.getItem('ds_ProjectDescripTotalChar')
    return input
  }
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_textareaCount((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_ProjectDescripTotalChar'] : '')));
  }
  
  
  renderState1() {
    const style_state1_elProjectDescrition880152582 = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    // Embedded HTML content for element 'embed'
    const htmlContent_embed = "<div id=editor style='width:100%; height:300px; border-radius: 0px 0px 20px 20px; background-color:#ffffff'>";
    
    const style_state1_elEmbed341566042 = {
      pointerEvents: 'auto',
     };
    
    const value_textareaCount = this.getValue_state1_elTextareaCount686172016();
    
    const style_state1_elTextareaCount686172016 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    const style_state1_elRemainingchars1300397438 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    
    return (
      <div className="Editor">
        <div className="layoutFlow">
          <div className="state1_elProjectDescrition880152582">
            <div className="font-ralewayBold" style={style_state1_elProjectDescrition880152582}>
              <div>{this.context.locStrings.comp8_projectdescrition_880152582}</div>
            </div>
          </div>
          
          <div className="embeddedContent state1_elEmbed341566042">
            <div style={style_state1_elEmbed341566042}>
              <div dangerouslySetInnerHTML={{__html: htmlContent_embed}}></div>
            </div>
          </div>
          
          <div className="state1_elTextareaCount686172016">
            <div className="baseFont" style={style_state1_elTextareaCount686172016}>
              <div>{value_textareaCount}</div>
            </div>
          </div>
          
          <div className="state1_elRemainingchars1300397438">
            <div className="baseFont" style={style_state1_elRemainingchars1300397438}>
              <div>{this.context.locStrings.comp8_remainingchars_1300397438}</div>
            </div>
          </div>
          
          <div className="state1_elSpacer1261194532">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 3) --- 
  
  
  
  getValue_state2_elTextareaCount686172016 = () => {
  let transformPropValue_textareaCount = (input) => {
    input = localStorage.getItem('ds_ProjectDescripTotalChar')
    return input
  }
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_textareaCount((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_ProjectDescripTotalChar'] : '')));
  }
  
  
  renderState2() {
    const style_state2_elProjectDescrition880152582 = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    // Embedded HTML content for element 'embed'
    const htmlContent_embed = "<div id=editor style='width:100%; height:300px; border-radius: 0px 0px 20px 20px; background-color:#ffffff'>";
    
    const style_state2_elEmbed341566042 = {
      pointerEvents: 'auto',
     };
    
    const value_textareaCount = this.getValue_state2_elTextareaCount686172016();
    
    const style_state2_elTextareaCount686172016 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    const style_state2_elRemainingchars1300397438 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    
    return (
      <div className="Editor">
        <div className="layoutFlow">
          <div className="state2_elProjectDescrition880152582">
            <div className="font-ralewayBold" style={style_state2_elProjectDescrition880152582}>
              <div>{this.context.locStrings.comp8_projectdescrition_880152582}</div>
            </div>
          </div>
          
          <div className="embeddedContent state2_elEmbed341566042">
            <div style={style_state2_elEmbed341566042}>
              <div dangerouslySetInnerHTML={{__html: htmlContent_embed}}></div>
            </div>
          </div>
          
          <div className="state2_elTextareaCount686172016">
            <div className="baseFont" style={style_state2_elTextareaCount686172016}>
              <div>{value_textareaCount}</div>
            </div>
          </div>
          
          <div className="state2_elRemainingchars1300397438">
            <div className="baseFont" style={style_state2_elRemainingchars1300397438}>
              <div>{this.context.locStrings.comp8_remainingchars_1300397438}</div>
            </div>
          </div>
          
          <div className="state2_elSpacer1261194532">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
    }
  }
  
}
