import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

import ProjectslisComp from './ProjectslisComp';
import Compnavfotterh from './Compnavfotterh';
import MenuWeb from './MenuWeb';
import Empty from './Empty';
import Menumobile from './Menumobile';
import Menu from './Menu';
import OpenlanguageSelector from './OpenlanguageSelector';
import ModelEditonMap from './ModelEditonMap';

export default class ListProjectsScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.context.appActions.dataSheets['projects'];
      let serviceOptions = this.context.appActions.serviceOptions_projects;
      if ( !this.context.appActions.dataSheetLoaded['projects']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("group:projects", this.context.appActions.dataSlots);
        this.context.appActions.loadData_firebaseConnection(dataSheet, serviceOptions, true);
        this.context.appActions.dataSheetLoaded['projects'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    
    let contentElement_elSwapperSidebar;  // This element's content can vary based on screen size
    contentElement_elSwapperSidebar = (<MenuWeb />);
    if (this.context.deviceInfo.screenFormatId === 'narrow-phone') {
      contentElement_elSwapperSidebar = (<Empty />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'wide-phone') {
      contentElement_elSwapperSidebar = (<Empty />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'narrow-tablet') {
      contentElement_elSwapperSidebar = (<MenuWeb />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'wide-tablet') {
      contentElement_elSwapperSidebar = (<MenuWeb />);
    }
    
    
    let contentElement_elSwapper;  // This element's content can vary based on screen size
    contentElement_elSwapper = (<Menumobile />);
    if (this.context.deviceInfo.screenFormatId === 'narrow-phone') {
      contentElement_elSwapper = (<Menumobile />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'wide-phone') {
      contentElement_elSwapper = (<Menumobile />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'narrow-tablet') {
      contentElement_elSwapper = (<Menu />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'wide-tablet') {
      contentElement_elSwapper = (<Menu />);
    }
    
    
    const style_elRect = {
      background: 'rgba(213, 186, 0, 1.000)',
     };
    const elOpenlanguageSelector = ((val) => { return val === "true" || val == true || val == 1 })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotLangSelector'] : '')) ? (
      <div className="hasNestedComps elOpenlanguageSelector">
        <OpenlanguageSelector ref={(el)=> this._elOpenlanguageSelector = el} />
      </div>
     ) : null;
    const elModelEditonMap = ((val) => { return val === "true" || val == true || val == 1 })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_editOnmap'] : '')) ? (
      <div className="hasNestedComps containerMinHeight elModelEditonMap">
        <ModelEditonMap ref={(el)=> this._elModelEditonMap = el} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen ListProjectsScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="hasNestedComps elProjectslisComp">
            <div>
              <ProjectslisComp ref={(el)=> this._elProjectslisComp = el} />
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps elCompnafooter">
              <Compnavfotterh ref={(el)=> this._elCompnafooter = el} />
            </div>
            <div className="hasNestedComps elSwapperSidebar">
              {contentElement_elSwapperSidebar}
            </div>
            <div className="hasNestedComps elSwapper">
              {contentElement_elSwapper}
            </div>
            <div className="elRect" style={style_elRect} />
            { elOpenlanguageSelector }
            { elModelEditonMap }
          </div>
        </div>
      </div>
    )
  }
  
}
