import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import './App.css';
import ScreenContext from './ScreenContext';

import Comp2 from './Comp2';
import img_state0_elClose592589 from './images/RequestpartnershiptForm_state0_elClose592589.png';
import img_state1_elClose592589 from './images/RequestpartnershiptForm_state1_elClose592589.png';

// UI framework component imports
import Input from 'muicss/lib/react/input';
import Textarea from 'muicss/lib/react/textarea';
import Button from 'muicss/lib/react/button';

export default class RequestpartnershiptForm extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
      state0_elValidator_visible: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  
  
  textInputChanged_state0_elName = (event) => {
    this.setState({name: event.target.value});
  }
  
  getValue_state0_elName = () => {
    return this.state.name || '';
  }
  
  textInputChanged_state0_elEmail = (event) => {
    this.setState({email: event.target.value});
  }
  
  getValue_state0_elEmail = () => {
    return this.state.email || '';
  }
  
  textInputChanged_state0_elOrganization = (event) => {
    this.setState({organization: event.target.value});
  }
  
  getValue_state0_elOrganization = () => {
    return this.state.organization || '';
  }
  
  textAreaChanged_state0_elMessage = (event) => {
    this.setState({message: event.target.value});
  }
  
  getValue_state0_elMessage = () => {
    return this.state.message || '';
  }
  
  
  
  onClick_state0_elButton = async () => {
    // Perform action 'Validate' on element 'validator'
    const items = [
    ];
    
    let valid = true;
    for (let i = 0; i < items.length; ++i) {
      let item = items[i];
      valid &= item.result;
      if ( !valid) {
        if ( !process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          console.log("Validator 'validator' failed: item '" + item.name + "' is not valid, value: '" + item.value + "'");
        }
        break;
      }
    };
    this.setState({state0_elValidator_visible: !valid});
    
    if ( !valid) {
      return;
    }
  
    const db = firebase.firestore();
        	var message = this.state.message
        	var userName = this.state.name
        	var userEmail = this.state.email
        	var organization = this.state.organization
        	var message = this.state.message
        	var type = this.context.appActions.dataSlots['ds_type']
    
        	var docRef = db.collection(`partnershipMessages`).doc()
        	
        	   let setDoc = docRef.set({
        	       //document_key: uid, 
        	       userName: userName,
        	       userEmail: userEmail,
        	       message: message,
        	       organization: organization, 
        	       type: type,
        	       timestamp: firebase.firestore.FieldValue.serverTimestamp()})
        	   .then(setDoc => {
        	     console.log("Document successfully written!");
        	});
        
  
    this.setState({visualStateIndexOverride: 1})
  
  }
  
  
  onClick_state0_elClose592589 = async () => {
    // Go back in screen navigation history
    this.context.appActions.goBack();
  
  }
  
  
  renderState0() {
    const style_state0_elTextRDemo = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_state0_elTxttakeamoment = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_state0_elName = {
      display: 'block',
      paddingTop: 0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state0_elEmail = {
      display: 'block',
      paddingTop: 0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state0_elOrganization = {
      display: 'block',
      paddingTop: 0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state0_elMessage = {
      display: 'block',
      pointerEvents: 'auto',
     };
    const style_state0_elOrgtypetxt = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformStateValue_state0_elComp = (input) => {
      var ds_activeLang = this.context.appActions.dataSlots['ds_activeLang'];
      
      if(ds_activeLang === 'ptbr') {
        return 1;
      } else {
        return 0;
      }
    }
    const style_state0_elValidator = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const state0_elValidator = this.state.state0_elValidator_visible ? (
      <div className="state0_elValidator">
        <div className="baseFont" style={style_state0_elValidator}>
          <div>{this.context.locStrings.requestpartnershiptform_validator_661082}</div>
        </div>
      </div>
      
     ) : null;
    
    const style_state0_elButton = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elClose592589 = {
      backgroundImage: 'url('+img_state0_elClose592589+')',
      backgroundSize: '100% 100%',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="RequestpartnershiptForm">
        <div className="layoutFlow">
          <div className="state0_elTextRDemo">
            <div className="headlineFont" style={style_state0_elTextRDemo}>
              <div>{this.context.locStrings.requestpartnershiptform_textrdemo_1010421}</div>
            </div>
          </div>
          
          <div className="state0_elTxttakeamoment">
            <div className="baseFont" style={style_state0_elTxttakeamoment}>
              <div>{this.context.locStrings.requestpartnershiptform_txttakeamoment_547288}</div>
            </div>
          </div>
          
          <div className="state0_elName">
            <Input className="baseFont" style={style_state0_elName} type="text" placeholder={this.context.locStrings.requestpartnershiptform_name_347825} onChange={this.textInputChanged_state0_elName} value={this.getValue_state0_elName()}  />
          </div>
          
          <div className="state0_elEmail">
            <Input className="baseFont" style={style_state0_elEmail} type="text" placeholder={this.context.locStrings.requestpartnershiptform_email_563601} onChange={this.textInputChanged_state0_elEmail} value={this.getValue_state0_elEmail()}  />
          </div>
          
          <div className="state0_elOrganization">
            <Input className="baseFont" style={style_state0_elOrganization} type="text" placeholder={this.context.locStrings.requestpartnershiptform_organization_37103} onChange={this.textInputChanged_state0_elOrganization} value={this.getValue_state0_elOrganization()}  />
          </div>
          
          <div className="state0_elMessage">
            <Textarea className="baseFont" style={style_state0_elMessage}  placeholder={this.context.locStrings.requestpartnershiptform_message_958656} onChange={this.textAreaChanged_state0_elMessage} value={this.getValue_state0_elMessage()}  />
          </div>
          
          <div className="state0_elOrgtypetxt">
            <div className="font-AppleSystemUIFont" style={style_state0_elOrgtypetxt}>
              <div>{this.context.locStrings.requestpartnershiptform_orgtypetxt_759534}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state0_elComp">
            <div>
              <Comp2 visualStateIndex={transformStateValue_state0_elComp((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_activeLang'] : ''))} ref={(el)=> this._state0_elComp = el} />
            </div>
          </div>
          
          { state0_elValidator }
          <div className="state0_elButton">
            <Button className="actionFont" style={style_state0_elButton}  color="accent" onClick={this.onClick_state0_elButton} >
              {this.context.locStrings.requestpartnershiptform_button_283263}
            </Button>
          </div>
          
          <div className="state0_elSpacer">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <div className="state0_elClose592589" style={style_state0_elClose592589} onClick={this.onClick_state0_elClose592589}  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  
  
  onClick_state1_elButton = async () => {
    // Go back in screen navigation history
    this.context.appActions.goBack();
  
  }
  
  
  onClick_state1_elClose592589 = async () => {
    // Go back in screen navigation history
    this.context.appActions.goBack();
  
  }
  
  
  renderState1() {
    const style_state1_elTextRDemo = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_state1_elTxttakeamoment = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_state1_elButton = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elClose592589 = {
      backgroundImage: 'url('+img_state1_elClose592589+')',
      backgroundSize: '100% 100%',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="RequestpartnershiptForm">
        <div className="layoutFlow">
          <div className="state1_elTextRDemo">
            <div className="headlineFont" style={style_state1_elTextRDemo}>
              <div>{this.context.locStrings.requestpartnershiptform_textrdemo_195100}</div>
            </div>
          </div>
          
          <div className="state1_elTxttakeamoment">
            <div className="baseFont" style={style_state1_elTxttakeamoment}>
              <div>{this.context.locStrings.requestpartnershiptform_txttakeamoment_612979}</div>
            </div>
          </div>
          
          <div className="state1_elButton">
            <Button className="actionFont" style={style_state1_elButton}  color="accent" onClick={this.onClick_state1_elButton} >
              {this.context.locStrings.requestpartnershiptform_button_454689}
            </Button>
          </div>
          
          <div className="state1_elSpacer2">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <div className="state1_elClose592589" style={style_state1_elClose592589} onClick={this.onClick_state1_elClose592589}  />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
