import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elCoverimage from './images/ProjectItem_elCoverimage_1371387613.jpg';
import img_elHeroimage from './images/ProjectItem_elCoverimage_1371387613.jpg';

export default class PlistItem extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // neighborhood, projectName, summary, coverPhoto, projectDescript, heroPhoto, address, projectTitle, projectPhase, phaseExpectedEnd, phaseDescription

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  getValue_elNewprojecttext = () => {
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.projectName);
  }
  
  
  getValue_elNeighbourhood = () => {
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.neighborhood);
  }
  
  
  getValue_elSummary = () => {
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.summary);
  }
  
  
  getValue_elDescription = () => {
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.projectDescript);
  }
  
  
  getValue_elProjectTitle = () => {
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.projectTitle);
  }
  
  
  getValue_elAddress = () => {
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.address);
  }
  
  
  getValue_elPhase = () => {
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.projectPhase);
  }
  
  
  getValue_elPhaseEnd = () => {
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.phaseExpectedEnd);
  }
  
  
  getValue_elPhaseDescription = () => {
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.phaseDescription);
  }
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
      borderRadius: '10.0px',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      borderRadius: '10.0px',
      filter: 'drop-shadow(1.0px 1.0px 2px #eaeaea)',
      overflow: 'visible',
     };
    
    const value_newprojecttext = this.getValue_elNewprojecttext();
    
    const style_elNewprojecttext = {
      color: '#7f7eff',
      textAlign: 'left',
     };
    
    const value_neighbourhood = this.getValue_elNeighbourhood();
    
    const style_elNeighbourhood = {
      fontSize: 14.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_summary = this.getValue_elSummary();
    
    const style_elSummary = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elCoverimage = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp(this.props.coverPhoto)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      borderRadius: '10.0px',
      boxShadow: '1.0px 1.0px 2px #eaeaea',
     };
    const style_elHeroimage = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp(this.props.heroPhoto)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      borderRadius: '10.0px',
      boxShadow: '1.0px 1.0px 2px #eaeaea',
     };
    const style_elHeroimage_outer = {
      display: 'none',
     };
    
    const value_description = this.getValue_elDescription();
    
    const style_elDescription = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elDescription_outer = {
      display: 'none',
     };
    
    const value_projectTitle = this.getValue_elProjectTitle();
    
    const style_elProjectTitle = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elProjectTitle_outer = {
      display: 'none',
     };
    
    const value_address = this.getValue_elAddress();
    
    const style_elAddress = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elAddress_outer = {
      display: 'none',
     };
    
    const value_phase = this.getValue_elPhase();
    
    const style_elPhase = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elPhase_outer = {
      display: 'none',
     };
    
    const value_phaseEnd = this.getValue_elPhaseEnd();
    
    const style_elPhaseEnd = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elPhaseEnd_outer = {
      display: 'none',
     };
    
    const value_phaseDescription = this.getValue_elPhaseDescription();
    
    const style_elPhaseDescription = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elPhaseDescription_outer = {
      display: 'none',
     };
    
    return (
      <div className="PlistItem">
        <div className="background">
          <div className="elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="elNewprojecttext">
            <div className="headlineFont" style={style_elNewprojecttext}>
              <div>{value_newprojecttext}</div>
            </div>
          </div>
          
          <div className="elNeighbourhood">
            <div className="font-ralewayBold" style={style_elNeighbourhood}>
              <div>{value_neighbourhood}</div>
            </div>
          </div>
          
          <div className="elSummary">
            <div className="baseFont" style={style_elSummary}>
              <div>{value_summary}</div>
            </div>
          </div>
          
          <div className="elCoverimage">
            <div style={style_elCoverimage} />
          </div>
          
          <div className="flowRow flowRow_PlistItem_elHeroimage_1214261014">
          <div className="elHeroimage" style={style_elHeroimage_outer}>
            <div style={style_elHeroimage} />
          </div>
          
          <div className="elDescription" style={style_elDescription_outer}>
            <div className="baseFont" style={style_elDescription}>
              <div>{value_description}</div>
            </div>
          </div>
          
          <div className="elProjectTitle" style={style_elProjectTitle_outer}>
            <div className="baseFont" style={style_elProjectTitle}>
              <div>{value_projectTitle}</div>
            </div>
          </div>
          
          <div className="elAddress" style={style_elAddress_outer}>
            <div className="baseFont" style={style_elAddress}>
              <div>{value_address}</div>
            </div>
          </div>
          
          <div className="elPhase" style={style_elPhase_outer}>
            <div className="baseFont" style={style_elPhase}>
              <div>{value_phase}</div>
            </div>
          </div>
          
          <div className="elPhaseEnd" style={style_elPhaseEnd_outer}>
            <div className="baseFont" style={style_elPhaseEnd}>
              <div>{value_phaseEnd}</div>
            </div>
          </div>
          
          <div className="elPhaseDescription" style={style_elPhaseDescription_outer}>
            <div className="baseFont" style={style_elPhaseDescription}>
              <div>{value_phaseDescription}</div>
            </div>
          </div>
          
          </div>
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
