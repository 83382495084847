import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import firebase from 'firebase/app';
import 'firebase/firestore';
import img_elBgphoto from './images/AccountSetupScreen_state0_elBgphoto389432_389432.jpg';
import img_elProgressBar2 from './images/GeoaddressScreen_elProgressBar2_256997.png';
import Geolocation from './Geolocation';
import img_elClose from './images/AccountSetupScreen_state0_elClose538058_538058.png';

// UI framework component imports
import Input from 'muicss/lib/react/input';
import Button from 'muicss/lib/react/button';

export default class GeoaddressScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
      elValidator_visible: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  
  textInputChanged_elFieldnumber = (event) => {
    this.setState({fieldnumber: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_SlotPhoneNumber", event.target.value);
  }
  
  getValue_elFieldnumber = () => {
    return (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotPhoneNumber'] : '');
  }
  
  
  onClick_elButton = async () => {
    // Perform action 'Validate' on element 'validator'
    const items = [
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotPhoneNumber'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotPhoneNumber'] : '').toString().length > 0, name: 'ds_SlotPhoneNumber', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotPhoneNumber'] : '') },
    ];
    
    let valid = true;
    for (let i = 0; i < items.length; ++i) {
      let item = items[i];
      valid &= item.result;
      if ( !valid) {
        if ( !process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          console.log("Validator 'validator' failed: item '" + item.name + "' is not valid, value: '" + item.value + "'");
        }
        break;
      }
    };
    this.setState({elValidator_visible: !valid});
    
    if ( !valid) {
      return;
    }
  
    var db = firebase.firestore();
        
    var name = this.context.appActions.dataSlots['ds_SlotUserName']
    var fullname = this.context.appActions.dataSlots['ds_SlotFullName']
    var uid = this.context.appActions.dataSlots['ds_SlotUserId']
    var gender = this.context.appActions.dataSlots['ds_SlotGender'];
    var userEmail = this.context.appActions.dataSlots['ds_SlotUserEmail'];
    var age = this.context.appActions.dataSlots['ds_SlotAge'];
    var userphoto = this.context.appActions.dataSlots['ds_SlotUserPhoto'];
    var userphone = this.context.appActions.dataSlots['ds_SlotPhoneNumber'];
    var customer = this.props.stripeId;
    console.log('customer',customer)
    var hashslot = localStorage.getItem('ds_SlotHash');
    var latslot = localStorage.getItem('ds_SlotLat');
    var lonslot = localStorage.getItem('ds_SlotLon');
    var cityslot = localStorage.getItem('ds_SlotCity');
    var state = localStorage.getItem('ds_SlotState');
    var countryslot = localStorage.getItem('ds_SlotCountry');
    var neighborhood = localStorage.getItem('ds_Neighborhood');
    var address = localStorage.getItem('ds_mygeo'); 
    var complement = localStorage.getItem('ds_addressComplement'); 
    var spacename = localStorage.getItem('ds_spaceName');
    var spaceId = localStorage.getItem('ds_spaceId');
    var organizationName = localStorage.getItem('ds_organizationName');
    var organizationId = localStorage.getItem('ds_organizationId');
    //var isAdmin = this.context.appActions.dataSlots['ds_userisAdmin'];
    var userStatus = this.context.appActions.dataSlots['ds_userStatus'];
    var lat = Number(latslot)
    var lng = Number(lonslot)
    var hash = hashslot
    var city = cityslot
    var country = countryslot
            
        let setDoc = await db.collection(`users`).doc(`${uid}`).set({
          displayName: name,
          fullName: fullname,
          photoURL: userphoto,
          userEmail: userEmail,
          gender: gender, 
          age: age,
          userphone: userphone,
          isSubscribed: true,
          isAccount: true,
          isAdmin: false,
          userStatus: userStatus,
          organizationName: organizationName,
          organizationId: organizationId,
          spaceName: spacename,
          spaceId: spaceId,
          geohash: hash,
          lat: lat,
          lng: lng,
          address: address,
          complement: complement,
          city: city,
          state: state,
          neighborhood: neighborhood,
          country_name: country,
          geolocation: new firebase.firestore.GeoPoint(lat, lng),
          timestamp: firebase.firestore.FieldValue.serverTimestamp()})
        .then(setDoc => {
        })  
            
        
        
        
        
  
    // Go to screen 'lastquestion'
    this.context.appActions.goToScreen('lastquestion', this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elClose = async () => {
    this.context.appActions.updateDataSlot('ds_userIsSubscribed', "");
  
    // Go to screen 'Account Setup'
    this.context.appActions.goToScreen('accountSetup', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBgphoto = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_homebackground'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elBackground = {
      width: '100%',
      height: '100%',
      borderRadius: '20.0px',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
      borderRadius: '20.0px',
     };
    const style_elTitle1 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elProgressBar2 = {
      backgroundImage: 'url('+img_elProgressBar2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elTitle2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elTitle3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elFieldnumber = {
      display: 'block',
      paddingTop: 0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    let transformStateValue_elCompGeo = (input) => {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
      let val = this.context.appActions.dataSlots['ds_screen_state'];
      this.context.appActions.updateDataSlot('ds_screen_state', (val + 1) % 2);
      }, 2000);
      
      
      return input;
    
    }
    const style_elValidator = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const elValidator = this.state.elValidator_visible ? (
      <div className="elValidator">
        <div className="baseFont" style={style_elValidator}>
          <div>{this.context.locStrings.geoaddress_validator_18898}</div>
        </div>
      </div>
      
     ) : null;
    
    const style_elButton = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elClose = {
      backgroundImage: 'url('+img_elClose+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen GeoaddressScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBgphoto" style={style_elBgphoto} />
          <div className="elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elTitle1">
            <div className="headlineFont" style={style_elTitle1}>
              <div>{this.context.locStrings.accountsetup_text_915386}</div>
            </div>
          </div>
          
          <div className="elProgressBar2">
            <div style={style_elProgressBar2} />
          </div>
          
          <div className="elTitle2">
            <div className="baseFont" style={style_elTitle2}>
              <div>{this.context.locStrings.geoaddress_text3_900322}</div>
            </div>
          </div>
          
          <div className="elTitle3">
            <div className="headlineFont" style={style_elTitle3}>
              <div>{this.context.locStrings.geoaddress_text2_410423}</div>
            </div>
          </div>
          
          <div className="elFieldnumber">
            <Input className="baseFont" style={style_elFieldnumber} type="number" placeholder={this.context.locStrings.geoaddress_field_650966} onChange={this.textInputChanged_elFieldnumber} value={this.getValue_elFieldnumber()}  />
          </div>
          
          <div className="hasNestedComps elCompGeo">
            <div>
              <Geolocation visualStateIndex={transformStateValue_elCompGeo((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_screen_state'] : ''))} ref={(el)=> this._elCompGeo = el} />
            </div>
          </div>
          
          { elValidator }
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              {this.context.locStrings.accountsetup_button_761106}
            </Button>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elClose" style={style_elClose} onClick={this.onClick_elClose}  />
          </div>
        </div>
      </div>
    )
  }
  
}
