import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import CompmyGeo from './CompmyGeo';
import img_state0_elMapgeoicon1657176273 from './images/Geolocation_state0_elMapgeo792794_792794.png';
import img_state1_elMapgeoicon1657176273 from './images/Geolocation_state0_elMapgeo792794_792794.png';

// UI framework component imports
import Input from 'muicss/lib/react/input';

export default class Geolocation2 extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  
  
  onClick_state0_elTextCopy1532022204 = async () => {
    let newVal = "true";
    this.context.appActions.updateDataSlot('ds_editOnmap', newVal);
  
    newVal = "true";
    this.context.appActions.updateDataSlot('ds_preloadergif', newVal);
  
  }
  
  
  onClick_state0_elMapgeoicon1657176273 = async () => {
    let newVal = "true";
    this.context.appActions.updateDataSlot('ds_editOnmap', newVal);
  
    newVal = "true";
    this.context.appActions.updateDataSlot('ds_preloadergif', newVal);
  
  }
  
  
  
  textInputChanged_state0_elField1816951457 = (event) => {
    this.setState({field: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_addressComplement", event.target.value);
  }
  
  getValue_state0_elField1816951457 = () => {
    return this.state.field || '';
  }
  
  renderState0() {
    const style_state0_elBackground837232966 = {
      width: '100%',
      height: '100%',
      borderRadius: '10.0px',
     };
    const style_state0_elBackground837232966_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
      borderRadius: '10.0px',
      filter: 'drop-shadow(2.0px 2.0px 2px #feffff)',
      overflow: 'visible',
     };
    const style_state0_elText832100114 = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_state0_elTextCopy1532022204 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elMapgeoicon1657176273 = {
      backgroundImage: 'url('+img_state0_elMapgeoicon1657176273+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elText21318623397 = {
      fontSize: 14.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_state0_elField1816951457 = {
      display: 'block',
      paddingTop: 0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Geolocation2">
        <div className="background">
          <div className="state0_elBackground837232966" style={style_state0_elBackground837232966_outer}>
            <div style={style_state0_elBackground837232966} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state0_elText832100114">
            <div className="font-ralewayBold" style={style_state0_elText832100114}>
              <div>{this.context.locStrings.geolocation2_text_832100114}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state0_elCompmyGeo1785133442">
            <div>
              <CompmyGeo {...this.props} ref={(el)=> this._state0_elCompmyGeo1785133442 = el} />
            </div>
          </div>
          
          <div className="flowRow flowRow_Geolocation2_state0_elTextCopy1532022204_1532022204">
          <div className="state0_elTextCopy1532022204">
            <div className="baseFont" style={style_state0_elTextCopy1532022204} onClick={this.onClick_state0_elTextCopy1532022204} >
              <div>{this.context.locStrings.geolocation2_textcopy_1532022204}</div>
            </div>
          </div>
          
          </div>
          <div className="state0_elMapgeoicon1657176273">
            <div style={style_state0_elMapgeoicon1657176273} onClick={this.onClick_state0_elMapgeoicon1657176273}  />
          </div>
          
          <div className="state0_elText21318623397">
            <div className="font-ralewayBold" style={style_state0_elText21318623397}>
              <div>{this.context.locStrings.geolocation2_text2_1318623397}</div>
            </div>
          </div>
          
          <div className="state0_elField1816951457">
            <Input className="baseFont" style={style_state0_elField1816951457} type="text" placeholder={this.context.locStrings.geolocation2_field_1816951457} onChange={this.textInputChanged_state0_elField1816951457} value={this.getValue_state0_elField1816951457()}  />
          </div>
          
          <div className="state0_elSpacer983853017">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  
  
  onClick_state1_elTextCopy1532022204 = async () => {
    let newVal = "true";
    this.context.appActions.updateDataSlot('ds_editOnmap', newVal);
  
    newVal = "true";
    this.context.appActions.updateDataSlot('ds_preloadergif', newVal);
  
  }
  
  
  onClick_state1_elMapgeoicon1657176273 = async () => {
    let newVal = "true";
    this.context.appActions.updateDataSlot('ds_editOnmap', newVal);
  
    newVal = "true";
    this.context.appActions.updateDataSlot('ds_preloadergif', newVal);
  
  }
  
  
  
  textInputChanged_state1_elField1816951457 = (event) => {
    this.setState({field: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_addressComplement", event.target.value);
  }
  
  getValue_state1_elField1816951457 = () => {
    return this.state.field || '';
  }
  
  renderState1() {
    const style_state1_elBackground837232966 = {
      width: '100%',
      height: '100%',
      borderRadius: '10.0px',
     };
    const style_state1_elBackground837232966_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
      borderRadius: '10.0px',
      filter: 'drop-shadow(2.0px 2.0px 2px #feffff)',
      overflow: 'visible',
     };
    const style_state1_elText832100114 = {
      fontSize: 16.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_state1_elTextCopy1532022204 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elMapgeoicon1657176273 = {
      backgroundImage: 'url('+img_state1_elMapgeoicon1657176273+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elText21318623397 = {
      fontSize: 14.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_state1_elField1816951457 = {
      display: 'block',
      paddingTop: 0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Geolocation2">
        <div className="background">
          <div className="state1_elBackground837232966" style={style_state1_elBackground837232966_outer}>
            <div style={style_state1_elBackground837232966} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state1_elText832100114">
            <div className="font-ralewayBold" style={style_state1_elText832100114}>
              <div>{this.context.locStrings.geolocation2_text_832100114}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state1_elCompmyGeo1785133442">
            <div>
              <CompmyGeo {...this.props} ref={(el)=> this._state1_elCompmyGeo1785133442 = el} />
            </div>
          </div>
          
          <div className="flowRow flowRow_Geolocation2_state1_elTextCopy1532022204_1532022204">
          <div className="state1_elTextCopy1532022204">
            <div className="baseFont" style={style_state1_elTextCopy1532022204} onClick={this.onClick_state1_elTextCopy1532022204} >
              <div>{this.context.locStrings.geolocation2_textcopy_1532022204}</div>
            </div>
          </div>
          
          </div>
          <div className="state1_elMapgeoicon1657176273">
            <div style={style_state1_elMapgeoicon1657176273} onClick={this.onClick_state1_elMapgeoicon1657176273}  />
          </div>
          
          <div className="state1_elText21318623397">
            <div className="font-ralewayBold" style={style_state1_elText21318623397}>
              <div>{this.context.locStrings.geolocation2_text2_1318623397}</div>
            </div>
          </div>
          
          <div className="state1_elField1816951457">
            <Input className="baseFont" style={style_state1_elField1816951457} type="text" placeholder={this.context.locStrings.geolocation2_field_1816951457} onChange={this.textInputChanged_state1_elField1816951457} value={this.getValue_state1_elField1816951457()}  />
          </div>
          
          <div className="state1_elSpacer983853017">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
