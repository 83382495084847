import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_state0_elPreloader from './images/WaitScreen_state0_elPreloader_626520.png';
import GlobalcheckConnection from './GlobalcheckConnection';

export default class WelcomeScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  // fetch geo country 
         function geo() {
          var https = require('https');
          https.get('https://ipapi.co/json/', function(resp){
              var body = ''
              resp.on('data', function(data){
                  body += data;
              });

              resp.on('end', function(){
                  var loc = JSON.parse(body);
                  console.log('Ip loc' , loc);

                  var Ipcountry_name = loc.country_name
                  var ptbr = 'ptbr'
                  console.log('Ip country_name', Ipcountry_name)
                  localStorage.setItem("ds_SlotCountry", Ipcountry_name);

                  if (Ipcountry_name == 'Brazil'){
                    localStorage.setItem("ds_activeLang", ptbr);
                  }
              });
          });
    } 
   geo();
   //end ini ipapi
    
  setTimeout(function(){ 
  	//wait to go screen accountSetup 
    }, this.context.appActions.goToScreen('start', { transitionId: 'slideIn_bottom' }) , 4400);
    
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  renderState0() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state0_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    const style_state0_elPreloader = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotLoadingAnim'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    return (
      <div className="AppScreen WelcomeScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state0_elBackground" style={style_state0_elBackground_outer}>
            <div className="appBg" style={style_state0_elBackground} />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="state0_elPreloader" style={style_state0_elPreloader} />
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  
  onClick_state1_elHotspot = async () => {
    window.location.reload()
  
  }
  
  
  renderState1() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    
    const style_state1_elNavigatoronLine = {
      background: 'rgba(255, 125, 120, 1.000)',
     };
    const style_state1_elRetry = {
      color: '#feffff',
      textAlign: 'center',
      textShadow: '#feffff 1.0px 1.0px 2.0px',
     };
    
    const style_state1_elHotspot = {
      display: 'block',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen WelcomeScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state1_elNavigatoronLine" style={style_state1_elNavigatoronLine} />
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="hasNestedComps state1_elGlobalcheckConnection">
            <div>
              <GlobalcheckConnection ref={(el)=> this._state1_elGlobalcheckConnection = el} />
            </div>
          </div>
          
          <div className="state1_elRetry">
            <div className="actionFont" style={style_state1_elRetry}>
              <div>{this.context.locStrings.welcome_retry_213326}</div>
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="actionFont containerMinHeight state1_elHotspot" style={style_state1_elHotspot} onClick={this.onClick_state1_elHotspot}  />
          </div>
        </div>
      </div>
    )
  }
  
  
  render() {
    let transformStateValue = (input) => {
      if(navigator.onLine === true){
      console.log('online')
      return 0;
      }else{
       console.log('offline')
      return 1;
      }
    }
    switch (parseInt((transformStateValue(this.context.ds_internetConnection) !== undefined) ? transformStateValue(this.context.ds_internetConnection) : 0, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
