import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elCourbaniza from './images/Menu_state0_elCoUrbaniza461992_461992.png';
import btn_icon_419438216 from './images/btn_icon_419438216.png';
import Draweroff from './Draweroff';

// UI framework component imports

export default class Menumobilelogout extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      draweroff_isOpen: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elCourbaniza = async () => {
    // Go to screen 'Feed'
    this.context.appActions.goToScreen('feed', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elIconButton = async () => {
    this.toggle_draweroff();
  
  }
  
  
  toggle_draweroff = () => {
    this.setState({draweroff_isOpen: !this.state.draweroff_isOpen});
  }
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
     };
    
    const style_elRect = {
      background: 'rgba(138, 138, 138, 1.000)',
     };
    const style_elCourbaniza = {
      backgroundImage: 'url('+img_elCourbaniza+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elIconButton = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_419438216+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '80.839%',
      backgroundPosition: '50% 0%',
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_draweroff = {
      pointerEvents: (this.state.draweroff_isOpen) ? 'auto' : 'none'
    }
    
    return (
      <div className="Menumobilelogout">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="foreground">
          <div className="elRect" style={style_elRect} />
          <div className="elCourbaniza" style={style_elCourbaniza} onClick={this.onClick_elCourbaniza}  />
          <button className="actionFont elIconButton" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
        </div>
        <div className="drawer draweroff" style={style_draweroff} onClick={this.toggle_draweroff}>
          <div className={"drawerContent" + (this.state.draweroff_isOpen ? " drawerOpen": " drawerClosedRight")} onClick={(e) => {e.stopPropagation();}}>
            <Draweroff visualStateIndex={(this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotDrawer'] : '')} closeDrawer={this.toggle_draweroff} />
          </div>
        </div>
      </div>
    )
  }
  
}
