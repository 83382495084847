import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class Colorslabels extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  
  
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
     };
    const style_elTextproject = {
      fontSize: 13.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elColorprojects = {
      background: 'rgba(255, 133, 128, 1.000)',
     };
    const style_elTextquestions = {
      fontSize: 13.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elColorquestions = {
      background: 'rgba(135, 241, 61, 1.000)',
     };
    const style_elTextpolls = {
      fontSize: 13.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elColorpolls = {
      background: 'rgba(125, 125, 125, 1.000)',
     };
    const style_elTextideas = {
      fontSize: 13.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elColorideas = {
      background: 'rgba(212, 170, 255, 1.000)',
     };
    const style_elTextBlock = {
      fontSize: 12.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    
    return (
      <div className="Colorslabels">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="flowRow flowRow_Colorslabels_elTextproject_153731">
          <div className="elTextproject">
            <div className="systemFontRegular" style={style_elTextproject}>
              <div>{this.context.locStrings.colorslabels_text_153731}</div>
            </div>
          </div>
          
          </div>
          <div className="elColorprojects">
            <div style={style_elColorprojects} />
          </div>
          
          <div className="flowRow flowRow_Colorslabels_elTextquestions_805128">
          <div className="elTextquestions">
            <div className="systemFontRegular" style={style_elTextquestions}>
              <div>{this.context.locStrings.colorslabels_textprojectcopy2_805128}</div>
            </div>
          </div>
          
          </div>
          <div className="elColorquestions">
            <div style={style_elColorquestions} />
          </div>
          
          <div className="flowRow flowRow_Colorslabels_elTextpolls_59314">
          <div className="elTextpolls">
            <div className="systemFontRegular" style={style_elTextpolls}>
              <div>{this.context.locStrings.colorslabels_textprojectcopy3_59314}</div>
            </div>
          </div>
          
          </div>
          <div className="elColorpolls">
            <div style={style_elColorpolls} />
          </div>
          
          <div className="flowRow flowRow_Colorslabels_elTextideas_814310">
          <div className="elTextideas">
            <div className="systemFontRegular" style={style_elTextideas}>
              <div>{this.context.locStrings.colorslabels_textprojectcopy_814310}</div>
            </div>
          </div>
          
          </div>
          <div className="elColorideas">
            <div style={style_elColorideas} />
          </div>
          
          <div className="elTextBlock">
            <div className="systemFontRegular" style={style_elTextBlock}>
              <div>{this.context.locStrings.colorslabels_textblock_42558}</div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
