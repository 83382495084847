import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

import SingleProject from './SingleProject';
import Compnavfotterh from './Compnavfotterh';
import MenuWeb from './MenuWeb';
import Empty from './Empty';
import Menumobile from './Menumobile';
import Menu from './Menu';
import OpenlanguageSelector from './OpenlanguageSelector';
import ModelEditonMap from './ModelEditonMap';
import Backgroundtrans from './Backgroundtrans';

export default class ProjectScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.context.appActions.dataSheets['projects'];
      let serviceOptions = this.context.appActions.serviceOptions_projects;
      if ( !this.context.appActions.dataSheetLoaded['projects']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("group:projects", this.context.appActions.dataSlots);
        this.context.appActions.loadData_firebaseConnection(dataSheet, serviceOptions, true);
        this.context.appActions.dataSheetLoaded['projects'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  onClick_elCompbg = async () => {
    let newVal = "false";
    this.context.appActions.updateDataSlot('(null)', newVal);
  
    newVal = "false";
    this.context.appActions.updateDataSlot('(null)', newVal);
  
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_projects = this.context.dataSheets['projects'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    let filterItems_list = items => {
      var projectURL = localStorage.getItem('ds_url');
      console.log('Project URL', projectURL)
      let filteredItems = [];
      items.forEach(item => {;  
          if (item['projectURL'].includes(projectURL)) {
          filteredItems.push(item);
          }
         });
       items=filteredItems;
      return items;
    
    }
    items_list = items_list.concat(filterItems_list(this.context.appActions.getDataSheet('projects').items));
    this._elList_items = [];
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    
    let contentElement_elSwapperSidebar;  // This element's content can vary based on screen size
    contentElement_elSwapperSidebar = (<MenuWeb />);
    if (this.context.deviceInfo.screenFormatId === 'narrow-phone') {
      contentElement_elSwapperSidebar = (<Empty />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'wide-phone') {
      contentElement_elSwapperSidebar = (<Empty />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'narrow-tablet') {
      contentElement_elSwapperSidebar = (<MenuWeb />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'wide-tablet') {
      contentElement_elSwapperSidebar = (<MenuWeb />);
    }
    
    
    let contentElement_elSwapper;  // This element's content can vary based on screen size
    contentElement_elSwapper = (<Menumobile />);
    if (this.context.deviceInfo.screenFormatId === 'narrow-phone') {
      contentElement_elSwapper = (<Menumobile />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'wide-phone') {
      contentElement_elSwapper = (<Menumobile />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'narrow-tablet') {
      contentElement_elSwapper = (<Menu />);
    }
    else if (this.context.deviceInfo.screenFormatId === 'wide-tablet') {
      contentElement_elSwapper = (<Menu />);
    }
    
    
    const style_elRect = {
      background: 'rgba(213, 186, 0, 1.000)',
     };
    const elOpenlanguageSelector = ((val) => { return val === "true" || val == true || val == 1 })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotLangSelector'] : '')) ? (
      <div className="hasNestedComps elOpenlanguageSelector">
        <OpenlanguageSelector ref={(el)=> this._elOpenlanguageSelector = el} />
      </div>
     ) : null;
    const elModelEditonMap = ((val) => { return val === "true" || val == true || val == 1 })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_editOnmap'] : '')) ? (
      <div className="hasNestedComps containerMinHeight elModelEditonMap">
        <ModelEditonMap ref={(el)=> this._elModelEditonMap = el} />
      </div>
     ) : null;
    const style_elCompbg = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elCompbg = ((val) => { return val === "true" || val == true || val == 1 })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_bgmap'] : '')) ? (
      <div className="hasNestedComps elCompbg" style={style_elCompbg} onClick={this.onClick_elCompbg} >
        <Backgroundtrans ref={(el)=> this._elCompbg = el} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen ProjectScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="hasNestedComps elList">
            <ul style={style_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <SingleProject dataSheetRow={row.document_key} dataSheetId={'projects'} dataSheetRow={row} {...{ 'heroPhoto': row['heroPhoto'], 'catchphrase': row['catchphrase'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
                return (<li key={row.key || index}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </ul>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps elCompnafooter">
              <Compnavfotterh ref={(el)=> this._elCompnafooter = el} />
            </div>
            <div className="hasNestedComps elSwapperSidebar">
              {contentElement_elSwapperSidebar}
            </div>
            <div className="hasNestedComps elSwapper">
              {contentElement_elSwapper}
            </div>
            <div className="elRect" style={style_elRect} />
            { elOpenlanguageSelector }
            { elModelEditonMap }
            { elCompbg }
          </div>
        </div>
      </div>
    )
  }
  
}
