import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class Spot5 extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  render() {
    const style_elResults = {
      fontSize: 16.0,
      color: '#ff9200',
      textAlign: 'left',
     };
    const style_elText2 = {
      color: '#feffff',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Spot5">
        <div className="layoutFlow">
          <div className="elResults">
            <div className="font-ralewayBold" style={style_elResults}>
              <div>{this.context.locStrings.comp8_results_1032394188}</div>
            </div>
          </div>
          
          <div className="elText2">
            <div className="baseFont" style={style_elText2}>
              <div><div dangerouslySetInnerHTML={{__html: this.context.locStrings.comp8_text2_1738661423.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
