import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class OpenlanguageSelector extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  onClick_elChangeLangEn = async () => {
    let newVal = "en";
    this.context.appActions.updateDataSlot('ds_activeLang', newVal);
  
    newVal = "false";
    this.context.appActions.updateDataSlot('ds_SlotLangSelector', newVal);
  
  }
  
  
  
  onClick_elChangeLangPtBR = async () => {
    let newVal = "ptbr";
    this.context.appActions.updateDataSlot('ds_activeLang', newVal);
  
    newVal = "false";
    this.context.appActions.updateDataSlot('ds_SlotLangSelector', newVal);
  
  }
  
  
  render() {
    const style_elBg2 = {
      width: '100%',
      height: '100%',
     };
    const style_elBg2_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    const style_elBg1 = {
      width: '100%',
      height: '100%',
     };
    const style_elBg1_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    const style_elChangeLangEn = {
      fontSize: 14.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elChangeLangPtBR = {
      fontSize: 14.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="OpenlanguageSelector">
        <div className="background">
          <div className="elBg2" style={style_elBg2_outer}>
            <div className="appBg" style={style_elBg2} />
          </div>
          
          <div className="elBg1" style={style_elBg1_outer}>
            <div className="appBg" style={style_elBg1} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="elChangeLangEn">
            <div className="font-ralewaySemiBold" style={style_elChangeLangEn} onClick={this.onClick_elChangeLangEn} >
              <div>{this.context.locStrings.openlanguageselector_changelangen_70030}</div>
            </div>
          </div>
          
          <div className="elChangeLangPtBR">
            <div className="font-ralewaySemiBold" style={style_elChangeLangPtBR} onClick={this.onClick_elChangeLangPtBR} >
              <div>{this.context.locStrings.openlanguageselector_changelangptbr_888407}</div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
