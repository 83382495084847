import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class Component1home extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  render() {
    const style_elTextCopy2 = {
      fontSize: 16.0,
      color: '#ff9200',
      textAlign: 'left',
     };
    const style_elText = {
      fontSize: 15.0,
      color: '#919191',
      textAlign: 'left',
     };
    
    return (
      <div className="Component1home">
        <div className="layoutFlow">
          <div className="elTextCopy2">
            <div className="font-ralewayBold" style={style_elTextCopy2}>
              <div>{this.context.locStrings.component1home_textcopy2_807916}</div>
            </div>
          </div>
          
          <div className="elText">
            <div className="font-ralewayBold" style={style_elText}>
              <div><div dangerouslySetInnerHTML={{__html: this.context.locStrings.component1home_text_287154.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
