import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elNoimagecontn from './images/HeroHearderProject_elNoimagecontn_2052193592.jpg';
import img_elPin from './images/HeroHearderProject_elPin_645531131.png';

export default class HeroHearderProject extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // catchphrase, heroPhoto, dataSheetRow

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  getValue_elText = () => {
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.catchphrase);
  }
  
  
  getValue_elNeighbourhood = () => {
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.neighborhood : '');
  }
  
  render() {
    const style_elNoimagecontn = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp(this.props.heroPhoto)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      borderRadius: '10.0px',
      boxShadow: '1.0px 1.0px 2px #eaeaea',
     };
    
    const value_text = this.getValue_elText();
    
    const style_elText = {
      fontSize: 28.0,
      color: '#feffff',
      textAlign: 'left',
     };
    const style_elPin = {
      backgroundImage: 'url('+img_elPin+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    const value_neighbourhood = this.getValue_elNeighbourhood();
    
    const style_elNeighbourhood = {
      fontSize: 14.0,
      color: '#feffff',
      textAlign: 'left',
     };
    // eslint-disable-next-line no-unused-vars
    const style_elNeighbourhood_inner = {
      textAlign: 'left',
     };
    
    return (
      <div className="HeroHearderProject">
        <div className="background">
          <div className="elNoimagecontn" style={style_elNoimagecontn} />
        </div>
        
        <div className="layoutFlow">
          <div className="elText">
            <div className="font-ralewayBold" style={style_elText}>
              <div>{value_text}</div>
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <div className="elPin" style={style_elPin} />
          <div className="font-ralewayBold  elNeighbourhood" style={style_elNeighbourhood}>
            <div className="bottomAlignedContent">{value_neighbourhood}</div>
          </div>
        </div>
      </div>
    )
  }
  
}
