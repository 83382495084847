// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_projects extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Firebase (Cloud Firestore)'.
    
    item = {};
    this.items.push(item);
    item['userEmail'] = "marcos.c.allencar@gmail.com";
    item['catchphrase'] = "Sustentabilidade na Praça Vicentina de Carvalho";
    item['document_key'] = "Vxrg79tYzrvNPAk7zjIR";
    item['projectType'] = "Mobilidade";
    item['status'] = "true";
    item['summary'] = "Aqui um breve sumário Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eget felis non dui egestas interdum eu sit amet est";
    item['phaseExpectedEnd'] = {"nanoseconds":966000000,"seconds":1664463600};
    item['lng'] = "-46.70184382076263";
    item['docid'] = "Vxrg79tYzrvNPAk7zjIR";
    item['city'] = "São Paulo";
    item['projectTitle'] = "Sustentabilidade na Praça";
    item['projectName'] = "Praça Vicentina de Carvalho";
    item['state'] = "SP";
    item['postType'] = "Project";
    item['country_name'] = "Brazil";
    item['coverPhoto'] = "https://firebasestorage.googleapis.com/v0/b/courbaniza.appspot.com/o/projectUploads%2F1dcf3a83-3c5a-48fd-ba64-2269648824a7.jpeg?alt=media&token=719ad297-3295-47c0-a826-545b86e0160d";
    item['projectURL'] = "Praca_Vicentina_de_Carvalho";
    item['neighborhood'] = "Vila Madalena";
    item['heroPhoto'] = "https://firebasestorage.googleapis.com/v0/b/courbaniza.appspot.com/o/projectUploads%2F1c9d36b0-616e-40ae-af33-6a7ec621c22d.jpg?alt=media&token=8b10c25b-28a5-4ea0-b464-5e33948873d6";
    item['phaseDescription'] = "Acompanhando a diretriz aqui determinada, ajudará em todas as etapas de aprovação do projeto, para desta forma agilizar e melhorar";
    item['projectPhase'] = "Concepção";
    item['timestamp'] = {"nanoseconds":780000000,"seconds":1661890210};
    item['uid'] = "z9BtxlFIx2MSuq6vP4YC2P26MQy2";
    item['lat'] = "-23.548715551635905";
    item['userName'] = "Marcos C. Alencar";
    item['projectDescript'] = "<p><strong>Reformamos a praça</strong></p><p>Após um longo período, conseguimos viabilizar a reforma completa da Praça, com projeto que valoriza a Figueira histórica e mantendo o caráter histórico do espaço. As principais conquistas foram a reforma completa do parquinho, troca de todos os bancos da praça, novo paisagismo e recuperação da iluminação.</p><p><strong>Manutenção e limpeza contínua</strong></p><p>Somos um grupo de moradores e comerciantes que contribui financeiramente para cuidar da Praça Vicentina de Carvalho, Conseguimos salvar a Figueira e reformar a Praça. Foi uma grande conquista!!! Agora precisamos fazer a manutenção e limpeza contínua do parquinho, do jardim, das vias e dos bancos. Com a nossa arrecadação atual conseguimos fazer somente uma limpeza por semana. Você que aprecia essa praça e gosta de vê-la em ordem, contribua! Escolha um valor mensal a partir de R$20,00. Ou se preferir, faça uma contribuição anual. Empresas também podem contribuir. É fácil e seguro!</p><p><strong>Novos Colaboradores</strong></p><p>Atualmente somos duas realizadoras que, de forma voluntária, cuidamos para que a manutenção e a limpeza da praça aconteçam: Somos a Yara Gomes e a Heitor Pestana. Seria bacana que outras pessoas se juntassem a nós. Buscamos parceiros que possam contribuir com seu próprio conhecimento como paisagistas, designers, arquitetos, engenheiros, agrônomos, marqueteiros, redatores, publicitários, analista de mídia social, fotógrafos, etc. Se cada um fizer um pedacinho do trabalho, juntos deixaremos a nossa praça mais encantadora!</p>";
    item['address'] = "Praça Vicentina de Carvalho, 189 - Vila Madalena, São Paulo - SP, 05447-050, Brazil";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['userEmail'] = "marcos.c.allencar@gmail.com";
    item['catchphrase'] = "Nossa Praça da Pimenta";
    item['document_key'] = "Wjy1akLXmDpIiqMrbHcw";
    item['projectType'] = "Mobilidade";
    item['status'] = "true";
    item['summary'] = "Aqui um breve sumário Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eget felis non dui egestas innterdum eu sit amet est sasvas as dolor sit amet, consectetur.";
    item['phaseExpectedEnd'] = {"nanoseconds":450000000,"seconds":1666383118};
    item['lng'] = "-46.70511243187715";
    item['docid'] = "Wjy1akLXmDpIiqMrbHcw";
    item['city'] = "São Paulo";
    item['projectTitle'] = "Mobilidade do Bairro";
    item['projectName'] = "Projeto Mobilidade do Bairro";
    item['state'] = "SP";
    item['postType'] = "Project";
    item['country_name'] = "Brazil";
    item['coverPhoto'] = "https://firebasestorage.googleapis.com/v0/b/courbaniza.appspot.com/o/projectUploads%2F00eab753-d97d-41d2-9ebe-fd1bf7fb0e97.png?alt=media&token=7b99a1b3-0bde-41e3-8141-d7d11e49fd52";
    item['projectURL'] = "Projeto_Mobilidade_do_Bairro";
    item['neighborhood'] = "Alto de Pinheiros";
    item['heroPhoto'] = "https://firebasestorage.googleapis.com/v0/b/courbaniza.appspot.com/o/projectUploads%2F98440b00-7654-4e16-bf67-ce2e87f94215.png?alt=media&token=93d662e5-db83-407b-b22f-a51169c190bb";
    item['phaseDescription'] = "Aqui um breve sumário Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eget felis non dui egestas interdum eu sit amet est";
    item['projectPhase'] = "Execução";
    item['timestamp'] = {"nanoseconds":241000000,"seconds":1661890794};
    item['uid'] = "z9BtxlFIx2MSuq6vP4YC2P26MQy2";
    item['lat'] = "-23.543798318148472";
    item['userName'] = "Marcos C. Alencar";
    item['projectDescript'] = "<p><strong>Praça Comendador Manuel de Melo Pimenta</strong></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eget felis non dui egestas interdum eu sit amet est. Aenean in lacus orci. Nullam ut nisi laoreet, mattis risus sed, blandit velit. Aenean gravida quam auctor, lacinia sem vel, tempor est. Etiam vulputate orci vel dolor rhoncus, et blandit nunc consectetur. Suspendisse potenti. In quam arcu, sodales et nunc sit amet, ornare blandit dui. Donec facilisis porttitor vestibulum. Sed at pharetra diam, quis commodo velit. Nam quis semper tortor, eu aliquam eros. Suspendisse sed sem sed eros suscipit scelerisque. Morbi faucibus orci a ex hendrerit, et ultrices enim placerat.</p><p>Aenean vehicula cursus ante vitae condimentum. Etiam eleifend id elit quis laoreet. Sed non quam id dui vulputate malesuada congue sed ante. Aliquam fermentum metus a magna tincidunt consectetur. Aliquam erat volutpat. Pellentesque quis sem sapien. Vestibulum tempor vulputate quam, sit amet faucibus libero facilisis nec. Cras convallis purus non elit luctus, sit amet pulvinar tellus maximus.</p><p>Fusce pulvinar pulvinar ex. Suspendisse vitae est non erat fringilla rutrum sit amet dignissim risus. Vivamus fringilla massa cursus sapien laoreet laoreet. Pellentesque ullamcorper lobortis dolor, ut ornare est faucibus nec. In lobortis at nulla ac molestie. Nullam a feugiat ante. Phasellus elit massa, mollis eget egestas eget, imperdiet non felis. Morbi mollis libero mi, nec suscipit quam aliquet sed. Proin rutrum mi ipsum, et rhoncus ante commodo in. Donec nec maximus leo. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean non tincidunt erat. Phasellus vel vestibulum arcu.</p>";
    item['address'] = "Praça Comendador Manuel de Melo Pimenta, 116 - Alto de Pinheiros, São Paulo - SP, 05451-110, Brazil";
    item.key = key++;
  }

  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  
  addItem(item, options) {
    console.log("add to firebase: ", item);
      
    let isCollectionGroup = options.servicePath.startsWith("group:");
    if (isCollectionGroup) {
      console.log("unable to add to collection group: ", options.servicePath.substring(6));
      return;
    }
  
    const db = this.firebase.firestore();
    const collection = db.collection(options.servicePath);
  
    let addItemPromise;
    let parsedItem = (({ key, document_key, document_path, ...item }) => (item))( item );
    if (item.document_key && item.document_key.length > 0) {
      addItemPromise = collection.doc(item.document_key).set(parsedItem);
    } else {
      addItemPromise = collection.add(parsedItem);
    }
    addItemPromise
      .then((docRef) => {
        var addedKey="";
        if (docRef) {
          console.log("Firebase document added with id: ", docRef.id);
          addedKey=docRef.id;
          item.document_key = docRef.id;
          item.document_path = docRef.path;
        } else {
          console.log("Firebase document added with id: ", item.document_key);
          addedKey=item.document_key;
        }   
        if (this.onLastAddedDocumentKeyChanged != null) {
          this.onLastAddedDocumentKeyChanged(addedKey);
        }
        //super.addItem(item, options);
      
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        this._fetchComplete(error, options);
      });
      
    return addItemPromise;
  }
  
  removeItem(item, options) {
    //super.removeItem(item, options);
    
    console.log("delete from firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
  
    docRef.delete()
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error deleting document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  
  replaceItemByRowIndex(idx, item, options) {
    //super.replaceItemByRowIndex(idx, item, options);
    
    console.log("update in firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
      
    docRef.update((({ key, document_ref, document_key, document_path, ...item }) => (item))( item ))
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        this._fetchComplete(error, options);
      });
  }
  

}
