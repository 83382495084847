// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_agegroup extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "01";
    item['agegroup'] = "10-15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "02";
    item['agegroup'] = "15-20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "03";
    item['agegroup'] = "20-30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "04";
    item['agegroup'] = "30-40";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "05";
    item['agegroup'] = "40-50";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "06";
    item['agegroup'] = "50-60";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "07";
    item['agegroup'] = "60-70";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "08";
    item['agegroup'] = "70-80";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "09";
    item['agegroup'] = "80+";
    item.key = key++;
  }

}
