import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import firebase from 'firebase/app';
import 'firebase/firestore';
import img_elPreloader from './images/WaitScreen_state0_elPreloader_626520.png';

export default class LoadingfeedScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {

    const google = window.google = window.google ? window.google : {} 
    const geofire = require('geofire-common');
    
    navigator.geolocation.getCurrentPosition(function (position) {
    
            var pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
    
            const latitude  = parseFloat(position.coords.latitude.toFixed(6));
            const longitude = parseFloat(position.coords.longitude.toFixed(6));
            const hash = geofire.geohashForLocation([latitude, longitude]);
    
            var pos2 = new google.maps.LatLng(latitude, longitude);
              
            localStorage.setItem("ds_SlotLat", latitude);
            localStorage.setItem("ds_SlotLon", longitude);
            localStorage.setItem("ds_SlotHash", hash);
    
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({'latLng': pos}, function(results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                          var address = (results[0].formatted_address);
                          console.log('address',address)
                          localStorage.setItem("ds_mygeo", address)
                           if (results[0]) {
                        //console.log(results[0]);
                        // choose from console whatever you need.
                        var city = results[0].address_components[3].short_name;
                        var neig = results[0].address_components[2].short_name;
                        var state = results[0].address_components[4].short_name;
                             
                        console.log( "city name is: ",  city)
                        console.log( "neig name is: ",  neig)
                        console.log( "state name is: ",  state)
                             
                        localStorage.setItem("ds_SlotCity", city)
                        localStorage.setItem("ds_Neighborhood", neig)
                        localStorage.setItem("ds_SlotState", state)
                        }
                    } 
             })
     })

    
var db = firebase.firestore();

var uid = this.context.appActions.dataSlots['ds_SlotUserId'];
var docRef = db.collection('users').doc(uid);
var screen = localStorage.getItem('ds_url');
    
    docRef.get().then((doc) => {

        if (doc.exists && screen === 'undefined' || screen === null) {

        let userStatus = doc.data().userStatus;
        localStorage.setItem('ds_userStatus', userStatus)
        console.log(doc.data().userStatus)
        
        let organizationName = doc.data().organizationName;
        localStorage.setItem('ds_organizationName', organizationName);
        console.log(doc.data().organizationName)
        

              
      setTimeout(() => {
      this.context.appActions.goToScreen('Feed', { transitionId: 'slideIn_bottom' })
       }, 4000);

        } else if (doc.exists && screen !== 'undefined') {


      setTimeout(() => {
      this.context.appActions.goToScreen('project', { transitionId: 'slideIn_bottom' })
      }, 1000);
          
        } else {
           
      setTimeout(() => {
      this.context.appActions.goToScreen('loginproject', { transitionId: 'slideIn_bottom' })
      }, 1000);
          
          
       
      }
    })
    //end
  
  
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    const style_elPreloader = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotLoadingAnim'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    return (
      <div className="AppScreen LoadingfeedScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elPreloader" style={style_elPreloader} />
          </div>
        </div>
      </div>
    )
  }
  
}
