import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elPreloader from './images/WaitScreen_state0_elPreloader_626520.png';

export default class Loadinganima extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  render() {
    const style_elPreloader = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotLoadingAnim'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    return (
      <div className="Loadinganima">
        <div className="layoutFlow">
          <div className="elPreloader">
            <div style={style_elPreloader} />
          </div>
        </div>
        
        <div className="foreground">
          <div className="baseFont elText" style={style_elText}>
            <div>{this.context.locStrings.wait_text_290842}</div>
          </div>
        </div>
      </div>
    )
  }
  
}
