import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import 'moment/locale/pt-br';
import moment from 'moment';

export default class ProjectItem2 extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // dataSheetRow

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  getValue_elPhase = () => {
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.projectPhase : '');
  }
  
  
  getValue_elPhaseDescription = () => {
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.phaseDescription : '');
  }
  
  
  
  getValue_elTimetoend = () => {
  let transformPropValue_timetoend = (input) => {
    const time = input
    const date = time.format
       var ds_activeLang = localStorage.getItem('ds_activeLang')
     
       if(ds_activeLang === 'en') {
         moment.locale('en');
         let en = moment(date).format('LL')
         console.log('en', en)
         return en
       } else if (ds_activeLang === 'ptbr') {
         moment.locale('ptbr');
         let ptbr = moment(date).format('LL')
            console.log('ptbr', ptbr)
            return ptbr
    }
  }
    return ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_timetoend(this.props.dataSheetRow ? this.props.dataSheetRow.phaseExpectedEnd : ''));
  }
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
      borderRadius: '10.0px',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      borderRadius: '10.0px',
      filter: 'drop-shadow(1.0px 1.0px 2px #eaeaea)',
      overflow: 'visible',
     };
    const style_elText = {
      fontSize: 14.0,
      color: '#7f7eff',
      textAlign: 'left',
     };
    
    const value_phase = this.getValue_elPhase();
    
    const style_elPhase = {
      fontSize: 14.0,
      color: '#ff9200',
      textAlign: 'left',
     };
    
    const value_phaseDescription = this.getValue_elPhaseDescription();
    
    const style_elPhaseDescription = {
      fontSize: 12.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextCopy = {
      fontSize: 14.0,
      color: '#7f7eff',
      textAlign: 'left',
     };
    
    const value_timetoend = this.getValue_elTimetoend();
    
    const style_elTimetoend = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elMomentJS = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ProjectItem2">
        <div className="background">
          <div className="elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="elText">
            <div className="font-ralewayBold" style={style_elText}>
              <div>{this.context.locStrings.projectitem2_text_638720833}</div>
            </div>
          </div>
          
          <div className="elPhase">
            <div className="font-ralewayBold" style={style_elPhase}>
              <div>{value_phase}</div>
            </div>
          </div>
          
          <div className="elPhaseDescription">
            <div className="font-ralewayRegular" style={style_elPhaseDescription}>
              <div>{value_phaseDescription}</div>
            </div>
          </div>
          
          <div className="elTextCopy">
            <div className="font-ralewayBold" style={style_elTextCopy}>
              <div>{this.context.locStrings.projectitem2_textcopy_887113748}</div>
            </div>
          </div>
          
          <div className="elTimetoend">
            <div className="baseFont" style={style_elTimetoend}>
              <div>{value_timetoend}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <div className="elMomentJS" style={style_elMomentJS} />
        </div>
      </div>
    )
  }
  
}
