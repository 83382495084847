import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import './App.css';
import ScreenContext from './ScreenContext';

import Compbg from './Compbg';
import Loadinganima from './Loadinganima';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class CompMap extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {

  const googleM = window.google = window.google ? window.google : {} 
    const geofire = require('geofire-common');
    const status = document.getElementById('status');
    const mapLink = document.getElementById('map-link');

    mapLink.href = '';
    mapLink.textContent = '';
    status.textContent = '';

    var map;
    var db = firebase.firestore()
    
    //var target = document.getElementById('map');

    if ('geolocation' in navigator) {

      //var ds_activeLang = localStorage.getItem('ds_activeLang');
      //if(ds_activeLang === 'ptbr') {
      //  status.textContent = 'Localizando…'
      //} else if (ds_activeLang === 'en') {
      //  status.textContent = 'Locating…'
      //}
        //var hashslot = localStorage.getItem('ds_SlotHash');
        //var address = localStorage.getItem('ds_mygeo');
        var latslot = Number(localStorage.getItem('ds_SlotLat'));
        var lonslot = Number(localStorage.getItem('ds_SlotLon'));
        var cityslot = localStorage.getItem('ds_SlotCity');
        var stateslot = localStorage.getItem('ds_SlotState');
        //var locationId = localStorage.getItem('ds_locationId');

    //Map initial
    var pos = new googleM.maps.LatLng(latslot, lonslot)

        var mapOptions = {
            apiKey: "AIzaSyBJvMQUeg5bpGqay4ohIGPA-Zt5GSJ-yaU",
            mapId: 'd16bae8cf5e6ee95',
            center: pos,
            zoom: 8,
            enableHighAccuracy: true,
            disableDefaultUI: true,
           	zoomControl: true,
            radiusInM : 50 * 1000
        };

      const feedbacks = db.collectionGroup('feedbacks')
        .where('state', '==', stateslot)
        //.where('city', '==', cityslot)
        //.where('lat', '>', '-1')
        .get()
        .then((querySnapshot) => {
            const data1 = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            question: doc.data().question,
            projectName: doc.data().projectName,
            proposalTitle: doc.data().proposalTitle,
            neighborhood: doc.data().neighborhood,
            summary: doc.data().summary,
            city: doc.data().city,
            state: doc.data().state,
            postType: doc.data().postType,
            userEmail: doc.data().userEmail,
            userName: doc.data().userName,
            likes: doc.data().likes,
            numberofcomments: doc.data().numberofcomments,
            lat: Number(doc.data().lat),
            lng: Number(doc.data().lng),
            })
        );
  
      var locations1 = [];
      locations1 = data1
      console.log('feedbacks',locations1)

      ///////PROJECTS

        const projects = db.collectionGroup('projects')
            .where('state', '==', stateslot)
            //.where('city', '==', cityslot)
            //.where('lat', '>', '-1')
            .get()
            .then((querySnapshot) => {
                const data = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                summary: doc.data().summary,
                //question: doc.data().question,
                projectName: doc.data().projectName,
                neighborhood: doc.data().question,
                city: doc.data().city,
                state: doc.data().state,
                postType: doc.data().postType,
                userEmail: doc.data().userEmail,
                likes: doc.data().likes,
                numberofcomments: doc.data().numberofcomments,
                lat: Number(doc.data().lat),
                lng: Number(doc.data().lng),
                })    
            );
          
          var locations0 = [];
          locations0 = data 
          console.log('projects',locations0)

      ///////QUESTIONS
      const questions = db.collectionGroup('questions')
      .where('state', '==', stateslot)
      //.where('city', '==', cityslot)
      //.where('lat', '>', '-1')
      .get()
      .then((querySnapshot) => {
          const data2 = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          question: doc.data().question,
          questionId: doc.data().questionId,
          projectName: doc.data().projectName,
          summary: doc.data().summary,
          neighborhood: doc.data().neighborhood,
          city: doc.data().city,
          state: doc.data().state,
          postType: doc.data().postType,
          userEmail: doc.data().userEmail,
          likes: doc.data().likes,
          numberofcomments: doc.data().numberofcomments,
          lat: Number(doc.data().lat),
          lng: Number(doc.data().lng),
          })
      );
          var locations2 = [];
          locations2 = data2 
          console.log('questions',locations2)
      
          const merged = [...locations0, ...locations1, ...locations2];  //Using the Spread operator Since ES6
          console.log(merged)

          var locations = [];
          locations = merged 

        //`Showing results near: ${cityslot}}`
        //status.textContent = '';
        //mapLink.href = `https://www.openstreetmap.org/#map=18/${latslot}/${lonslot}&zoom=12`;

        var ds_activeLang = localStorage.getItem('ds_activeLang');

       if(ds_activeLang === 'en') {
          mapLink.textContent = `Results near ${cityslot}`
       } else if (ds_activeLang === 'ptbr') {
          mapLink.textContent = `Resultados em ${cityslot}`
       }
       
        map = new googleM.maps.Map(document.getElementById('map'), mapOptions);

        var infoWin = new googleM.maps.InfoWindow({maxWidth: 360});
      
        var markers = locations.map(function(location, i) {

            if(location.postType === 'Project'){
            const markerProject = {
              url: "https://firebasestorage.googleapis.com/v0/b/courbaniza.appspot.com/o/mappingIcons%2Fmap-marker-urbanProjectS.png?alt=media&token=41862e95-0ede-40ae-a3af-a6eb4d47067b",
              scaledSize: new googleM.maps.Size(50, 64.26),
            }
            var marker = new googleM.maps.Marker({
            position: location,
            animation: googleM.maps.Animation.DROP,
            title: location.projectName,
            //gestureHandling: "cooperative",
            icon: markerProject,
            })

           } else if (location.postType === 'Idea'){

            const markerIdea = {
              url: "https://firebasestorage.googleapis.com/v0/b/courbaniza.appspot.com/o/mappingIcons%2Fmap-marker-urbanIdea.png?alt=media&token=825f2e2a-ea63-4a03-b38d-a887c30daf1a",
              scaledSize: new googleM.maps.Size(42, 53.98),
            }
            var marker = new googleM.maps.Marker({
            position: location,
            animation: googleM.maps.Animation.DROP,
            //gestureHandling: "cooperative",
            title: location.proposalTitle,
            icon: markerIdea,
            })

          } else if (location.postType === 'Question'){
            const markerQuestion = {
              url: "https://firebasestorage.googleapis.com/v0/b/courbaniza.appspot.com/o/mappingIcons%2Fmap-marker-urbanQuestion.png?alt=media&token=e4ff12be-892b-4d5a-8ce0-27802bd94c44",
              scaledSize: new googleM.maps.Size(42, 53.98),
            }
            var marker = new googleM.maps.Marker({
            position: location,
            animation: googleM.maps.Animation.DROP,
            gestureHandling: "cooperative",
            title: location.question,
            icon: markerQuestion,
            })
           } 
            googleM.maps.event.addListener(marker, 'click', function(evt) {
           

    var ds_activeLang = localStorage.getItem('ds_activeLang');

    if(ds_activeLang === 'en') {
      if(location.postType === 'Project'){ 

        var html = "<div style='width:220px;overflow:hidden;line-height:1.20;min-width:220px;' onClick= window.open('https://courbaniza.com/project/" + location.id  + "','_self')><p style='line-height:10px'>" + location.city + "<h4 style='line-height:10px'><strong>" + location.projectName + "</strong></h4>" + location.summary + "</p><p><span style='background-color: #6C63FF; font-weight: bold; border: none; color: white; padding: 10px 16px; border-radius: 4px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer;'>Learn more</span></p></div>"
    
      } else if (location.postType === 'Idea') {
          
          var html = '<div style="background-color:#ffffff;width:220pxcolor:141515;padding:10px"><p><strong>' + location.userName + '</strong></p><h4><strong>' + location.proposalTitle + '</strong></h4></div>'

       } else if (location.postType === 'Question') {
          
            var html ='<div style="background-color:#ffffff;width:220px;color:141515;padding:10px"><p><strong>' + location.projectName + '</strong></p><h4><strong>' + location.question + '</strong></h4></div>'

          } else { 
            //Nothing here 
          }
    } else if (ds_activeLang === 'ptbr') {

      if(location.postType === 'Project'){ 

        var html = "<div style='width:220px;overflow:hidden;line-height:1.20;min-width:220px;' onClick= window.open('https://courbaniza.com/project/" + location.id  + "','_self')><p style='line-height:10px'>" + location.city + "<h4 style='line-height:10px'><strong>" + location.projectName + "</strong></h4>" + location.summary + "</p><p><strong><span style='background-color: #6C63FF; border: none; color: white; padding: 10px 16px; border-radius: 4px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer;'>Conheça o projeto</span></strong></p></div>"
    
      } else if (location.postType === 'Idea') {
          
          var html = '<div style="background-color:#ffffff;width:220pxcolor:141515;padding:10px"><p><strong>' + location.userName + '</strong></p><h4><strong>' + location.proposalTitle + '</strong></h4></div>'

       } else if (location.postType === 'Question') {
          
            var html ='<div style="background-color:#ffffff;width:220px;color:141515;padding:10px"><p><strong>' + location.projectName + '</strong></p><h4><strong>' + location.question + '</strong></h4></div>'

          } else { 
            //Nothing here 
          }
    }      
            infoWin.setContent(html)
            infoWin.open(map, marker);
            });
            return marker;
        });

        googleM.maps.event.addListener(map, 'click', function() {
        infoWin.close();
        })

        const clusterOptions = {
          imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
          gridSize: 30,
          zoomOnClick: false,
          maxZoom: 10,
        };
        
        var markerClusterer = new MarkerClusterer({map, markers, clusterOptions, gridSize:
          70, zoomOnClick: false, averageCenter: false, maxZoom: 13});
        });
     
      });
    });
  //Around you - zoom 14
    document.querySelector('.elButton2').addEventListener('click', function () {
        map.setZoom(14);
        map.setCenter(pos);
      
      var ds_activeLang = localStorage.getItem('ds_activeLang');

        if(ds_activeLang === 'en') {
          mapLink.textContent = `Results near ${cityslot}`
       } else if (ds_activeLang === 'ptbr') {
          mapLink.textContent = `Resultados em ${cityslot}`
       }

        // The user and svgMarker
        const svgMarker = {
          url: "https://firebasestorage.googleapis.com/v0/b/courbaniza.appspot.com/o/mappingIcons%2Fmap-marker-user.png?alt=media&token=8ae1acf6-4d5b-48eb-b860-b64f5844bd66",
          scaledSize: new googleM.maps.Size(35, 44.98),
    }

    var usermarker = new googleM.maps.Marker({
              icon: svgMarker, //turn of the custom marker above
              map: map,
              draggable: false,
              animation: googleM.maps.Animation.DROP,
              gestureHandling: "cooperative",
          })
    usermarker.setPosition(pos);

  //user map listenner
      googleM.maps.event.addListener(usermarker, 'dragend', function (event) {  
          let latitude = this.getPosition().lat();
          let longitude = this.getPosition().lng();
          let hash = geofire.geohashForLocation([latitude, longitude]);
          console.log(latitude,longitude);
          console.log(hash);

          localStorage.setItem("ds_SlotLat", latitude);
          localStorage.setItem("ds_SlotLon", longitude);
          localStorage.setItem("ds_SlotHash", hash);
       });
map.setCenter(pos);
    })
      
// end user
      
  } else {console.log('geolocation not avaiable')}
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elButton2 = async () => {
  
  }
  
  
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    
    // Embedded HTML content for element 'mapembed'
    const htmlContent_mapembed = "";
    
    const style_elMapembed = {
      pointerEvents: 'auto',
     };
    const style_elBgcomp1 = {
      borderRadius: '7.0px',
      boxShadow: '1.0px 1.0px 1px #eaeaea',
     };
    
    const style_elButton2 = {
      display: 'block',
      textTransform: 'none',
      fontSize: 14.0,
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elLtlng = {
      fontSize: 12.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformVisiblePropValue_loadinganima = (input) => {
      setTimeout(() => {
         this.context.appActions.updateDataSlot('ds_preloadergif', "false");
         }, 4200);
      return input;
    }
    const elLoadinganima = ((val) => { return val === "true" || val == true || val == 1 })(transformVisiblePropValue_loadinganima((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_preloadergif'] : ''))) ? (
      <div className="hasNestedComps elLoadinganima">
        <Loadinganima ref={(el)=> this._elLoadinganima = el} />
      </div>
     ) : null;
    
    return (
      <div className="CompMap">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
          
          <div className="embeddedContent containerMinHeight elMapembed" style={style_elMapembed}>
            <div dangerouslySetInnerHTML={{__html: htmlContent_mapembed}}></div>
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="flowRow flowRow_CompMap_elBgcomp1_1285671764">
          <div className="hasNestedComps elBgcomp1">
            <div style={style_elBgcomp1}>
              <Compbg ref={(el)=> this._elBgcomp1 = el} />
            </div>
          </div>
          
          <div className="elButton2">
            <Button className="font-ralewayBold" style={style_elButton2}  color="accent" onClick={this.onClick_elButton2} >
              {this.context.locStrings.compmap_button2_537108}
            </Button>
          </div>
          
          </div>
          <div className="elLtlng">
            <div className="font-ralewayBold" style={style_elLtlng}>
              <div>{this.context.locStrings.compmap_ltlng_205545488}</div>
            </div>
          </div>
        </div>
        
        <div className="foreground">
          { elLoadinganima }
        </div>
      </div>
    )
  }
  
}