import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class Footer extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  
  render() {
    const style_elPolicy = {
      fontSize: 16.0,
      color: '#feffff',
      textAlign: 'right',
     };
    const style_elPolicy_outer = {
      display: 'none',
     };
    const style_elTerms = {
      fontSize: 15.0,
      color: '#feffff',
      textAlign: 'left',
     };
    const style_elTerms_outer = {
      display: 'none',
     };
    const style_elText2 = {
      fontSize: 15.0,
      color: '#feffff',
      textAlign: 'center',
     };
    
    return (
      <div className="Footer">
        <div className="layoutFlow">
          <div className="flowRow flowRow_Footer_elPolicy_203581">
          <div className="elPolicy" style={style_elPolicy_outer}>
            <div className="font-ralewayRegular" style={style_elPolicy}>
              <div>{this.context.locStrings.footer_policy_203581}</div>
            </div>
          </div>
          
          <div className="elTerms" style={style_elTerms_outer}>
            <div className="font-ralewayRegular" style={style_elTerms}>
              <div>{this.context.locStrings.footer_terms_982393}</div>
            </div>
          </div>
          
          </div>
          <div className="elText2">
            <div className="font-ralewayRegular" style={style_elText2}>
              <div>{this.context.locStrings.footer_text2_548779}</div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
