import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import PlistItem from './PlistItem';

export default class ProjectLisItem2 extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // neighborhood, projectName, summary, coverPhoto, projectURL, projectDescript, phase, heroPhoto, address, projectTitle, projectPhase, phaseExpectedEnd, phaseDescription

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elHotspot = async () => {
    let newVal = this.props.projectName;
    this.context.appActions.updateDataSlot('ds_projectName', newVal);
  
    newVal = this.props.projectURL;
    this.context.appActions.updateDataSlot('ds_url', newVal);
  
    // Go to screen 'project'
    const param_projectName = this.props.projectURL;
    this.context.appActions.goToScreen('project'+'/'+param_projectName, this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    
    const style_elHotspot = {
      display: 'block',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ProjectLisItem2">
        <div className="layoutFlow">
          <div className="hasNestedComps elCardProject">
            <div>
              <PlistItem neighborhood={this.props.neighborhood} projectName={this.props.projectName} summary={this.props.summary} coverPhoto={this.props.coverPhoto} projectDescript={this.props.projectDescript} heroPhoto={this.props.heroPhoto} address={this.props.address} projectTitle={this.props.projectTitle} projectPhase={this.props.projectPhase} phaseExpectedEnd={"1"} phaseDescription={"sasasa"} {...this.props} ref={(el)=> this._elCardProject = el} />
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <div className="actionFont containerMinHeight elHotspot" style={style_elHotspot} onClick={this.onClick_elHotspot}  />
        </div>
      </div>
    )
  }
  
}
